import React from "react"
import {
  Box,
  CardContent as MuiCardContent,
  Chip,
  Typography as MuiTypography,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  TextField,
} from "@material-ui/core"
import * as IconsGallery from "@iconsGallery"
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons"
import { SmallButton, useStyles, StyledMenu } from "./styles"
import * as Adm from "@adm"
import _ from "lodash"
import CustomDateFilter from "./CustomDateFilter"

const chipConvertValue = (value) => {
  if (value === "365") {
    return "1 Year"
  } else if (value === "730") {
    return "Last 2 Years"
  } else if (value === "30" || value === "60" || value === "90") {
    return `${value} Days`
  } else {
    return `Custom Date`
  }
}

const RevenueFilter = (props) => {
  const {
    filterOption: defaultfilterOption = [],
    defaultFilterValue = "",
    onUpdate = () => "",
    disabled = false,
  } = props || {}

  const [anchorEl1, setAnchorEl1] = React.useState(null)
  const [filterValue, setFilterValue] = React.useState(defaultFilterValue)
  const [filterOption, setFilterOption] = React.useState(defaultfilterOption)
  const [filteredLabel, setFilteredLabel] = React.useState("")
  const [showDatePicker, setShowDatePicker] = React.useState(true)
  const [getStartDateSelectedDate, setStartDateSelectedDate] = React.useState(
    ""
  )
  const [getEndDateSelectedDate, setEndDateSelectedDate] = React.useState("")

  React.useEffect(() => {
    // setFilterValue(defaultFilterValue)
  }, [defaultFilterValue])

  const handleClose = (e) => {
    return
    // if (anchorEl1 != "Custom") {
    //   setAnchorEl1(null)
    // } else {
    // }
    // console.log("props bk e", e)
    setAnchorEl1(null)
  }

  const handleChange = (event, type) => {
    let year = event.target.value.split("_")[0]
    let calender = year
    setFilterValue(event.target.value)
    setFilteredLabel(event.target.value.label)
    onUpdate({ value: event.target.value })
  }

  const handleStartDateChange = (event) => {
    setStartDateSelectedDate(event.target.value)
  }
  const handleEndDateChange = (event) => {
    setEndDateSelectedDate(event.target.value)
  }

  // console.log("Selected Start Date is---", getStartDateSelectedDate)
  // console.log("Selected End Date is---", getEndDateSelectedDate)
  // localStorage.setItem("dashBoardStartDate", getStartDateSelectedDate)
  // localStorage.setItem("dashBoardEndDate", getEndDateSelectedDate)

  const renderChip = (value) => {
    return (
      <Chip
        label={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "start",
              lineHeight: "15px",
              padding: 2,
            }}
          >
            <span style={{ fontWeight: "bold" }}>
              {chipConvertValue(value)}
            </span>
            {value && value.includes("to") && (
              <span>{`${value.replace("to", " to ")}`}</span>
            )}
          </div>
        }
        size="small"
        variant="outlined"
        color="primary"
        style={{
          borderRadius: "30px",
          marginRight: 10,
          color: "var(--primaryColor)",
          border: `1px solid var(--primaryColor)`,
          minWidth: 130,
          minHeight: value && value.includes("to") ? 40 : 30,
        }}
      />
    )
  }

  const classes = useStyles()

  return (
    <React.Fragment>
      <Box textAlign="right" className={classes.boxStyle}>
        <SmallButton
          disabled={disabled}
          onClick={(event) => {
            document.body.classList.add("show-menu")
            setAnchorEl1(event.currentTarget)
          }}
        >
          {renderChip(filterValue)}
          <FilterListIcon />
        </SmallButton>
      </Box>
      <StyledMenu
        name={`filter`}
        id="simple-menu1"
        PaperProps={{
          style: {
            transform: "translateX(-20%) translateY(13%)",
            border: 0,
            boxShadow: "1px 1px 10px #b4b4b4",
            padding: "0px 10px",
            minWidth: 350,
            minHeight: 360,
          },
        }}
        anchorEl={anchorEl1}
        open={Boolean(anchorEl1)}
        onClose={handleClose}
      >
        <FormControl
          style={{ background: "", padding: 10, margin: "auto" }}
          component="div"
        >
          <RadioGroup
            name={`filter`}
            aria-label={`filter`}
            key={defaultFilterValue}
            value={filterValue}
            checked={filterValue}
            onChange={(event) => {
              handleChange(event, "filter")
            }}
          >
            {!_.isEmpty(filterOption) &&
              filterOption.map((item, i) => {
                return (
                  <FormControlLabel
                    key={i}
                    value={item.value}
                    control={<Radio style={{ color: "var(--primaryColor)" }} />}
                    label={item.label}
                    onClick={handleClose}
                  />
                )
              })}
          </RadioGroup>
          {filterValue.includes("to") ? (
            <CustomDateFilter
              defaultDate={filterValue}
              onDateChange={(innerProps) => {
                const { value } = innerProps
                setFilterValue(value)
                onUpdate({ value: value, isType: "click" })
                handleClose()
              }}
            />
          ) : (
            <></>
          )}
        </FormControl>
        {/* {filterValue.includes("to") && ( */}
        <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            document.body.classList.remove("show-menu")
            setAnchorEl1(null)
          }}
        >
          <IconsGallery.CloseRoundeds />
        </div>
        {/* )} */}
      </StyledMenu>
    </React.Fragment>
  )
}
export default RevenueFilter
export { RevenueFilter }
