import React, { useState } from 'react';
import _ from 'lodash';
// import DateRangeInput from './DateRangeInput';
import { isValid, format, startOfDay, endOfDay } from 'date-fns';
import { TimePicker, KeyboardTimePicker } from "@material-ui/pickers";
import moment from 'moment';
import { makeStyles, Grid, withStyles, createMuiTheme } from '@material-ui/core'
import { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core/styles'
import * as Adm from "@adm"
const CustomTimePicker = withStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "var(--primaryColor)"
        },
        '& .MuiFormLabel-root.Mui-focused': {
            color: "var(--primaryColor)",
        },
    },
    '& .MuiPickersToolbar-toolbar': {
        backgroundColor: "var(--primaryColor)"
    }
}))(TimePicker)

const theme = createMuiTheme({
    overrides: {
        MuiPickersToolbar: {
            toolbar: {
                backgroundColor: "var(--primaryColor)"
            }
        },
        MuiPickersClockNumber: {
            clockNumberSelected: {
                backgroundColor: "var(--primaryColor)",
                color: "var(--white)"

            }
        },
        MuiPickersClock: {
            pin: {
                backgroundColor: "var(--primaryColor)",
            }
        },
        MuiPickersClockPointer: {
            pointer: {
                backgroundColor: "var(--primaryColor)",
            },
            thumb: {
                backgroundColor: "var(--primaryColor)",
                border: "14px solid var(--primaryColor)"
            }
        },
        MuiButton: {
            textPrimary: {
                color: "var(--primaryColor)"
            }
        },
        MuiOutlinedInput: {
            root: {
                height: "36px"
            }
        }
    }
});

const withFormattedTime = (date, time, key = 'from') => {
    if (isValid(time)) {
        return `${format(date, "yyyy-MM-dd'T'")}${format(time, key === 'from' ? "HH:mm:'00.000'xxx" : "HH:mm:'59.999'xxx")}`
    }
    return format(date, key === 'from' ? "yyyy-MM-dd'T'HH:mm:'00.000'xxx" : "yyyy-MM-dd'T23:59:59.999'xxx") //  "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
}

const getFormattedDate = (date, time, key = 'from') => {
    if (isValid(date)) {
        return withFormattedTime(date, time, key)
    }
    return '';
}

export const getInitialDatesFromString = str => {
    if (_.isEmpty(str) || !_.isString(str)) {
        return { startDate: undefined, endDate: undefined }
    }

    let dateArr = str.split('__||__');
    return { startDate: _.isEmpty(dateArr[0]) ? undefined : dateArr[0], endDate: _.isEmpty(dateArr[1]) ? undefined : dateArr[1], key: 'dates' }
}

const SearchByDate = (props) => {
    console.log(props.searchObj, "searchObj");
    const { searchObjKey = '', searchObj = {}, setSearchObj = () => { }, label = '', select = false, SelectProps = {}, searchByOptions = [], isLocationLookup = false } = props;
    const [initialDates, setInitialDates] = useState(getInitialDatesFromString(_.get(searchObj, searchObjKey, '')));
    const [timeObj, setTimeObj] = useState(() => ({ from: _.get(initialDates, 'startDate', null), to: _.get(initialDates, 'endDate', null) }));

    useEffect(() => {
        setInitialDates(getInitialDatesFromString(_.get(searchObj, searchObjKey, '')))
    }, [searchObj])

    const handleChange = val => setSearchObj(c => {
        let temp = _.cloneDeep(c);
        temp[searchObjKey] = `${getFormattedDate(_.get(val, 'startDate', ''), _.get(timeObj, 'from'), 'from')}__||__${getFormattedDate(_.get(val, 'endDate', ''), _.get(timeObj, 'to'), 'to')}`;
        return temp;
    })

    const handleTimeChange = (time, key = 'from') => setSearchObj(c => {
        let tempDates = getInitialDatesFromString(_.get(searchObj, searchObjKey, ''));
        if (!isValid(new Date(_.get(tempDates, 'startDate', '')))) {
            tempDates['startDate'] = startOfDay(time);
            tempDates['endDate'] = endOfDay(time);
        }
        setTimeObj(c => ({ ...c, [key]: time }))
        let temp = _.cloneDeep(c);
        temp[searchObjKey] = `${getFormattedDate(new Date(_.get(tempDates, 'startDate', '')), key === 'from' ? time : _.get(tempDates, 'startDate'), 'from')}__||__${getFormattedDate(new Date(_.get(tempDates, 'endDate', '')), key === 'to' ? time : _.get(tempDates, 'endDate'), 'to')}`;
        return temp;
    })

    // const handleTimeChange = (time, key = 'from') => {
    //     if (isValid(_.get(initialDates, 'startDate', ''))) {

    //     }
    // }

    return (
        <Grid container item xs={12} /* spacing={2} */>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6} style={{ paddingRight: "5px" }}>
                <Adm.DateRangeInput initialDates={initialDates} handleDateChange={handleChange} />
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3} xl={3} style={{ paddingRight: "5px" }}>
                <ThemeProvider theme={theme}>
                    <CustomTimePicker
                        fullWidth
                        variant='dialog'
                        emptyLabel=''
                        inputVariant='outlined'
                        size='medium' margin='dense'
                        label='From Time' value={_.get(timeObj, 'from', undefined)}
                        onChange={e => { handleTimeChange(e, 'from') }}
                    />
                </ThemeProvider>

                {/* <CustomTimePicker fullWidth variant='dialog' emptyLabel='' inputVariant='outlined' size='medium' margin='dense' label='From Time' value={_.get(timeObj, 'from', undefined)} onChange={e => { handleTimeChange(e, 'from') }} /> */}
            </Grid>
            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                <ThemeProvider theme={theme}>
                    <CustomTimePicker
                        fullWidth
                        variant='dialog'
                        emptyLabel=''
                        inputVariant='outlined'
                        size='medium' margin='dense'
                        label='To Time' value={_.get(timeObj, 'to', undefined)}
                        onChange={e => { handleTimeChange(e, 'to') }}
                    />
                </ThemeProvider>
                {/* <CustomTimePicker fullWidth variant='dialog' emptyLabel='' inputVariant='outlined' size='medium' margin='dense' label='To Time' value={_.get(timeObj, 'to', undefined)} onChange={e => { handleTimeChange(e, 'to') }} /> */}
            </Grid>
        </Grid>
    )
}

export default SearchByDate;