
export async function fetchLocationList() {

  const allLocationsPromise = fetch(
    "http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3004/api/stores/getlocations",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //   body: JSON.stringify({
      //     customer_state: data.customer_state,
      //     order_status: data.order_status,

      //   }),

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "locations": data,
      }
    })
  )
  return allLocationsPromise
}
export async function fetchTurnstileList() {

  const allLocationsPromise = fetch(
    "http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/turnstilefilters?locationId=arunkumar.s@infovision.com&turnstileId=turnstileId&turnstileIp=turnstileIp&locationNumber=locationNumber&turnstileLocation=turnstileLocation&pagenumber=1&itemcount=10&sortvalue=0",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },


    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "turnstile": data,
      }
    })
  )
  return allLocationsPromise
}

export async function fetchGondolaList() {

  const allLocationsPromise = fetch(
    "http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/gondolafilters?locationId=arunkumar.s@infovision.com&gondolaId=gondolaId&gondolaDimension=gondolaDimension&shelfCount=shelfCount&gondolaLocation=gondolaLocation&pagenumber=1&itemcount=10&sortvalue=0",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },


    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "gondola": data,
      }
    })
  )
  return allLocationsPromise
}

export async function fetchGroundsplot(data, data2) {

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/groundplot", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      locationId: "arunkumar.s@infovision.com",

      redisTableName: data.redisTableName,

      redisOutboundName: data.redisOutboundName,

      gpMemoLength: data.gpMemoLength,

      routerBoundary: {
        xmin: data.xmin,
        xmax: data.xmax,
        ymin: data.ymin,
        ymax: data.ymax
      },

      calibrationTargets: [data2

      ],

      entryGateLocation: [
        data.entryGateLocation1, data.entryGateLocation2
      ],

      exitGateLocation: [
        data.exitGateLocation1, data.exitGateLocation2
      ],

      waypoint: {
        lastActivationTime: data.lastActivationTime,
        entryWaypoint: {
          label: data.label,
          centrepoint: [
            data.centerPoint1x, data.centerPoint1y
          ],
          color: data.color,
          resetTime: data.resetTime,
          ready: data.ready
        },
        exitWaypoint: {
          label: data.label2,
          centrepoint: [
            data.centerPoint2x, data.centerPoint2y
          ],
          color: data.color2,
          resetTime: data.resetPoint2,
          ready: data.ready2
        }
      }

    })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "groundsplot": data }
  }))
  return allLocationsPromise
}

export async function fetchCartManager(data) {

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/cartmanager", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      locationId: "arunkumar.s@infovision.com",

      gPRedisPersonsName: data.gPRedisPersonsName,

      productCounterRedisName: data.productCounterRedisName,

      productUPCTokenFile: data.productUPCTokenFile,
      redisTableOpenpose: data.redisTableOpenpose,


      cameraLocation: [
        data.cameraLocation1,
        data.cameraLocation2

      ],

      targetedClass: [data.targetedClass1, data.targetedClass2]



    })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "cartManager": data }
  }))
  return allLocationsPromise
}

export async function fetchCameraList() {

  const allLocationsPromise = fetch(
    "http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/camerafilters?locationId=arunkumar.s@infovision.com&pagenumber=1&itemcount=10&sortvalue=1&usbPort=usbPort&gpuIndex=gpuIndex&serialNumber=serialNumber&cameraId=cameraId&cameraType=cameraType&cameraLocation=cameraLocation&calibrationData=calibrationData&calibrationData_75=calibrationData_75&calibrationLog=calibrationLog",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },


    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "camera": data,
      }
    })
  )
  return allLocationsPromise
}

export async function fetchCameraAndGpu(data, data2) {

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/cameraandgpu", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      locationId: "arunkumar.s@infovision.com",

      redisTableName: data.redisTableName,
      redisTableOpenpose: data.redisTableOpenpose,
      weights: data.weights,
      weights_Head: data.weights_Head,
      xLim: data.xLim,
      yLim: data.yLim,
      linkedCamera: [data2]

    })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "cameraAndGpu": data }
  }))
  return allLocationsPromise
}

export async function fetchCameraUpdate(data) {
  const usbNumber = parseInt(data.usbPort)
  const gpuIndex = parseInt(data.gpuIndex)
  const serialNumber = parseInt(data.serialNumber)

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/camera", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      locationId: "arunkumar.s@infovision.com",

      serialNumber: serialNumber,
      cameraId: data.cameraId,
      cameraType: data.cameraType,
      cameraLocation: data.cameraLocation,
      calibrationData: data.calibrationData,
      calibrationData_75: data.calibrationData_75,
      calibrationLog: data.calibrationLog,
      gpuIndex: gpuIndex,
      usbPort: usbNumber

    })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "cameraUpdate": data }
  }))
  return allLocationsPromise
}

export async function fetchStoreDimension(data, data2, data3) {
  const length = parseInt(data.length)
  const width = parseInt(data.width)
  const height = parseInt(data.height)

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/storedimension", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      locationId: "arunkumar.s@infovision.com",

      planId: data.planId,
      planName: data.planName,
      length: length,
      width: width,
      height: height,
      entry: data2,
      exits: data3

    })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "storeDimension": data }
  }))
  return allLocationsPromise
}

export async function fetchGondolaDetails(data) {
  const itemID = "&gondolaId=" + data;


  const allLocationsPromise = fetch(
    "http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/gondola?" + itemID,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },


    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "gondola": data,
      }
    })
  )
  return allLocationsPromise
}

export async function fetchLocationSetup(data, data2, data3) {

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/locationsetup", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({

      locationId: data3.locationId,
      storeType: data,
      nameOfTheRetailBrand: data2

    })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "locationSetup": data }
  }))
  return allLocationsPromise
}


export async function fetchGetLocationDataStepper(data) {

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/locationsetup?locationId=" + data, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    // body: JSON.stringify({

    // locationId : data3.locationId,
    // storeType : data,
    // nameOfTheRetailBrand: data2

    // })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "locationData": data }
  }))
  return allLocationsPromise
}


export async function fetchStoreDimensionStepper(data) {

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/storedimension?locationId=" + data, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    // body: JSON.stringify({

    // locationId : data3.locationId,
    // storeType : data,
    // nameOfTheRetailBrand: data2

    // })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "storeDimensionData": data }
  }))
  return allLocationsPromise
}


export async function fetchGondaStepData(data) {

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/gondolafilters?locationId=" + data, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    // body: JSON.stringify({

    // locationId : data3.locationId,
    // storeType : data,
    // nameOfTheRetailBrand: data2

    // })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "storeDimensionData": data }
  }))
  return allLocationsPromise
}



export async function fetchCameraAndGPUdata(data) {

  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/camerafilters?locationId=" + data, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    // body: JSON.stringify({

    // locationId : data3.locationId,
    // storeType : data,
    // nameOfTheRetailBrand: data2

    // })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "storeDimensionData": data }
  }))
  return allLocationsPromise
}



export async function fetchCartManagerStepData(data) {


  const allLocationsPromise = fetch("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:3013/cartmanager?locationId=" + data, {
    method: "GET",
    headers: {
      "Content-Type": "application/json"
    },
    // body: JSON.stringify({

    // locationId : data3.locationId,
    // storeType : data,
    // nameOfTheRetailBrand: data2

    // })
  }).then((response) => response.json().then((data) => {
    return { "status": response.status, "storeDimensionData": data }
  }))
  return allLocationsPromise
}


