import {
  Avatar,
  Grid,
  TextField,
  Typography,
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  Button,
  ListItemText,
} from "@material-ui/core"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
export const useCustomerSummaryHeaderCardStyles = makeStyles((theme) => ({
  avatar: {
    width: "80px !important",
    height: "80px !important",
    fontSize: "50px !important",
    color: "rgba(21, 101, 192, 1) !important",
    backgroundColor: "#F4F7FA !important",
  },
  heading2: {
    backgroundColor: "#F4F7FA",
    color: "rgba(21, 101, 192, 1)",
  },
  address: {
    // margin: "0 16px",
    alignItems: "center",
  },
  address1: {
    marginLeft: "500px",
    marginTop: "10px",
  },
  textField: {
    width: "160px",
  },
}))

export const useRenderCustomerValuesStyles = makeStyles((theme) => ({
  text1: {
    // marginLeft: "75px",
    marginTop: "5px",
  },
  text2: {
    //marginLeft: "30px",
    marginTop: "5px",
  },
  basicIconProperty: {
    color: "rgba(158, 158, 158, 1)",
    fontSize: "30px",
  },
}))
export const useCustomerDetailStyles = makeStyles({
  textInput: {
    height: "37px",
    marginRight: "32px",
    display: "inlineblock",
    "& .MuiSelect-icon ": {
      color: "rgba(0, 0, 0, 0.87);",
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
    fontWeight:'bold'
  },
  smallTextInput: {
    width: "177px",
    height: "36px",
    marginRight: "32px",
    marginLeft:"10px",
    marginTop: "0px",
    fontWeight:'bold'
  },
  inputFieldText: {
    marginLeft: "10px",
    fontWeight: 'bold',

  },
  nonEditText: {
    fontSize: "14px",
    fontWeight: "400",
    width: "180px",
    whiteSpace: "nowrap !important",
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    marginTop: "0px",

  },
  optionalFieldText: {
    color: "#9E9E9E",
    fontSize: "12px",
    fontWeight: "400",
    marginTop: "12px",
    textAlign: "right",
    marginRight: "34px",
  },
  optionalFieldText1: {
    color: "#9E9E9E",
    fontSize: "12px",
    fontWeight: "400",
    marginLeft: "8px",
  },
  datePickerStyles: {
    width: "303px",
    height: "36px",
    //  marginBottom: "25px",
    marginRight: "32px",
    border: "1px solid rgba(47, 72, 88, 0.15)",
    borderRadius: "4px",
    paddingLeft: "16px",
    paddingBottom: "0px",
    marginBottom: "0px",
    marginTop: "0px",
    "& .MuiSvgIcon-root": {
      color: "#9E9E9E",
    },
  },
  checkBox: {
    "& .MuiCheckbox-root": {
      color: "rgba(102, 120, 138, 1)",
    },
  },
  underline: {
    "&&&:before": {
      borderBottom: "none",
    },
    "&&:after": {
      borderBottom: "none",
    },
  },
})

export const CardWrapper = styled(Card)`
  width: 100%;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
    0px 1px 2px rgba(63, 63, 68, 0.15);
  border-radius: 8px;
  position: relative;

  .base-calender-component.cls-error {
    p {
      bottom: -8px !important;
      transform: translateY(75%);
    }
  }
`

export const CardTopWrapper = styled(Grid)`
  align-items: center;
  position: relative;
  margin-bottom: 3px;
`

export const IconWrapper = styled(Grid)`
  justify-content: flex-end;
  position: absolute;
  top: 0px;
  right: 0px;
`
//  border-left: 0.1em solid rgba(0, 0, 0, 0.1);

export const MainWrapper = styled(Grid)`
   max-width: 100%;
  flex-basis: 100%;

  @media (min-width: 1024px) and (max-width: 1640px) {
    max-width: 100%;
    flex-basis: 100%;
    border-left: none;
    // border-top: 0.1em solid rgba(0, 0, 0, 0.1);
  }
`

export const FormMainWrapper = styled.form`
  @media (min-width: 1024px) and (max-width: 1280px) {
    .cls-title-textfield,
    .cls-title-label {
      border-bottom: 1px solid #e0e4e6;
      padding-bottom: 16px;
      height: 60px;
      margin-bottom: 16px;
      align-items: center;
      display: flex;
    }
  }
`

export const DropDownGrid = styled(Grid)`
  margin-left: 20px;
  margin-top: 1px;
`

export const TextFiledWrapper = styled(TextField)`
  width: 250px;
  height: 36px;
  margin-left: 20px;
  margin-bottom: 15px;
`

export const ProfileMainWrapper = styled(Grid)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  // padding-right: 20px;
   box-sizing: border-box;
  @media (min-width: 1024px) and (max-width: 1640px) {
    max-width: 100%;
    flex-basis: 100%;
    // padding: 10px;
  }
`
export const ProfileInfo = styled(Grid)`
  display: flex;
`
export const ProfileDetail = styled(Grid)`
  margin-left: 17px;
`
