import React, { useEffect } from "react"
import styled from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography as MuiTypography, FormHelperText } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { spacing } from "@material-ui/system"
import "./styles.css"
import Divider from "@material-ui/core/Divider"
import * as Utils from "@utils"
import * as Features from "@features"
import * as Adm from "@adm"
import moment from "moment"
import { useState } from "react"

const MainWrapper = styled(Grid)`
  .cls-open-calender {
    display: flex;
    align-items: center;
  }
  .cls-timezone {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  .cls-close-calender {
    display: flex;
    align-items: center;
    padding-top: 0px;

    .cls-calender-date-native {
      margin-top: 0px;
    }
  }

  .cls-open-date-label {
    margin-right: 68px;
  }
  .cls-close-date-label {
    margin-right: 55px;
  }
`
const Typography = styled(MuiTypography)(spacing)
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 150,
  },
  contolChildGridSecond: {
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15,
  },
  contolChildGridFirst: (width) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
    marginTop: 15,
    paddingLeft: 20 + width,
    fontSize: "13px",
    marginRight: "15px",
  }),

  contolChildGridFirstdivSecSpan: (width) => ({
    paddingLeft: width,
    marginTop: "10px",
    paddingTop: "10px",
  }),
  contolChildGridSecdivFirstSpan: (width) => ({
    paddingLeft: 10 + width,
    fontSize: "14px",
  }),
  autoCompleteHeight: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        "borderColor": "var(--primaryColor)",
      }
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
      width: "100%",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
  },
})

const OpenDateMain = ({
  isWriteAllowed = false,
  selectedOpenDat,
  selectedCloseDat,
  setAllowPrompt = () => { },
  locationTimezone = "",
  editMode,
  setEditMode,
  saveHandler,
  slashHandler,
  handleDateChange,
  handleTimeZoneChange,
  loading,
  isAnyChanges,
  setAnyChanges,
  defaultTimeZone,
  isTablet = false,
  values = {},
  timeZoneList = [],
  errors = {},
  touched = {},
  onGlobalUpdate
}) => {
  const { useWindowSize } = Utils || {}
  const [height, width] = useWindowSize()
  const classes = useStyles(width)
  const { t } = useTranslation()

  const [selectedOpenDate, setSelectedOpenDate] = React.useState(
    selectedOpenDat
  )
  const [selectedCloseDate, setSelectedCloseDate] = React.useState(
    selectedCloseDat
  )
  const [timeZoneOpen, setTimeZoneOpen] = React.useState(false)
  const closeDateValidation = selectedCloseDate < selectedOpenDate && selectedCloseDate !== ""

  useEffect(() => {
    // console.log("selectedOpenDat",selectedOpenDat)
    setSelectedOpenDate(selectedOpenDat)
    setSelectedCloseDate(selectedCloseDat)
    return () => {
    }
  }, [selectedCloseDat, selectedOpenDat])

  const getActualDate = (date) => {
    let dateArray = date.split("-");
    let dat = parseInt(dateArray[2]);
    let mon = parseInt(dateArray[1]) - 1;
    let yrs = parseInt(dateArray[0]);
    let currentDate = new Date(yrs, mon, dat);
    let sendDate =  moment(currentDate).format("yyyy-MM-DD")
    return sendDate
  }

  const getViewedData = (date) => {
    let dateArray = date.split("-");
    let dat = parseInt(dateArray[2]);
    let mon = parseInt(dateArray[1]) - 1;
    let yrs = parseInt(dateArray[0]);
    let currentDate = new Date(yrs, mon, dat);
    let changeDate = new Date(currentDate).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
    return changeDate
  }

  return (
    <MainWrapper item lg={12} md={12} sm={12} xs={12}>
      <Grid
        container
        direction="row"
        alignContent="center"
        alignItems="center"
        style={{ justifyContent: "space-between", paddingLeft: "20px", height: "60px" }}
      >
        <Grid item>
          <Typography
            variant="h6"
            align="center"
            style={{ fontWeight: "bold" }}
          >
            {t("Open Date")}
          </Typography>
        </Grid>

      </Grid>
      <div timeout="auto">
        {locationTimezone === "" && !editMode ? (
          <Features.NoRecordFound />
        ) : (
          <>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Grid container direction="column">
                <Grid container direction="row" item lg={12} md={12} sm={12} xs={12} style={{ padding: '16px 16px 25px 25px' }}>
                  <Grid container direction="row" item lg={6} md={9} sm={9} xs={12}>
                    <Grid item lg={3} md={2} sm={4} xs={3} style={{ alignSelf: 'center' }}>
                      <Typography style={{ fontWeight: 'bold' }}>Time Zone{editMode ? <span style={{ color: "red" }}>*</span> : ""}</Typography>
                    </Grid>
                    <Grid item lg={7} md={7} sm={8} xs={8}>
                      {!editMode ?
                        <Typography style={{ fontWeight: 500 }} >{locationTimezone}</Typography>
                        :
                        <>
                          <Adm.DropdownFilter
                            list={timeZoneList || []}
                            selectedValue={locationTimezone}
                            onChangeEvent={(e, details) => {
                              handleTimeZoneChange(e, details)
                              onGlobalUpdate()
                            }}
                            popupOpen={timeZoneOpen}
                            setPopupOpen={setTimeZoneOpen}
                            disableClearable={true}
                            classNameTextField={classes.autoCompleteHeight}
                            placeholder={'Select Time Zone'}
                          />
                          <FormHelperText style={{ color: `red` }} error={Boolean(touched?.locationTimezone && errors?.locationTimezone)}>
                            {touched?.locationTimezone && errors?.locationTimezone}
                          </FormHelperText>
                        </>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Divider />
                <Grid container direction="row" item lg={12} md={12} sm={12} xs={12} style={{ padding: '16px 16px 25px 25px', margin: "0px" }} spacing={isTablet ? 4 : 1}>
                  <Grid container direction="row" item lg={6} md={6} sm={9} xs={12} style={{ padding: "10px 0px" }}>
                    <Grid item lg={3} md={3} sm={4} xs={3} style={{ alignSelf: 'center' }}>
                      <Typography style={{ fontWeight: 'bold' }}>Open Date {editMode ? <span style={{ color: "red" }}>*</span> : ""}</Typography>
                    </Grid>
                    <Grid item lg={7} md={8} sm={8} xs={8} >
                      {!editMode ?
                        <Typography style={{ fontWeight: 500 }} >
                          { getViewedData(selectedOpenDate) }
                          {/* {new Date(selectedOpenDate).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })} */}
                        </Typography>
                        :
                        <div style={{ marginTop: '-16px' }}>
                          <Adm.Calender
                            fullWidth
                            style={{ width: '100%' }}
                            isTimeEnable={false}
                            allowFutureDate={true}
                            date={
                              selectedOpenDate != null
                                ? 
                                getActualDate(selectedOpenDate)
                                // moment(new Date(selectedOpenDate)).format(
                                //   "yyyy-MM-DD"
                                // )
                                : null
                            }
                            error={errors?.openDate && errors?.openDate !== "" ? "Select Open Date" : ""}
                            onUpdateCalender={({ date }) => {
                              handleDateChange(date, "openDate")
                              onGlobalUpdate()
                            }}
                          />
                        </div>
                      }
                    </Grid>
                  </Grid>

                  <Grid container direction="row" item lg={6} md={6} sm={9} xs={12} style={{ padding: "10px 0px" }}>
                    <Grid item lg={3} md={3} sm={4} xs={3} style={{ alignSelf: 'center' }}>
                      <Typography style={{ fontWeight: 'bold' }}>Close Date</Typography>
                    </Grid>
                    <Grid item lg={7} md={8} sm={8} xs={8}>
                      {!editMode ?
                        <Typography style={{ fontWeight: 500 }} >
                          { selectedCloseDate ? getViewedData(selectedCloseDate) : '-' }
                          {/* {selectedCloseDate ? new Date(selectedCloseDate).toLocaleDateString('en-GB', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : '-'} */}
                        </Typography>
                        :
                        <div style={{ marginTop: '-16px' }} >
                          <Adm.Calender
                            fullWidth
                            style={{ width: '100%' }}
                            isTimeEnable={false}
                            allowFutureDate={true}
                            date={
                              selectedCloseDate !== null
                                ? 
                                getActualDate(selectedCloseDate)
                                // moment(new Date(selectedCloseDate)).format(
                                //   "yyyy-MM-DD"
                                // )
                                : ""
                            }
                            error={
                              selectedCloseDate < selectedOpenDate &&
                                selectedCloseDate !== ""
                                ? "Select valid Close Date"
                                : ""
                            }
                            onUpdateCalender={({ date }) => {
                              handleDateChange(date, "closeDate")
                              onGlobalUpdate()
                            }}
                          />
                        </div>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </div>
    </MainWrapper>
  )
}

export { OpenDateMain }
export default OpenDateMain
