/* eslint-disable import/no-anonymous-default-export */
import { put, call, takeLatest, cancelled, select, takeEvery, all } from 'redux-saga/effects'
import Axios from 'axios'
import _ from 'lodash'
import api, { productDataApiMethods } from '../api';
import { ReduxConstants } from '@constants';
const { ACTION_TYPES, SAGA_ACTIONS: SAGAS } = ReduxConstants.REDUX_CONSTANTS;



function* fetchAllCategories(action) {
    yield put({ type: ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_PENDING });
    const cancelSource = Axios.CancelToken.source();

    try {
        const resp = yield call(productDataApiMethods.getProductCategories, cancelSource);
        // const resp = yield call(api.getAllProductCategories, cancelSource);
        yield put({ type: ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_FULFILLED, payload: _.get(resp, 'data.data', []) });
    } catch (err) {
        yield put({ type: ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_REJECTED, payload: err })
    } finally {
        if (yield cancelled()) {
            cancelSource.cancel('Cancelled');
        }
    }
}
function* watchFetchAllCategories() {
    yield takeLatest(SAGAS.FETCH_PRODUCT_CATEGORIES, fetchAllCategories)
}

function* deleteCategory(action) {
    yield put({ type: ACTION_TYPES.DELETE_CATEGORY_PENDING });

    try {
        const resp = yield call(api.deleteCategory, action.categoryID);
        yield put({ type: SAGAS.FETCH_PRODUCT_CATEGORIES })
        yield put({ type: ACTION_TYPES.DELETE_CATEGORY_FULFILLED, payload: resp })
    } catch (err) {
        yield put({ type: ACTION_TYPES.DELETE_CATEGORY_REJECTED, payload: err })
    }
}
function* watchDeleteCategory() {
    yield takeEvery(SAGAS.DELETE_CATEGORY, deleteCategory)
}

function* addCategory(action) {
    yield put({ type: ACTION_TYPES.ADD_CATEGORY_PENDING });

    try {
        const resp = yield call(api.deleteCategory, action.categoryID);
        yield put({ type: SAGAS.FETCH_PRODUCT_CATEGORIES })
        yield put({ type: ACTION_TYPES.ADD_CATEGORY_FULFILLED, payload: resp })
    } catch (err) {
        yield put({ type: ACTION_TYPES.ADD_CATEGORY_REJECTED, payload: err })
    }
}
function* watchAddCategory() {
    yield takeEvery(SAGAS.DELETE_CATEGORY, deleteCategory)
}

export default [watchFetchAllCategories(), watchDeleteCategory(), watchAddCategory()]
