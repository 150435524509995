import React from "react"
import {
  IconButton,
  Container,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core"
import _ from "lodash"
import * as Adm from "@adm"
import { JSUtils } from "@utils"
import * as StyledDOM from "./styles"
import * as IconsGallery from "@iconsGallery"
import SearchIcon from "@material-ui/icons/Search"
import ClearIcon from "@material-ui/icons/Clear"

export const RetailerLeftSideBar = (props) => {
  const classes = StyledDOM.useStyles()
  const {
    title,
    mainStateDashboard,
    handleSearchField,
    handleSearchIconButton,
    handleSearchOnFoucsField,
    // rowItem,
    handleListClick,
    capitalizeText,
    handleSearchBluredField,
    // searchFieldValue,
    // rows,
    handleClearSearch,
  } = props || {}

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ padding: "15px 0px 15px 10px", background: "#f9f9fc" }}
      >
        <Typography
          variant="h3"
          style={{
            color: "#000000",
            fontWeight: 700,
          }}
        >
          {title}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ position: "relative", padding: "15px 15px 0px 10px" }}
      >
        <TextField
          inputRef={(input) => input && input.focus()}
          ref={mainStateDashboard.refFieldValue}
          id="search-bar"
          className={classes.textField}
          variant="outlined"
          placeholder="Search..."
          size="small"
          style={{ width: "100%" }}
          onChange={(e) => handleSearchField(e)}
          // onBlur={(e) => {
          //   handleSearchBluredField(e)
          // }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearchIconButton(e)
            }
          }}
          // value={searchFieldValue}
          defaultValue={mainStateDashboard.searchFieldValue}
        // type="search"
        />
        {mainStateDashboard.searchFieldValue ? (
          <IconButton
            type="submit"
            aria-label="search"
            style={{ position: "absolute", right: "10px", bottom: "-5px" }}
            onClick={handleClearSearch}
          >
            <ClearIcon style={{ fill: "#A0A0A0" }} />
          </IconButton>
        ) : (
          <IconButton
            type="submit"
            aria-label="search"
            style={{ position: "absolute", right: "10px", bottom: "-5px" }}
            onClick={handleSearchIconButton}
          >
            <SearchIcon style={{ fill: "#A0A0A0" }} />
          </IconButton>
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: "20px" }}>
        {!_.isEmpty(mainStateDashboard.retailerList) &&
          <div style={{ height: '1000px', overflow: "auto" }}>
            {mainStateDashboard.retailerList.map((itm, index) => (
              <div key={index}>
                {searchCardList({
                  itm,
                  mainStateDashboard,
                  handleListClick,
                  classes,
                  capitalizeText,
                })}
              </div>
            ))}
          </div>}
        {_.isEmpty(mainStateDashboard.retailerList) && (
          <Typography style={{ textAlign: "center" }}>
            No Record(s) Found
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export const searchCardList = (props) => {
  const {
    mainStateDashboard,
    itm,
    handleListClick,
    classes,
    capitalizeText,
  } = props
  return (
    <StyledDOM.StyledGridMainWrapper
      container
      item
      xs={12}
      className={itm.isActive ? classes.active : ""}
    >
      <StyledDOM.StyledGridList container onClick={() => handleListClick(itm)}>
        <Grid item xs={11} style={{
          marginTop: "5px", fontWeight: 700, overflow: "hidden",
          textOverflow: "ellipsis", width: "100%"
        }}>
          {capitalizeText(itm.title)}
        </Grid>
        <Grid item xs={1}>
          <StyledDOM.StyledTabNumber className={itm.isActive ? "active" : ""}>
            {itm.count}
          </StyledDOM.StyledTabNumber>
        </Grid>
      </StyledDOM.StyledGridList>
    </StyledDOM.StyledGridMainWrapper>
  )
}
