import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { userProfileDetails } from "./reduxSagas/admin/actions"
import _ from "lodash"

function CustomRedirect(history = {}) {
  const { location = {}, _kc = {} } = window || {}
  const dispatch = useDispatch()

  const onTriggerRerouting = () => {
    const { idTokenParsed = {} } = _kc || {}
    const { userRole = {} } = idTokenParsed || {}

    /**
     * @SaaS-Site-Admin
     * @Default_ROLE_Tenant_Admin
     * @Default_ROLE_Store_Associate
     * @Default_ROLE_Master_Admin
     * @Sub-Sass-Admin
     */

    let mappedSaasArray = _.map(userRole, function square(n) {
      return n === "SaaS-Site-Admin"
    })
    let isSassBased = _.includes(mappedSaasArray, true)

    let mappedSubSaasArray = _.map(userRole, function square(n) {
      return n === "Sub-SaaS-Admin"
    })
    let isSubsassBased = _.includes(mappedSubSaasArray, true)

    let mappedSassCustomBased = _.map(userRole, function square(n) {
      return _.includes(n, "SaaS-Site-Admin")
    })
    let isSassCustomBased = _.includes(mappedSassCustomBased, true)

    let mappedSubSassCustomBased = _.map(userRole, function square(n) {
      return _.includes(n, "Sub-SaaS-Admin")
    })
    let isSubSassCustomBased = _.includes(mappedSubSassCustomBased, true)

    //let isTenantBased = !isSassBased && !isSubsassBased && !isSassCustomBased && !isSubSassCustomBased

    // let isSassBased = userRole?.includes("SaaS-Site-Admin")
    // let isSubsassBased = userRole?.includes("Sub-SaaS-Admin")
    // let isTenantBased = !userRole?.includes("SaaS-Site-Admin") && !userRole?.includes("Sub-SaaS-Admin")

    let lcPathname = "/"
    if (isSassBased) {
      lcPathname = "master"
    } else if (isSubsassBased) {
      lcPathname = "portal"
    } else if (isSassCustomBased) {
      lcPathname = "support"
    } else if (isSubSassCustomBased) {
      lcPathname = "portal_support"
    }
    //if (isTenantBased) {
    else {
      let tenantNamkit = idTokenParsed?.Tenant?.[0].replace("/", "")
      tenantNamkit = tenantNamkit.toLowerCase()
      if (tenantNamkit.includes(" ")) {
        tenantNamkit = tenantNamkit.replaceAll(" ", "_")
        // tenantNamkit = tenantNamkit.replaceAll(" ", "%20")
      }
      lcPathname = tenantNamkit || "tenantName"
    }

    if (window !== undefined) {
      window["globalPathName"] = lcPathname
    }
    localStorage.setItem("globalPathName", lcPathname)
    if (location.pathname === "/") {
      history.push(
        lcPathname === "/" ? `/error?type=error` : `/${lcPathname}/dashboard`
      )
    } else {
      if (
        !location.pathname.includes(lcPathname) ||
        location.pathname.split("/")[1] !== lcPathname
      ) {
        history.push(`/error?type=load`)
      }
    }
  }

  React.useEffect(() => {
    if (!location.pathname.includes("auth")) {
      onTriggerRerouting()
    }
  }, [])

  return <></>
}

export default CustomRedirect
export { CustomRedirect }
