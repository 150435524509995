import React, { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import _, { cloneDeep } from "lodash"
import useStyles from "./styles"
import * as Adm from "@adm"
import MaterialDataGrid from "../../../../components/MaterialDataGrid"
import TableFilter from "../../../../components/tableFilter"
import api from "../../../../services/masterData/locations/api"
import { CloseRounded } from "@material-ui/icons"
import {
  Button,
  Divider,
  AppBar,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Card,
  Grid,
  Typography,
  TextField,
} from "@material-ui/core"
import update from "immutability-helper"
import classnames from "classnames"
import { Trash2, SearchIcon, InfoIconKit } from "@iconsGallery"
import * as IconsGallery from "@iconsGallery"
import { ConfirmationDialog } from "@features"
import styled from "styled-components"
import AttributeLookUpTable from "../../products/ProductDetailedPage/productAttribute/AttributeLookUpTable"
import AttributeTypeCheck from "../../products/ProductDetailedPage/productAttribute/AttributeTypeCheck"
import { EditAttributes } from "@material-ui/icons"
import { productDataApiMethods } from "../../../../services/masterData/products/api"
// import { gridStateAttributes } from "../../../../reduxSagas/admin/actions"
import { useHistory } from "react-router-dom"

export const renderOnEdit = (
  props,
  {
    history,
    reDirectPath = "",
    editVariable = "",
    tableGlobalState = {},
    isType = "",
    isCustomFunc = false,
  }
) => {
  const { rowData = {}, setRowData = () => {} } = tableGlobalState || {}
  const newRowDataState = {
    ...rowData,
  }

  newRowDataState["editID"] = props.dataItem._id
  setRowData(newRowDataState)
  let urlPush = ""
  if (isType === "singel") {
    const preDefinedId = props.dataItem[editVariable] || ""
    urlPush = reDirectPath + encodeURIComponent(preDefinedId)
  }
  if (isType === "group") {
    let addUrl = ""
    const splitReDirectPath = reDirectPath.split("/")
    splitReDirectPath.forEach((item) => {
      if (item !== "") {
        let setItem = ""
        if (item.includes("=")) {
          const splitEqual = item.split("=")
          setItem = `${splitEqual[0]}=${props.dataItem[splitEqual[1]]}`
        } else {
          setItem = item
        }
        addUrl = addUrl + "/" + setItem
      }
    })
    urlPush = addUrl
  }
  urlPush !== "" && history.push(urlPush)
}
const TemplateTableHeaderGrid = styled(Grid)`
  .ecom-card-table-header {
    /* border: 1px solid #d9d9db; */
    border-bottom: none;
    border-radius: 5px 5px 0 0 !important;
  }
`
const PageHeaderBreadcrumbKit = styled(Grid)`
  button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    max-width: unset;
  }
  margin-bottom: 24px;
  border-bottom: 1px #e0e0e0 solid;
  padding-bottom: 15px;
`
const SearchBox = styled(TextField)(() => ({
  "& fieldset": {
    borderRadius: "4px 0px 0px 4px",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "var(--primaryColor)",
  },
}))
export const StyledSyncedButton = styled(Button)`
  margin: 0px 8px;
  background: #e0e0e0;
  min-width: 118px;
  height: 36px;
  color: black;
  font-weight: bold;
  @media (min-width: 1000px) and (max-width: 1200px) {
    min-width: 50px;
  }
  @media (min-width: 350px) and (max-width: 851px) {
    min-width: 30px;
  }
  @keyframes spin {
    100% {
      transform: rotate(-360deg);
    }
  }
  &.cls-syncing {
    svg {
      animation: spin 4s linear infinite;
    }
  }
  &:active {
    color: #fff;
    background: #53be57;
  }
  &:hover {
    color: #fff;
    background: #53be57;
  }
  svg {
    margin-right: 8px;
  }
`
const columnData = [
  {
    field: "locationName",
    title: "Location Name",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
  },
  {
    field: "locationAddress.line1",
    title: "Location Address",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
  },
  {
    field: "locationType.name",
    title: "Location Type",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
  },
]

const defaultDataState = {
  filter: {
    logic: "and",
    filters: [],
  },
  sort: [],
  take: 10,
  skip: 0,
}

const defaultBodyData = {
  page: 1,
  limit: 10,
  sort: {},
  filter: {},
}

const renderUpdateTableFilterData = (item, stateProps) => {
  const { columnData, setColumnData } = stateProps || {}
  const newColumnData = [...columnData]
  newColumnData.map((val) => {
    if (val.field === item.field) {
      val.show = !val.show
    }
    return {}
  })
  setColumnData(newColumnData)
}

const Table = ({
  isWriteAllowed,
  dataFetchMethod = () => {},
  deleteAnId = () => {},
  deleteMultipleId = () => {},
  isPageView = false,
  lookupType = "",
  noShowPrimaryWithoutSelect = false,
  columns = columnData,
  additionalButtons = [],
  customEditFunc = false,
  handleCustomFunc = () => {},
  onDiscard = () => {},
  onSave = () => {},
  onDelete = () => {},
  onView = () => {},
  onEdit = () => {},
  onItemChange = () => {},
  switchHandler = () => {},
  handleSingleDropdownChange = () => {},
  responseDataPath = "data.data",
  isLookUp = false,
  onPrimaryActionClick = () => {},
  handleClose = () => {},
  preSelectedItems = [],
  triggerDataFetch = { uncategorized: false, categorized: false },
  setAnyChanges = () => {},
  setTriggerDataFetch = () => {},
  dataFetchTriggerKey = "uncategorized",
  title = "UnCategorized Locations",
  selectedHierarchyIds = [],
  tableLocationAttributes = {},
  fetchOnlyOnSelectedIdUpdate = false,
  dataUniqueIdPath = "_id",
  primaryActionLabel = "Associate",
  showSearchBar,
  setExportGridData = () => {},
  isTablet,
  isMobile,
  customHeaderDisplay = () => {},
  additionalFilters = [
    { field: "locationHierarchy", operator: "eq", value: "" },
  ],
  tableFilter = true,
  ShowCheckBox = true,
  isStatic = false,
  reRender = false,
  menutype = "master",
  pageable = true,
  displayAssociate = true,
  methodType = "",
  historyDetails = () => {},
  isCreating = false,
  addRow = {},
  isDisableViewIcon = false,
  actionMenu = true,
  anyChanges = false,
  searchTerm = "",
  historyPopup = true,
  deleteIcon = false,
  actionDelete = true,
  nonApiDatastate = false,
  customPayload = false,
  onClickShekelSync = () => {},
  displayShekelSync = false,
  // gridState = {},
  // setGridState=()=>{},
  gridPayload = {},
  gridStateMaintain = false,
  reset = false,
  //resetMethod = () => { }
  //gridPath = ""
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  let history = useHistory()
  const { location = {} } = window || {}
  const fetchMethod = _.isFunction(dataFetchMethod)
    ? dataFetchMethod
    : api.getAllLocations
  const [columnData, setColumnData] = useState(columns)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isdelete, setIsdelete] = useState(false)
  const [gridState, setGridState] = useState({
    dataState: !_.isEmpty(gridPayload) ? gridPayload : defaultDataState,
    //dataState: defaultDataState,
  })
  const [selectedItems, setSelectedItems] = useState(
    () => preSelectedItems || []
  )
  const [rawData, setRawData] = useState({})
  const [gridData, setGridData] = useState({ data: [] })
  const [attSearchVal, setAttSearchVal] = useState("")
  const [isShekelSync, setIsShekelSync] = useState(false)
  const adopterFunction = (dataState, page, limit, additionalFilters = []) => {
    let sort = {}
    if (isLookUp) {
      sort = dataState.sort.reduce((acc, o) => {
        acc[o.field] = o.dir
        return acc
      }, {})
      return {
        page,
        limit,
        filter: [
          ...(dataState?.filter?.filters || []),
          ...(additionalFilters || []),
        ],
        sort,
        searchTerm: attSearchVal,
      }
    } else if (searchTerm !== "") {
      sort = dataState.sort.reduce((acc, o) => {
        acc[o.field] = o.dir
        return acc
      }, {})
      return {
        page,
        limit,
        filter: [
          ...(dataState?.filter?.filters || []),
          ...(additionalFilters || []),
        ],
        sort,
        searchTerm: searchTerm,
      }
    } else {
      sort = dataState.sort.reduce((acc, o) => {
        acc[o.field] = o.dir === "asc" ? 1 : -1
        return acc
      }, {})
      return {
        page,
        limit,
        filter: [
          ...(dataState?.filter?.filters || []),
          ...(additionalFilters || []),
        ],
        sort,
      }
    }
  }

  const fetchDataObject = () => {
    setLoading(true)
    fetchMethod(
      adopterFunction(
        gridState.dataState,
        Math.floor(gridState.dataState.skip / gridState.dataState.take) + 1,
        gridState.dataState.take,
        additionalFilters
      )
    )
      .then((resp) => {
        let bothApiCalls
        bothApiCalls = _.get(resp, responseDataPath)
        var customPayloadCalls = {}
        if (customPayload) {
          customPayloadCalls = {
            docs: bothApiCalls?.records || [],
            totalDocs: bothApiCalls?.total_records || 0,
            limit: bothApiCalls?.no_of_records_per_page || 0,
            page: bothApiCalls?.page_no || 0,
            totalPages: bothApiCalls?.total_no_of_pages || 0,
            pagingCounter: bothApiCalls?.page_first_record_seq_no || 0,
            hasPrevPage: bothApiCalls?.has_prev_page || false,
            hasNextPage: bothApiCalls?.hasNextPage || false,
            prevPage: bothApiCalls?.prev_page_no || null,
            nextPage: bothApiCalls?.next_page_no || null,
          }
        }
        setLoading(false)
        console.log(
          bothApiCalls,
          "fetchDataObject bothApiCalls",
          customPayloadCalls
        )
        setRawData(customPayload ? customPayloadCalls : bothApiCalls)
        setExportGridData({
          ...gridState.dataState,
          totalDocs: resp?.data?.data?.totalDocs,
        })
      })
      .catch((err) => {})
  }

  useEffect(() => {
    const asyncFetchDatareRender = async () => {
      if (reRender && !isStatic) {
        fetchDataObject()
      } else if (reRender && isStatic && methodType === "post") {
        fetchDataObject()
      } else if (reRender && isStatic) {
        setLoading(true)
        const tempData = await fetchMethod(tableFilter)
        await setRawData(_.get(tempData, responseDataPath))
        await setLoading(false)
      }
    }
    asyncFetchDatareRender()
  }, [reRender])

  // this is changed only to access static data page for userManagement
  useEffect(() => {
    const asyncFetchData = async () => {
      if (!isStatic) {
        fetchDataObject()
      } else if (isStatic && methodType === "post") {
        fetchDataObject()
      } else if (isStatic) {
        setLoading(true)
        const tempData = await fetchMethod(tableFilter)
        await setLoading(false)
        await setRawData(_.get(tempData, responseDataPath))
      }
    }
    asyncFetchData()
  }, [gridState.dataState])

  useEffect(() => {
    setAttSearchVal(attSearchVal)
  }, [attSearchVal])

  // this is changed only to access static data page for userManagement
  useEffect(() => {
    if (!isStatic || (isStatic && methodType === "post")) {
      if (
        triggerDataFetch[dataFetchTriggerKey] ||
        fetchOnlyOnSelectedIdUpdate
      ) {
        fetchMethod(
          adopterFunction(
            gridState.dataState,
            Math.floor(gridState.dataState.skip / gridState.dataState.take) + 1,
            gridState.dataState.take,
            additionalFilters
          )
        )
          .then((resp) => {
            setRawData(_.get(resp, responseDataPath))
            setTriggerDataFetch((c) => ({ ...c, [dataFetchTriggerKey]: false }))
          })
          .catch((err) => {})
      }
    }
  }, [triggerDataFetch[dataFetchTriggerKey], selectedHierarchyIds])
  /* 
        useEffect(() => {
            if (fetchOnlyOnSelectedIdUpdate) {
                fetchMethod(adopterFunction(gridState.dataState, Math.floor(gridState.dataState.skip / gridState.dataState.take) + 1, gridState.dataState.take, additionalFilters)).then(resp => {
                    setRawData(_.get(resp, responseDataPath));
                }).catch(err => {
                })
            }
        }, [selectedHierarchyIds]) */

  useEffect(() => {
    if (!_.isEmpty(rawData)) {
      setGridData(() => {
        let x = _.cloneDeep(_.get(rawData, "docs", [])) //Object.assign([], _.get(rawData, 'docs', []));
        let data = x.reduce((acc, o, i) => {
          if (
            _.findIndex(
              selectedItems,
              (c) =>
                _.get(o, dataUniqueIdPath, "*_*") ===
                _.get(c, dataUniqueIdPath, "-_-")
            ) !== -1
          ) {
            o.selected = true
          }
          acc.push(o)
          return acc
        }, [])
        return { data }
      })
    }
  }, [rawData])

  useEffect(() => {
    if (isCreating) {
      let tableData = anyChanges
        ? rawData?.docs
        : _.cloneDeep(gridData.data) || []
      tableData?.map((val) => {
        if (val._id !== "new") {
          val.inEdit = false
        }
        return val
      })
      setGridData({
        ...gridData,
        data: [addRow, ...tableData],
      })
    }
  }, [isCreating])

  useEffect(() => {
    let shekelStatus =
      selectedItems?.length > 10
        ? true
        : !_.isEmpty(
            _.filter(selectedItems, function (o) {
              return (
                o?.isShekelSync ||
                (!o?.isShekelSync && _.size(o?.item_images)) === 0
              )
            })
          )
    setIsShekelSync(shekelStatus)
  }, [selectedItems])

  useEffect(() => {
    // let griStateData = {
    //   gridPath: location.pathname,
    //   dataState: gridState?.dataState
    // };

    //dispatch(gridStateAttributes(griStateData));

    const { dataState = {} } = gridState || {}
    const { filter = {} } = dataState || {}
    const { filters = [] } = filter || {}

    const { sort = [] } = dataState

    const { skip = 0 } = dataState

    let isFilterAvailable = filters.length > 0
    let issortAvailable = sort?.length > 0
    let isSkipAvailable = skip > 0

    let isFilter =
      gridStateMaintain &&
      (isFilterAvailable || issortAvailable || isSkipAvailable)

    history.push(`${location.pathname}?filter=${isFilter}`)
    gridStateMaintain &&
      localStorage.setItem(
        location.pathname.replaceAll("/", "_"),
        JSON.stringify({
          filter: isFilter,
          dataState: gridState?.dataState,
        })
      )

    // console.log("gristage-->", {
    //   gridPath: location.pathname,
    //   dataState: gridState?.dataState,
    //   gridStateMaintain,
    //   isFilterAvailable,
    //   issortAvailable,
    //   isSkipAvailable
    // })
  }, [gridState])

  const resetMethod = () => {
    setGridState({ dataState: defaultDataState })
  }

  const handleSelection = (e) => {
    let temp = []
    let tempGridData = {}
    let dataItem = e.dataItem
    let existingIdx = _.findIndex(
      selectedItems,
      (o, id) =>
        _.get(o, dataUniqueIdPath, "*_*") ===
        _.get(dataItem, dataUniqueIdPath, "-_-")
    )
    let idxInDataState = _.findIndex(
      gridData.data,
      (o) =>
        _.get(o, dataUniqueIdPath, "*_*") ===
        _.get(dataItem, dataUniqueIdPath, "-_-")
    )
    if (existingIdx === -1) {
      temp = update(selectedItems, { $push: [dataItem] })
      tempGridData = update(gridData, {
        data: { [idxInDataState]: { $merge: { selected: true } } },
        allSelected: { $set: false },
      })
    } else {
      temp = update(selectedItems, { $splice: [[existingIdx, 1]] })
      tempGridData = update(gridData, {
        data: { [idxInDataState]: { $merge: { selected: false } } },
        allSelected: { $set: false },
      })
    }
    setSelectedItems(temp)
    // let shekelStatus = temp?.length > 10 ? true : !_.isEmpty(_.filter(temp, function (o) { return o?.isShekelSync }))
    // setIsShekelSync(shekelStatus);
    setGridData(tempGridData)
  }

  const deleteItems = async (selectedItems) => {
    await deleteMultipleId(selectedItems)
    await setSelectedItems([])
    await fetchDataObject()
  }

  const handleClearSelection = () => {
    setSelectedItems([])
    // deleteItems(selectedItems);
    // fetchDataObject();
    setGridData((c) => {
      return {
        data: c.data.map((x) => {
          x.selected = false
          return x
        }),
      }
    })
  }
  const onHeaderSelectionChange = React.useCallback((event) => {
    const checkboxElement = event.syntheticEvent.target
    const checked = checkboxElement.checked
    if (checked) {
      setGridData((c) => {
        let data = _.map(c?.data || [], (o, i) => {
          o.selected = true
          return o
        })
        setSelectedItems((s) => {
          let temp = _.isEmpty(s) ? [] : _.cloneDeep(s)
          // temp.concat(data);
          return _.uniqBy([...temp, ...data], dataUniqueIdPath)
        })
        // let shekelStatus = data?.length > 10 ? true : !_.isEmpty(_.filter(data, function (o) { return o?.isShekelSync }))
        // setIsShekelSync(shekelStatus);
        return {
          allSelected: true,
          data,
        }
      })
    } else {
      setGridData((c) => {
        let data = c.data.map((x) => {
          x.selected = false
          return x
        })

        setSelectedItems((s) => {
          let filteredSelection = _.differenceBy(s, data, dataUniqueIdPath)
          return filteredSelection
        })
        return {
          data,
        }
      })
    }
  }, [])
  const handleCustomCloseRedirect = () => {
    setOpenConfirmationDialog(false)
    deleteItems(selectedItems)
  }

  const handleCloseButtonTrigger = () => {
    handleClearSelection()
  }

  return (
    <>
      <Adm.BackdropOverlay open={loading} />

      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        // isdelete={isdelete}
        setReDirect={setReDirect}
        handleCloseButtonTrigger={handleCloseButtonTrigger}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
        deleteContent={`Selected ${title.slice(
          0,
          -1
        )}(s) would be archived, do you really want to continue ?`}
      />

      <div className={classes.container}>
        <div
          className={classnames(classes.header, {
            [classes.noPadding]: isPageView,
            [classes.height60]: isPageView,
          })}
        >
          <TemplateTableHeaderGrid item lg={12} md={12} sm={12} xs={12}>
            <Card className="ecom-card-table-header">
              <Grid
                container
                item
                row="true"
                justify="space-between"
                alignContent="center"
                alignItems="center"
                style={{
                  paddingLeft: "16px",
                  height: "60px",
                  flexWrap: "nowrap",
                }}
              >
                <Typography
                  variant="h4"
                  className={classnames(classes.title, {
                    [classes.headerTitle]: isPageView,
                  })}
                >
                  {title}
                </Typography>
                {showSearchBar && (
                  <span style={{ display: "flex", width: "15vw" }}>
                    <SearchBox
                      size="small"
                      fullWidth
                      value={attSearchVal}
                      placeholder="Search"
                      variant="outlined"
                      onChange={(e) => {
                        setAttSearchVal(e.target.value)
                      }}
                    />

                    <IconButton
                      style={{
                        backgroundColor: "var(--primaryColor)",
                        borderRadius: "0px 4px 4px 0px",
                        width: "36px",
                        height: "37.5px",
                      }}
                      size="small"
                      variant="outlined"
                      onClick={() => fetchDataObject()}
                    >
                      <SearchIcon style={{ color: "white" }} />
                    </IconButton>
                  </span>
                )}
                {(selectedItems.length > 0 ||
                  gridData?.allSelected ||
                  isLookUp ||
                  (_.isArray(additionalButtons) &&
                    !_.isEmpty(additionalButtons))) && (
                  <HeaderActionsContainer
                    columnData={columnData}
                    setColumnData={setColumnData}
                    noShowPrimaryWithoutSelect={noShowPrimaryWithoutSelect}
                    additionalButtons={additionalButtons}
                    isLookUp={isLookUp}
                    onPrimaryActionClick={() =>
                      onPrimaryActionClick(selectedItems, handleClearSelection)
                    }
                    count={
                      selectedItems.length > 0
                        ? selectedItems.length
                        : gridData?.allSelected
                        ? gridData?.data?.length
                        : selectedItems.length
                    }
                    classes={classes}
                    label={primaryActionLabel}
                    handleClearSelection={handleClearSelection}
                    showSearchBar={showSearchBar}
                    setAnyChanges={setAnyChanges}
                    deleteItems={() => deleteItems(selectedItems)}
                    // handleAttribute={handleAttribute}
                    setOpenConfirmationDialog={setOpenConfirmationDialog}
                    lookupType={lookupType}
                    displayAssociate={displayAssociate}
                    selectedItems={selectedItems}
                    isShekelSync={isShekelSync}
                    loading={loading}
                    onClickShekelSync={onClickShekelSync}
                    displayShekelSync={displayShekelSync}
                  />
                )}
                <span>
                  {/* {isLookUp && lookupType === "elasticSearch" ? ( */}
                  {displayAssociate && (
                    <>
                      {isLookUp ? (
                        <Tooltip title="Close" placement="top">
                          <CloseRounded
                            style={{
                              width: "25px",
                              margin: "30px",
                              height: "30px",
                              marginTop: "30px",
                              color: "gray",
                            }}
                            onClick={handleClose}
                          />
                        </Tooltip>
                      ) : null}
                    </>
                  )}
                  {/* <Button onClick={handleClose}>Close</Button> */}
                </span>
                {tableFilter && (
                  <div style={{ padding: "20px" }}>
                    <TableFilter
                      tableFilterData={columnData}
                      updateTableFilterData={(item) =>
                        renderUpdateTableFilterData(item, {
                          columnData,
                          setColumnData,
                        })
                      }
                      reset={reset}
                      resetFilterMethod={() => {
                        resetMethod()
                      }}
                    />
                    {_.isArray(additionalButtons) &&
                    !_.isEmpty(additionalButtons)
                      ? additionalButtons
                      : null}
                  </div>
                )}
              </Grid>
            </Card>
          </TemplateTableHeaderGrid>
        </div>
        <div className={classes.content}>
          {/* {loading ? <Adm.SkeletonTab width={100} height={100} loading={loading} column={5} row={5} /> : */}
          <MaterialDataGrid
            isGrid={false}
            isDisableViewIcon={isDisableViewIcon}
            isInlineEditAllowed={isWriteAllowed}
            columnData={columnData}
            gridState={gridState}
            setGridState={setGridState}
            rowData={gridData}
            setRowData={setGridData}
            isLookup={isLookUp || !isPageView}
            number_of_items={_.toFinite(rawData?.totalDocs || 0)}
            selectionChange={(event) => handleSelection(event)}
            otherProps={{ style: { height: "100%" } }}
            headerSelectionChange={(e) => onHeaderSelectionChange(e)}
            deleteIcon={deleteIcon}
            // onDelete={(selectedItems) => deleteAnId(selectedItems)}
            actionMenu={actionMenu}
            onEdit={(props) => {
              customEditFunc
                ? handleCustomFunc({ ...props, gridData, setGridData })
                : renderOnEdit(props, tableLocationAttributes)
            }}
            onDiscard={(props) => {
              onDiscard({ ...props, gridData, setGridData })
            }}
            switchHandler={(event, props) => {
              switchHandler({ ...props, gridData, setGridData })
            }}
            onSave={(props) => {
              onSave({ ...props, gridData, setGridData })
            }}
            onDelete={(props) => {
              onDelete({ ...props, gridData, setGridData })
            }}
            onView={(props) => {
              onView({ ...props, gridData, setGridData })
            }}
            itemChange={(e) => onItemChange(e, { gridData, setGridData })}
            handleSingleDropdownChange={(e, props) =>
              handleSingleDropdownChange(e, { gridData, setGridData, ...props })
            }
            menutype={menutype}
            tableFilter={tableFilter} // tableFilter is for remove filter option
            ShowCheckBox={ShowCheckBox} // checkbox in table
            pageable={pageable}
            historyPopup={historyPopup}
            actionDelete={actionDelete}
            nonApiDatastate={nonApiDatastate}
            //historyDetails={(e) => historyDetails(e)}
            // rowData={gridDataForGridOne}
            // isLookup={true}
            // number_of_items={itemsForGridOne}
            // loader={loader}
          />
          {/* } */}
        </div>
      </div>
    </>
  )
}

const HeaderActionsContainer = ({
  showSearchBar,
  onPrimaryActionClick = () => {},
  // handleAttribute = () => {},
  setAnyChanges = () => {},
  setOpenConfirmationDialog = () => {},
  columnData = [],
  setColumnData = () => {},
  noShowPrimaryWithoutSelect = false,
  additionalButtons = [],
  classes = {},
  count = 0,
  label = "Primary Action",
  isLookUp = false,
  handleClearSelection = () => {},
  deleteItems = () => {},
  lookupType = "",
  isMobile,
  displayAssociate = true,
  selectedItems = [],
  isShekelSync = false,
  loading = false,
  onClickShekelSync = () => {},
  displayShekelSync = false,
}) => {
  return (
    <>
      <div className={classes.headerActionsContainer}>
        {displayShekelSync && (
          <>
            <Tooltip title="Refresh" placement="top">
              <StyledSyncedButton
                className={`${loading ? "cls-syncing" : ""}`}
                onClick={() => {
                  onClickShekelSync(selectedItems)
                  handleClearSelection()
                }}
                disabled={isShekelSync}
              >
                <IconsGallery.LastSync />
                <span className={"cls-StyledSyncedButton-text"}>
                  {`Sync to Shekel`}
                </span>
              </StyledSyncedButton>
            </Tooltip>
            {isShekelSync && (
              <Tooltip
                title={`Ensure images are updated for all selected products. A maximum of 10 failed-to-sync products only can be selected`}
                placement="top"
              >
                <IconButton style={{ padding: 5 }}>
                  <InfoIconKit fill="#af2b99" />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}

        {count > 0 ? (
          <Typography variant="h5" className={classes.subTitle}>
            {count} {count > 1 ? "" : " "}Selected
          </Typography>
        ) : null}

        {displayAssociate && (
          <>
            {isLookUp ? (
              <>
                <Button
                  onClick={() => {
                    setAnyChanges(true)
                    onPrimaryActionClick()
                  }}
                  variant="contained"
                  color="primary"
                  style={{ backgroundColor: "var(--primaryColor)" }}
                >
                  ASSOCIATE
                </Button>
                <Button
                  color="primary"
                  style={{ fontWeight: "bold", color: "var(--primaryColor)" }}
                  onClick={handleClearSelection}
                >
                  Clear
                </Button>
              </>
            ) : count > 0 ? (
              <>
                <Tooltip title="Archive" placement="top">
                  <IconButton onClick={() => setOpenConfirmationDialog(true)}>
                    <Trash2 />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </>
        )}

        {/* {noShowPrimaryWithoutSelect ? count > 0 ? <Button variant='contained' color='primary' disableElevation onClick={onPrimaryActionClick}>{label}</Button> : null : <Button variant='contained' color='primary' disableElevation onClick={onPrimaryActionClick}>{label}</Button>} */}
      </div>
    </>
  )
}

export default Table
