import React from "react"
import styled, { withTheme } from "styled-components/macro"
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  IconButton,
  TableCell,
  Table,
  Grid,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@material-ui/core"
import { spacing } from "@material-ui/system"
import * as IconsGallery from "@iconsGallery"
import { Bar } from "react-chartjs-2"
import _ from "lodash"

const ChartWrapper = styled(Grid)`
  width: calc(100% - 20px);
  height: 340px;
  padding: 10px;
  position: relative;

  .cls-emptyKit {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 200px;
    width: 200px;
  }
`

const BarChart = (props) => {
  const {
    title = "",
    style = {},
    primaryColor,
    labels,
    datasets,
    yAxisPrefix = "",
    yAxisSuffix = "",
    isLoading = false,
    stepSize = 5000,
  } = props || {}

  const { data: charData = [] } = datasets[0]

  const secondDatasetColor = "rgba(0, 0, 0, 0.1)"

  const data = {
    labels: labels,
    datasets: datasets,
  }

  const options = {
    animation: {
      duration: 0, // general animation time
    },
    maintainAspectRatio: false,
    cornerRadius: 2,
    legend: {
      display: false,
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          stacked: true,
          ticks: {
            stepSize,
            fontColor: "#A0A0A0",
            callback: function (value, index, values) {
              return `${yAxisPrefix}${value}${yAxisSuffix}`
            },
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
          gridLines: {
            color: "transparent",
          },
          ticks: {
            fontColor: "#A0A0A0",
          },
        },
      ],
    },
  }

  return (
    <MuiCard
      style={{
        width: "100%",
        boxShadow: "0px 0px 14px rgba(53, 64, 82, 0.05)",
        background: "#FFFFFF",
        minHeight: 300,
        ...style,
      }}
    >
      <Grid container>
        <Grid>
          <Typography
            variant="h4"
            style={{
              paddingLeft: 16,
              paddingTop: 16,
              paddingBottom: 20,
              fontSize: 16,
              fontWeight: "500",
            }}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      <ChartWrapper>
        {isLoading ? (
          <IconsGallery.DotLoading />
        ) : (
          <>
            {!_.isEmpty(charData) ? (
              <Bar data={data} options={options} />
            ) : (
              <Grid className="cls-emptyKit">
                <IconsGallery.EmptyGraph />
                <Typography
                  style={{
                    textAlign: "center",
                    fontSize: "20px",
                    marginTop: 20,
                    color: "var(--primaryColor)",
                  }}
                >
                  No Data Available
                </Typography>
              </Grid>
            )}
          </>
        )}
      </ChartWrapper>
    </MuiCard>
  )
}

export default withTheme(BarChart)
// export default BarChart
export { BarChart }
