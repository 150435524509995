import React, { useState, useEffect } from "react"
import {
  Card,
  IconButton,
  CardHeader,
  Tooltip
} from "@material-ui/core"
import {
  Edit,
  Slash,
  CheckCircleIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
} from "@iconsGallery"

import * as Utils from "@utils"
import { useDispatch, useSelector } from "react-redux"
import * as Features from "@features"
import * as Adm from "@adm"
import LocationServiceContent from "./LocationServiceContent"
import { addLocationSerivces } from "@reduxHelper"
import { AccordianPanel } from "@adm"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import _ from "lodash"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const customRenderContainer = (props) => {
  const {
    edit,
    data,
    setAnyChanges,
    locationServicesList,
    setAllowPrompt,
    serviceOldValue = [],
     clearval = false,
    setClearVal=()=>{}
  } = props
  return (
    <>
      {data === undefined ? (
        <Features.NoRecordFound />
      ) : (
        <>
          <LocationServiceContent
            edit={edit}
            isDetailedPage={true}
            names={locationServicesList}
            data={data}
            label="Select the Services"
            setAnyChanges={setAnyChanges}
              setAllowPrompt={setAllowPrompt}
              clearVal={clearval}
              setClearVal={setClearVal}
          />
        </>
      )}
    </>
  )
}

const CustomHeaderContainer = (props) => {
  const {
    classes,
    setEdit,
    setAllowPrompt = () => "",
    expanded,
    handleExpandClick,
    updateData,
    resetData = () => "",
    isEditMode = true,
    fetchData,
    data,
    isAnyChanges,
    setAnyChanges,
    serviceOldValue=[],
    isWriteAllowed = false,
      clearval = false,
    setClearVal=()=>{}
  } = props || {}
  const isSelected = Utils.JSUtils.isEmpty(data) === false && isAnyChanges

  return (
    <CardHeader
      style={{ borderBottom: "1px solid #EEEEEE" }}
      title={
        <div style={{ fontWeight: "bold" }}>
          Location Services{" "}
          {!props.edit && expanded ? (
            <span style={{ color: "red" }}>*</span>
          ) : (
            ""
          )}
        </div>
      }
      action={
        <>
          {props.edit ? (
            <>
              {expanded === true && isEditMode === true && (
                <Tooltip title="Edit" placement="top">
                <IconButton
                  disabled={isWriteAllowed ? false : true}
                  onClick={() => {
                    setEdit(false)
                  }}
                  style={{ marginRight: "13px" }}
                >
                  <Edit className={classes.basicIconProperty} />
                  </IconButton>
                </Tooltip>
              )}
            </>
          ) : (
            <>
              {expanded === true ? (
                  <>
                    <Tooltip title="Cancel" placement="top">
                  <IconButton
                    onClick={() => {
                      resetData()
                      setEdit(true)
                      setAnyChanges(false)
                        setAllowPrompt(false)
                        setClearVal(true)
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    <Slash
                      style={{ color: "red" }}
                      className={classes.basicIconProperty}
                    />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Save" placement="top">  
                  <IconButton
                    style={{
                      marginRight: "18px",
                      pointerEvents: `${isSelected ? "unset" : "none"}`,
                    }}
                    onClick={() => {
                      if (isSelected) {
                        updateData(fetchData)
                        setEdit(true)
                        setAnyChanges(false)
                        setAllowPrompt(true)
                      }
                    }}
                  >
                    <CheckCircleIcon
                      style={{
                        color: `${isSelected ? "#4CAF50" : "#CCC"}`,
                      }}
                    />
                      </IconButton>
                    </Tooltip>
                </>
              ) : (
                ""
              )}
            </>
          )}
          {expanded === true ? (
            <IconButton
              onClick={() => {
                setEdit(true);
                resetData()
                handleExpandClick()
                setAllowPrompt(false)
              }}
            >
              <ExpandLessIcon className={classes.iconUpDown} />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                handleExpandClick()
              }}
            >
              <ExpandMoreIcon className={classes.iconUpDown} />
            </IconButton>
          )}
        </>
      }
    ></CardHeader>
  )
}

function LocationService(props) {
  const { isWriteAllowed = false, setAllowPrompt = () => {},serviceOldValue=[],fetchData=()=>{} } = props
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [data, setData] = useState([])
  const [defaultData, setDefaultData] = useState([])
  const [isAnyChanges, setAnyChanges] = useState(false)
  const formData = useSelector((state) => state.addLocationReducer)
  const [clearval,setClearVal] = useState(false)
  const dispatch = useDispatch()
  useEffect(() => {
    setData(props.data.locationServices)
    const { data = {} } = props || {}
    const { locationServices = [] } = data || {}
    setDefaultData(locationServices)
  }, [props.data])

  useEffect(() => {
    setDefaultData(
      _.cloneDeep(data)
    )
    dispatch(addLocationSerivces(data))
  }, [data])

  const resetData = () => {
    // fetchData()
    setData(_.cloneDeep(defaultData))
    // setData([...defaultData])
    dispatch(addLocationSerivces(defaultData))
  }

  const updateData = async () => {
    const dummyData = _.get(formData, "locationServices", [])
    if (dummyData.length !== 0) {
      setLoading(true)
      setAlert(false)
      let body = {
        ...props.sendBackData,
        locationServices: formData.locationServices,
      }
      let response = await ServiceAPI.fetchStoreCommonData(
        API_END_POINTS.updateLocation,
        body
      )
      await setData(_.get(response, "data.data.locationServices", []))
      await setAlertType(_.get(response, "data.status", "error"))
      await setAlertMessage(_.get(response, "data.message", "Try Again Later"))
      await setAllowPrompt(false)
      await setAlert(true)
      await setLoading(false)
    }
  }

  const stateProps = {
    data,
    setData,
    defaultData,
    setDefaultData,
    isAnyChanges,
    setAnyChanges,
    resetData,
    isWriteAllowed,
  }

  return (
    <>
      <Adm.BackdropOverlay open={loading} />
      <Card
        style={{
          width: "100%",
          boxShadow:"0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "8px",
        }}
      >
        <AccordianPanel
          renderContainer={(containerProps) =>
            customRenderContainer({
              ...containerProps,
              data:_.cloneDeep(data),
              ...stateProps,
              locationServicesList: props.locationServicesList,
              setAllowPrompt,
              serviceOldValue,
              clearval,
              setClearVal

            })
          }
          renderHeader={(containerProps) =>
            CustomHeaderContainer({ ...containerProps, ...stateProps,setAllowPrompt,serviceOldValue, clearval,setClearVal })
          }
          label="Location Service"
          updateData={updateData}
          resetData={resetData}
        />
      </Card>
      <>
        {alert ? (
          <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
        ) : null}
      </>
    </>
  )
}
export { LocationService, LocationServiceContent }
export default LocationService
