import { Typography } from "@material-ui/core"
import _ from "lodash"

const StatusColorBased = (props) => {
    const { dataItem = {}, colorsArr = [], fieldMap = "" } = props;
    let title = dataItem?.[fieldMap].name || "";
    console.log("StatusColorBased", fieldMap, title);
    //console.log(_.find(colorsArr, { text: title })?.colorCode, colorsArr, props, "StatusColorBased")
    return (
        <td>

            <Typography
                style={{
                    backgroundColor: _.find(colorsArr, { text: title })?.colorCode,
                    minWidth: "fit-content",
                    maxWidth: "100px",
                    color: "#FFF",
                    borderRadius: "4px",
                    textAlign: "center",
                    padding: "3px"
                }}
            >{title}</Typography>
        </td>
    )
}
export default StatusColorBased