import React from "react"
import loadable from "@loadable/component"
import * as Adm from "@adm"

const loadingKit = {
  //fallback: <Adm.LoadingSpinner />,
}

// Guards
// const AuthGuard = async(() => import("../components/AuthGuard"), loadingKit);

// Auth components
const SignIn = loadable(() => import("../pages/auth/SignIn"), loadingKit)
const SignUp = loadable(() => import("../pages/auth/SignUp"), loadingKit)
const ResetPassword = loadable(
  () => import("../pages/auth/ResetPassword"),
  loadingKit
)
const Page404 = loadable(() => import("../pages/auth/Page404"), loadingKit)
const Page500 = loadable(() => import("../pages/auth/Page500"), loadingKit)
const Page403 = loadable(() => import("../pages/auth/Page403"), loadingKit)

//SmartConfigRoutes
const Gondola = loadable(
  () => import("../pages/smartDeviceConfig/gondola/index"),
  loadingKit
)
const Planogram = loadable(
  () => import("../pages/smartDeviceConfig/planogram/index"),
  loadingKit
)
const Camera = loadable(
  () => import("../pages/smartDeviceConfig/camera/index"),
  loadingKit
)
const Smartshelf = loadable(
  () => import("../pages/smartDeviceConfig/smartShelf/index"),
  loadingKit
)
const Turnstile = loadable(
  () => import("../pages/smartDeviceConfig/turnstile/index"),
  loadingKit
)
const StoreConfig = loadable(
  () => import("../pages/smartDeviceConfig/storeConfig/index"),
  loadingKit
)
const inStoreDisplay = loadable(() => import("../pages/smartDeviceConfig/inStoreDisplay/index"), loadingKit)

//FSSmartDeviceConfigRoutes
const CameraFS = loadable(
  () => import("../pages/smartDeviceConfig/cameraFS/index"),
  loadingKit
)
const EdgeDeviceFS = loadable(
  () => import("../pages/smartDeviceConfig/edgeDeviceFS/index"),
  loadingKit
)

//Space Planning
const SpacePlanning = loadable(
  () => import("../pages/spacePlanning/PlanogramDetailedView"),
  loadingKit
)
const FrictionlessStore = loadable(
  () => import("../pages/spacePlanning/FrictionlessDetailedView"),
  loadingKit
)
const SpacePlanningList = loadable(
  () => import("../pages/spacePlanning/spacePlanningList/index"),
  loadingKit
)
const ForgetPassword = loadable(
  () => import("../pages/auth/ForgetPassword"),
  loadingKit
)
const ProfileUpdate = loadable(
  () => import("../pages/auth/ProfileUpdate"),
  loadingKit
)
const EmployeeDetails = loadable(() =>
  import("../pages/employeeDetails/EmployeeDetails")
)
// Dashboards components
const SassDashboard = loadable(
  () => import("../pages/SassDashboard"),
  loadingKit
)
const PlanogramDetails = loadable(
  () => import("../pages/PlanogramDetails"),
  loadingKit
)
const Dashboard = loadable(() => import("../pages/dashboard"), loadingKit)
const Countries = loadable(
  () => import("../pages/content/countries"),
  loadingKit
)
const USStates = loadable(() => import("../pages/content/usStates"), loadingKit)
const Currencies = loadable(
  () => import("../pages/content/currencies"),
  loadingKit
)
const Gender = loadable(() => import("../pages/content/gender"), loadingKit)
const Digitshelf = loadable(
  () => import("../pages/content/digitShelf"),
  loadingKit
)
const CanadianProvincesList = loadable(() =>
  import("../pages/content/canadianProvincesList")
)
const Languages = loadable(
  () => import("../pages/content/languages"),
  loadingKit
)
const UnitsOfMeasure = loadable(
  () => import("../pages/content/unitsOfMeasure"),
  loadingKit
)
const BarcodeTypes = loadable(
  () => import("../pages/content/barcodeTypes"),
  loadingKit
)
const Tax = loadable(() => import("../pages/content/tax"), loadingKit)

const Vendors = loadable(
  () => import("../pages/masterData/vendors"),
  loadingKit
)

const LocationsProductStorage = loadable(
  () => import("../pages/masterData/locationProductStorage"),
  loadingKit
)

const CreateUpdateStorage = loadable(
  () =>
    import("../pages/masterData/locationProductStorage/createUpdateStorage"),
  loadingKit
)

const Locations = loadable(
  () => import("../pages/masterData/locations"),
  loadingKit
)

const Groups = loadable(() =>
  import("../pages/masterData/locations/GroupLocation/GroupLocationsList")
)

const Hierarchy = loadable(
  () => import("../pages/masterData/Hierarchy"),
  loadingKit
)

const HierarchyMapping = loadable(
  () => import("../pages/masterData/HierarchyMapping/index"),
  loadingKit
)

const ConfigurePos = loadable(
  () => import("../pages/masterData/locations/ConfigurePos/Pos"),
  loadingKit
)

const CreateNewPos = loadable(
  () => import("../pages/masterData/locations/ConfigurePos/CreatePos/index"),
  loadingKit
)

const PosDetailedScreen = loadable(
  () => import("../pages/masterData/locations/ConfigurePos/PosDetailed/index"),
  loadingKit
)

const ProductAttribute = loadable(
  () => import("../pages/masterData/products/ProductAttribute/index"),
  loadingKit
)

const Products = loadable(
  () => import("../pages/masterData/products/ProductsList"),
  loadingKit
)

const ProductsGroups = loadable(
  () => import("../pages/masterData/products/ProductGroupPage"),
  loadingKit
)

const ProductDataHierarchy = loadable(
  () => import("../pages/masterData/products/ProductHierarchyPage"),
  loadingKit
)

const ProductDataMapping = loadable(
  () => import("../pages/masterData/products/ProductsMapping/ProductMapping"),
  loadingKit
)

const Customers = loadable(
  () => import("../pages/masterData/customers"),
  loadingKit
)

const Inventory = loadable(
  () => import("../pages/masterData/inventoryManagement/Inventory"),
  loadingKit
)
const InventoryStorage = loadable(
  () => import("../pages/masterData/inventoryStorageManagement/Inventory"),
  loadingKit
)

const InventoryDetails = loadable(
  () =>
    import(
      "../pages/masterData/inventoryManagement/inventoryDetails/InventoryDetails"
    ),
  loadingKit
)
const InventoryStorageDetails = loadable(
  () =>
    import(
      "../pages/masterData/inventoryStorageManagement/inventoryDetails/InventoryDetails"
    ),
  loadingKit
)

const InventoryAdjustments = loadable(
  () => import("../pages/masterData/inventoryManagement/adjustment/adjustment"),
  loadingKit
)

const InventoryCycleCounts = loadable(
  () => import("../pages/masterData/inventoryManagement/cycleCounts/cycleCounts"),
  loadingKit
)
const InventoryLogs = loadable(
  () => import("../pages/masterData/inventoryManagement/inventoryLogs"),
  loadingKit
)
const InventoryStorageAdjustments = loadable(
  () =>
    import(
      "../pages/masterData/inventoryStorageManagement/adjustment/adjustment"
    ),
  loadingKit
)

const AdjustmentsDetails = loadable(
  () =>
    import(
      "../pages/masterData/inventoryManagement/adjustment/adjustmentDetails"
    ),
  loadingKit
)
const AdjustmentsStorageDetails = loadable(
  () =>
    import(
      "../pages/masterData/inventoryStorageManagement/adjustment/adjustmentDetails"
    ),
  loadingKit
)

const AdjustmentsInventoryDetails = loadable(
  () =>
    import(
      "../pages/masterData/inventoryManagement/adjustment/inventoryAdjustment"
    ),
  loadingKit
)

const AdjustmentsStorageInventoryDetails = loadable(
  () =>
    import(
      "../pages/masterData/inventoryStorageManagement/adjustment/inventoryAdjustment"
    ),
  loadingKit
)

const CycleCountsInventoryDetails = loadable(
  () =>
    import(
      "../pages/masterData/inventoryManagement/cycleCounts/inventoryCycleCounts"
    ),
  loadingKit
)

const EquipmentList = loadable(
  () => import("../pages/storeEquipment/EquipmentList"),
  loadingKit
)

const Price = loadable(() => import("../pages/masterData/price"), loadingKit)
const Transactions = loadable(() => import("../pages/transactions"), loadingKit)
const TenantTransactionDetails = loadable(
  () => import("../pages/tenantTransactions/transactions"),
  loadingKit
)
const TenantTransactions = loadable(
  () => import("../pages/tenantTransactions"),
  loadingKit
)
const TenantStores = loadable(
  () => import("../pages/tenantTransactions/StoresList"),
  loadingKit
)
const Configuration = loadable(
  () => import("../pages/alerts/configuration/index"),
  loadingKit
)
const Alerts = loadable(() => import("../pages/alerts/Alerts"), loadingKit)
const NewAlert = loadable(() => import("../pages/alerts/NewAlert"), loadingKit)
const Monitor = loadable(
  () => import("../pages/alerts/monitor/index"),
  loadingKit
)
const Marketing = loadable(() => import("../pages/marketing"), loadingKit)
const Reports = loadable(
  () => import("../pages/reports/detailedTransactionReport"),
  loadingKit
)
const DetailedTransactionReport = loadable(
  () => import("../pages/reports/detailedTransactionReport"),
  loadingKit
)
const SalesSummaryReport = loadable(
  () => import("../pages/reports/salesSummaryReport"),
  loadingKit
)
const InventoryReport = loadable(
  () => import("../pages/reports/inventoryReport"),
  loadingKit
)
const InventoryLogReport = loadable(
  () => import("../pages/reports/inventoryLogReport"),
  loadingKit)
const User = loadable(() => import("../pages/userManagement/users"), loadingKit)
const TaxProvider = loadable(
  () => import("../pages/configuration/taxProvider/TaxProvider"),
  loadingKit
)
const AuditLog = loadable(
  () => import("../pages/logviewer/auditLogs/AuditLog"),
  loadingKit
)
const ApplicationLog = loadable(
  () => import("../pages/logviewer/applicationlogs/ApplicationLog"),
  loadingKit
)
const RolePermission = loadable(
  () =>
    import("../pages/userManagement/rolePermission/rolePermissionList/index"),
  loadingKit
)
// const RolePermission = loadable(() => import("../pages/userManagement/rolePermission/index"), loadingKit)
const Permission = loadable(
  () => import("../pages/userManagement/permission/index"),
  loadingKit
)
const RolePermissionDetails = loadable(
  () => import("../pages/userManagement/rolePermission/index"),
  loadingKit
)
const System = loadable(() => import("../pages/system"), loadingKit)
const EditProfile = loadable(() => import("../pages/editProfile"), loadingKit)
const Settings = loadable(() => import("../pages/settings"), loadingKit)
const PaymentGatewayConfig = loadable(
  () => import("../pages/settings/PaymentGatewayConfig/PaymentGatewayConfig"),
  loadingKit
)
const Help = loadable(() => import("../pages/help"), loadingKit)
const Support = loadable(() => import("../pages/support"), loadingKit)
const Privacy = loadable(() => import("../pages/privacy"), loadingKit)
const StoreEquipment = loadable(
  () => import("../pages/storeEquipment"),
  loadingKit
)
const StoreEquipmentDetails = loadable(
  () => import("../pages/storeEquipment/EquipmentList/StoreEquipmentDetails"),
  loadingKit
)

const LiveMonitoring = loadable(
  () => import("../pages/liveMonitoring"),
  loadingKit
)

const TermsOfService = loadable(
  () => import("../pages/termsOfService"),
  loadingKit
)
const CreateLocation = loadable(() =>
  import("../pages/masterData/locations/CreateLocations")
)
const GroupLocationsList = loadable(() =>
  import("../pages/masterData/locations/GroupLocation/LocationGroupCreatePage")
)
const ProductGroupCreate = loadable(() =>
  import("../pages/masterData/products/createGroupModel/ProductGroupCreatePage")
)
const LocationDetailedPage = loadable(() =>
  import("../pages/masterData/locations/LocationDetailedPage/index")
)

const LocationTabLandingPage = loadable(() =>
  import(
    "../pages/masterData/locations/LocationDetailedPage/LocationLandingPage"
  )
)

const StorageLoadingPage = loadable(() => import("../pages/masterData/locationProductStorage/createUpdateStorage/StorageLandingPage"))

const CustomerDetailedPage = loadable(() =>
  import("../pages/masterData/customers/CustomerDetailedPage")
)

const PriceDetailedPage = loadable(() =>
  import("../pages/masterData/price/priceDetailedPage")
)

const InventoryDetailedPage = loadable(() =>
  import("../pages/masterData/inventory/InventoryDetailedPage")
)

const LocationGroupDetailedPage = loadable(() =>
  import(
    "../pages/masterData/locations/GroupLocation/LocationGroupDetailedPage"
  )
)

const ProductGroupDetailedPage = loadable(() =>
  import("../pages/masterData/products/GroupDetail/ProductGroupDetailedPage")
)

const AddNewAddress = loadable(() =>
  import("../pages/masterData/customers/AddNewAddress")
)

const CreateCustomer = loadable(() =>
  import("../pages/masterData/customers/CreateCustomers")
)
const CreateInventory = loadable(() =>
  import("../pages/masterData/inventory/CreateInventory")
)
const CreateEquipment = loadable(() =>
  import("../pages/storeEquipment/CreateEquipment")
)
const CreatePrice = loadable(() =>
  import("../pages/masterData/price/CreatePrice")
)
const CreateProduct = loadable(
  () =>
    // import("../pages/masterData/createProduct") //MVP 1
    import("../pages/masterData/products/ProductCreatePage") //MVP 2
)

// const CreateLocationGroup = loadable(() =>
//   import("../pages/masterData/locations/GroupLocation/GroupLocationsList/CreateLocationGroup")
// )
const EditProduct = loadable(() =>
  // import("../pages/masterData/editProduct")
  import("../pages/masterData/products/ProductDetailedPage/ProductDetailedPage")
)

const CreateProductGroup = loadable(() =>
  import("../pages/masterData/products/GroupDetail")
)
const Status = loadable(() => import("../pages/status"), loadingKit)

const EditOrderDetails = loadable(() =>
  import("../pages/transactions/EditOrder/EditOrder")
)

const EditTenantTransactionsOrderDetails = loadable(() =>
  import("../pages/tenantTransactions/EditOrder/EditOrder")
)

const EditTurnstileDetails = loadable(() =>
  import("../pages/smartDeviceConfig/turnstile/editTurnstile/index")
)

const EditGondolaDetails = loadable(() =>
  import("../pages/smartDeviceConfig/gondola/editGondola/index")
)

const DeviceMgmtLandingPage = loadable(() =>
  import("../pages/smartDeviceConfig/DeviceManagement/index")
)

const EditDeviceMgmtPage = loadable(() =>
  import("../pages/smartDeviceConfig/DeviceManagement/editDevice/index")
)

const CreateDeviceMgmtPage = loadable(() =>
  import("../pages/smartDeviceConfig/DeviceManagement/createDevice/index")
)

const EditCameraDetails = loadable(() =>
  import("../pages/smartDeviceConfig/camera/editCamera/index")
)
const EditSmartShelfDetails = loadable(() =>
  import("../pages/smartDeviceConfig/smartShelf/editSmartShelf/index")
)

const CreateTurnstileDetails = loadable(() =>
  import("../pages/smartDeviceConfig/turnstile/createtuenstile/index")
)

const CreateSmartShelfDetails = loadable(() =>
  import("../pages/smartDeviceConfig/smartShelf/createSmartShelf/index")
)

const CreateCameraDetails = loadable(() =>
  import("../pages/smartDeviceConfig/camera/createCamera/index")
)

const CreateGondolaDetails = loadable(() =>
  import("../pages/smartDeviceConfig/gondola/createGondola/index")
)

const CreateFSCameraDetails = loadable(() =>
  import("../pages/smartDeviceConfig/cameraFS/createCamera/index")
)

const EditFSCameraDetails = loadable(() =>
  import("../pages/smartDeviceConfig/cameraFS/editCamera/index")
)

const CreateFSEdgeDeviceDetails = loadable(() =>
  import("../pages/smartDeviceConfig/edgeDeviceFS/createEdgeDevice/index")
)

const EditFSEdgeDeviceDetails = loadable(() =>
  import("../pages/smartDeviceConfig/edgeDeviceFS/editEdgeDevice/index")
)

const Tenant = loadable(() => import("../pages/tenant/index"), loadingKit)

const createVendors = loadable(() =>
  import("../pages/masterData/vendors/createUpdateVendors/index")
)

const updateVendors = loadable(() =>
  import("../pages/masterData/vendors/createUpdateVendors/VendorLandingPage")
)

const CreateEditUser = loadable(() =>
  import("../pages/userManagement/users/userCreateEdit/index")
)
const CreateEditTax = loadable(() =>
  import("../pages/configuration/taxProvider/taxCreate/TaxCreateEdit")
)

const TenantCreateEdit = loadable(() =>
  import("../pages/tenant/tenantCreateEdit/TenantCreateEdit")
)

const MonitoringProducts = loadable(
  () => import("../pages/liveMonitoring/products/ProductsList"),
  loadingKit
)

const EditLiveMonitoringProduct = loadable(() =>
  import("../pages/liveMonitoring/products/ProductDetailedPage")
)

const CreateEditPaymentConfig = loadable(() =>
  import("../pages/settings/PaymentGatewayConfig/MultiplePaymentCreateEdit")
)

const RemoteUnlockConfig = loadable(() => import("../pages/remoteUnlock/index"))

//for private route add "guard: AuthGuard" , after component ,(like after "component:Dashbaord",)

const PageComponentLibrary = {
  SpacePlanning,
  FrictionlessStore,
  ForgetPassword,
  CameraFS,
  EdgeDeviceFS,
  SpacePlanningList,
  Gondola,
  Planogram,
  Camera,
  Smartshelf,
  Turnstile,
  StoreConfig,
  inStoreDisplay,
  SignIn,
  SignUp,
  ResetPassword,
  Page404,
  Page500,
  Page403,
  Dashboard,
  Countries,
  USStates,
  Currencies,
  Gender,
  Digitshelf,
  CanadianProvincesList,
  EquipmentList,
  Languages,
  UnitsOfMeasure,
  BarcodeTypes,
  Tax,
  Vendors,
  LocationsProductStorage,
  CreateUpdateStorage,
  Locations,
  Inventory,
  InventoryStorage,
  InventoryDetails,
  InventoryStorageDetails,
  InventoryAdjustments,
  InventoryCycleCounts,
  InventoryLogs,
  InventoryStorageAdjustments,
  AdjustmentsDetails,
  AdjustmentsStorageDetails,
  AdjustmentsInventoryDetails,
  AdjustmentsStorageInventoryDetails,
  CycleCountsInventoryDetails,
  Products,
  Customers,
  Transactions,
  TenantTransactions,
  TenantTransactionDetails,
  TenantStores,
  LiveMonitoring,
  // CreateLocationGroup,
  Marketing,
  Reports,
  Alerts,
  NewAlert,
  Configuration,
  Monitor,
  User,
  TaxProvider,
  AuditLog,
  ApplicationLog,
  RolePermission,
  Permission,
  RolePermissionDetails,
  LocationTabLandingPage,
  StorageLoadingPage,
  System,
  EditProfile,
  Settings,
  Help,
  Support,
  Privacy,
  TermsOfService,
  CreateLocation,
  CreateCustomer,
  CreateProduct,
  EditProduct,
  LocationDetailedPage,
  LocationGroupDetailedPage,
  ConfigurePos,
  CreateNewPos,
  PosDetailedScreen,
  ProductGroupDetailedPage,
  CustomerDetailedPage,
  PriceDetailedPage,
  InventoryDetailedPage,
  AddNewAddress,
  Status,
  Price,
  CreateInventory,
  CreatePrice,
  GroupLocationsList,
  CreateProductGroup,
  StoreEquipment,
  StoreEquipmentDetails,
  CreateEquipment,
  EditOrderDetails,
  EditTurnstileDetails,
  EditGondolaDetails,
  EditCameraDetails,
  EditSmartShelfDetails,
  CreateTurnstileDetails,
  CreateSmartShelfDetails,
  CreateCameraDetails,
  CreateFSCameraDetails,
  EditFSCameraDetails,
  CreateFSEdgeDeviceDetails,
  EditFSEdgeDeviceDetails,
  CreateGondolaDetails,
  Groups,
  Hierarchy,
  ProductsGroups,
  ProductGroupCreate,
  ProductDataHierarchy,
  ProductDataMapping,
  HierarchyMapping,
  ProductAttribute,
  Tenant,
  createVendors,
  updateVendors,
  CreateEditUser,
  CreateEditTax,
  TenantCreateEdit,
  SassDashboard,
  PlanogramDetails,
  MonitoringProducts,
  EditLiveMonitoringProduct,
  DeviceMgmtLandingPage,
  EditDeviceMgmtPage,
  CreateDeviceMgmtPage,
  ProfileUpdate,
  EmployeeDetails,
  EditTenantTransactionsOrderDetails,
  PaymentGatewayConfig,
  SalesSummaryReport,
  InventoryReport,
  InventoryLogReport,
  DetailedTransactionReport,
  CreateEditPaymentConfig,
  RemoteUnlockConfig,
}
export { PageComponentLibrary }
export default PageComponentLibrary
