import {
  Checkbox,
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  TextField as MuiTextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core"
import Styled from "styled-components"
import CSVButton from "react-json-to-csv"

export const PortButton = Styled(Button)`
  border-color:  rgba(0, 0, 0, 0.23);
  color:black;
  margin-bottom: 33px;
  width:"100%"
  min-width: 110px;
  max-width: 110px;
  min-height: 35px;
  // margin-right: 15px;
`
export const DragDropwrapper = Styled(Grid)`
  background: #fff;
  height: 300px;
  padding:10;
  padding-top: 10px;
  border-radius: 5px;
  border: 2px dashed #D3D3D3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  color: #9d9d9d;
  text-transform: uppercase;
  cursor: pointer;
`
export const DialogContentWrapper = Styled(DialogContent)`
  height: auto;
  position: relative;
  overflow-Y: auto;
  overflow-X: hidden;
  padding: 10px;
`
export const ButtonGroup = Styled(Grid)`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top:20px;
    font-weight: bold;
    padding:10px;
    /* position: absolute; */
    /* bottom: 12px; */
   .btn-disable {
    pointer-events: none !important;
    background-color: gray;
    
  }
`
export const ExportButton = Styled(CSVButton)`
  display: flex;
  color:black;
  margin-bottom: 33px;
  /* min-width: 100px; */
  /* max-width: 100px; */
  min-height: 35px;
  /* margin-right: 15px; */
  border: 1px solid  rgba(0, 0, 0, 0.23);
  padding: 5px 15px;
  font-size: 0.875rem;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  background-color: transparent;
  svg {
    margin-right:10px;
  }
  &:hover {
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.04);
  }

`
