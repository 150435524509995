import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { Helmet } from "react-helmet"
import DateFnsUtils from "@date-io/date-fns"
import { Router, Route } from "react-router-dom"
import { createBrowserHistory } from "history"
import { ToastContainer } from "react-toastify"
import _ from "lodash"
import "react-toastify/dist/ReactToastify.css"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import { ThemeProvider } from "styled-components/macro"
import { create } from "jss"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles"
import createTheme from "./theme"
import Routes from "./routes/Routes"
import { QueryParamProvider } from "use-query-params"
import {
  userKeycloakAttributes,
  userProfileDetails,
} from "./reduxSagas/admin/actions"
import jwt_decode from "jwt-decode"
import UserService from "./services/UserService"
import Spinner from "./components/Spinner"
import { DashboardContext } from "./Dashboardcontext/DashboardContext"
import ForgetPassword from "./pages/auth/ForgetPassword"
import Page404 from "./pages/auth/Page404"
import UnAuthorizedUser from "./pages/auth/UnAuthorizedUser"
import AppLogout from "./layouts/AppLogout"
import renderCustomRedirect from "./CustomRedirect"
import { AppContext } from "@utils"
import "./styles.css"

import styled from "styled-components"
export const PageMainWrapper = styled("div")``
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const getLightness = (r, g, b) => 0.2126 * r + 0.7152 * g + 0.0722 * b
const isDark = (r, g, b) => getLightness(r, g, b) < 150
const isLight = (r, g, b) => getLightness(r, g, b) > 150
const hexTorgb = (hex) => [
  ("0x" + hex?.[1] + hex?.[2]) | 0,
  ("0x" + hex?.[3] + hex?.[4]) | 0,
  ("0x" + hex?.[5] + hex?.[6]) | 0,
]
const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
})
export const history = createBrowserHistory()
window["historyW"] = history
const renderDynamicStylesColor = (props) => {
  const { getAppThemes = {} } = props || {}
  return (
    <style>
      {`

        .k-grid-norecords td, .cls-tb-empty-kit {
          height: 50vh !important;
        }
        div[role="presentation"].MuiDialog-root {
          z-index: 1300 !important;
        }
        .k-animation-container.k-animation-container-relative {
          z-index: 1350 !important;
        }
        :root {
          --primaryColor: ${getAppThemes?.primaryColor};
          --secondaryColor: ${getAppThemes?.secondaryColor};
          --optionBgColor: ${getAppThemes?.optionBgColor};
          --primaryTextColor: ${getAppThemes?.primaryTextColor};
          --secondaryTextColor: ${getAppThemes?.secondaryTextColor};
          --darkColor: ${getAppThemes?.darkColor};
          --lightColor: ${getAppThemes?.lightColor};
          --black: ${getAppThemes?.black};
          --white: ${getAppThemes?.white};
          --gray: ${getAppThemes?.gray};
        }
      `}
    </style>
  )
}
function App() {
  const theme = useSelector((state) => state.themeReducer)

  let defaultGetAppThemes = {
    companyLargeLogImageUrl: "",
    companySmallLogImageUrl: "",
    primaryColor: "#df86bb",
    secondaryColor: "#280b2b",
    darkColor: "#140316",
    lightColor: "#fff3fb",
    primaryTextColor: "#ffffff",
    secondaryTextColor: "#ffffff",
    textColor: "#000000",
    black: "#000000",
    white: "#FFFFFF",
    gray: "#d0d0d0",
  }
  let objUserProfileDetails = {}
  const dispatch = useDispatch()
  const [isLoader, setLoader] = React.useState(false)
  const [showMore, setShowMore] = React.useState(false)
  const [getStorageData, setStorageData] = React.useState({
    storageId: "",
    storageList: [],
  })
  const [getAppThemes, setAppThemes] = React.useState(defaultGetAppThemes)
  const flag = useSelector((state) => state.loginstateReducer)
  const [roleName, setRoleName] = React.useState("")
  const [isActive, setIsActive] = React.useState(true)
  const [routesWithActiveStatus, setRoutesWithActiveStatus] = React.useState([])

  /**
   * @Common_API_Start
   */
  const bindUserLogoDetails = async (objTheme, objRealm) => {
    let black = "#000000"
    let white = "#ffffff"
    let gray = "#F9F9FC"

    if (window !== undefined) {
      localStorage.setItem("theme", JSON.stringify(objTheme))
      const {
        primaryColor = "",
        secondaryColor = "",
        darkColor = "",
        lightColor = "",
      } = objTheme || {}
      const { largeLightLogo = "", largeDarkLogo = " ", smallLogo = "" } =
        objRealm || {}

      const rgb1 = hexTorgb(primaryColor)
      const rgb2 = hexTorgb(secondaryColor)

      let primaryTextColor = isDark(...rgb1) ? "#ffffff" : "#000000"
      let secondaryTextColor = isDark(...rgb2) ? "#ffffff" : "#000000"

      localStorage.setItem(
        "realmPaymentConfigEnable",
        objRealm?.paymentConfigEnable || false
      )

      setAppThemes({
        ...getAppThemes,
        primaryTextColor,
        secondaryTextColor,
        lightColor,
        companyLargeLogImageUrl: largeLightLogo,
        companySmallLogImageUrl: smallLogo,
        primaryColor,
        secondaryColor,
        darkColor,
        optionBgColor: lightColor,
        gray,
        black,
        white,
      })
    }
  }

  const bindAttibutes = (data) => {
    const tempFormat = _.reduce(
      data,
      (acc, val, key) => {
        let keyArr = key.split("___")
        let permBool = val[0] === "Y"
        let path = _.join(keyArr, ".")
        _.set(acc, path, permBool)
        return acc
      },
      {}
    )
    dispatch(userKeycloakAttributes(tempFormat))
  }

  /**
   * @Common_API_End
   */

  const onTriggerUserServiceLoggedAPI = () => {
    async function fetchLoggedIn() {
      if (UserService.isLoggedIn()) {
        let mydata = UserService.getToken((res) => {
          return res
        })
        let decoded = jwt_decode(mydata)
        setLoader(true)
        localStorage.setItem("token", mydata)
        localStorage.setItem("userName", decoded.name)
        localStorage.setItem("userEmail", decoded.preferred_username)
        localStorage.setItem("firstName", decoded.given_name)
        localStorage.setItem("lastName", decoded.family_name)
        localStorage.setItem("userId", decoded?.sub)
        if (decoded.Tenant?.length) {
          localStorage.setItem(
            "tenantName",
            decoded?.Tenant[0]?.replace("/", "")
          )
        }

        let roleArray = decoded.userRole
        const myfile = _.map(roleArray, (x, i) => {
          if (
            x !== undefined &&
            x.split("-")?.[0] === "default" &&
            x.split("-")?.[1] === "roles"
          ) {
            roleArray.splice(i, 1)
          }
        })
        var loginRole = ""
        loginRole = _.find(roleArray, function (o) {
          return o !== "offline_access"
        })
        localStorage.setItem("sourceRole", loginRole)
        let roleType = ""

        if (loginRole === "SaaS-Site-Admin") {
          roleType = "master"
        } else if (loginRole === "Sub-SaaS-Admin") {
          roleType = "portal"
        } else if (_.includes(loginRole, "SaaS-Site-Admin")) {
          roleType = "support"
        } else if (_.includes(loginRole, "Sub-SaaS-Admin")) {
          roleType = "portal_support"
        } else {
          roleType = decoded?.Tenant[0]?.toLowerCase().replace("/", "")
        }
        objUserProfileDetails = {
          userName: decoded.name,
          userEmail: decoded.preferred_username,
          firstName: decoded.given_name,
          lastName: decoded.family_name,
          userId: decoded?.sub,
          roleType: roleType.replaceAll(" ", "_"),
        }

        let userDetailBody = {
          tenantName:
            decoded?.Tenant?.length > 0
              ? decoded?.Tenant[0].replace("/", "")
              : "",
          realmId: localStorage.getItem("X-KC-Realm-ID"),
          keycloackRoleName: loginRole,
          keycloakUserId: decoded?.sub,
        }

        let res = await ServiceAPI.fetchCommonRoleData(
          API_END_POINTS.getUserDetail,
          userDetailBody
        )
        if (decoded.userRole?.length > 0 && res?.data?.data) {
          if (res?.data?.data?.tenant) {
            let findShekel = _.filter(
              res?.data?.data?.tenant?.shelfTypes,
              function (o) {
                return o?.name === "Shekel"
              }
            )
            localStorage.setItem(
              "isShekelSync",
              findShekel?.length > 0 ? true : false
            )
            localStorage.setItem("tenantId", res?.data?.data?.tenant?.tenantId)
            localStorage.setItem(
              "tenantPaymentConfigEnable",
              res?.data?.data?.tenant?.paymentConfigEnable || false
            )
          }
          localStorage.setItem(
            "taxProvider",
            res?.data?.data?.tenant?.taxProvider?.name
          )
          localStorage.setItem("role", res?.data?.data?.role?.roleName)
          bindUserLogoDetails(res?.data?.data?.theme, res?.data?.data?.realm)
          dispatch(userProfileDetails(objUserProfileDetails))
          bindAttibutes(res?.data?.data?.role?.attributes)
          setLoader(false)
        } else {
          UserService.doLogout()
          localStorage.clear()
        }
      } else {
        const { location = {} } = window || {}
        if (!location.href.includes("/auth") || !location.href.includes("/")) {
          UserService.doLogin()
        }
      }
    }
    fetchLoggedIn()
  }

  const onInitLoad = () => {
    localStorage.removeItem("expandDetails")
    window?.addEventListener("click", () => {
      const { location = {} } = window || {}
      if (!location.href.includes("/auth")) {
        if (localStorage.getItem("sourceRole") === null) {
          UserService.doLogout()
        }
      }
    })
  }
  React.useEffect(onInitLoad, [])
  React.useEffect(onTriggerUserServiceLoggedAPI, [UserService.isLoggedIn()])

  const renderHelmet = () => {
    return (
      <Helmet
        titleTemplate={`%s | ${window["brandName"]}`}
        defaultTitle={`${window["brandName"]}`}
      />
    )
  }

  const renderAppLogout = () => {
    return (
      <AppLogout
        onActive={() => {
          setIsActive(true)
        }}
        onIdle={() => {
          setIsActive(false)
        }}
      />
    )
  }

  const renderRouteItemForgotPwd = () => {
    return (
      <Route
        path={`/auth/forgetpassword`}
        exact={true}
        render={() => <ForgetPassword history={history} />}
      />
    )
  }

  const renderGlobalRoutes = () => {
    return (
      <DashboardContext.Provider
        value={{
          showMore,
          setShowMore,
          routesWithActiveStatus,
          setRoutesWithActiveStatus,
        }}
      >
        <QueryParamProvider ReactRouterRoute={Route}>
          {isLoader ? <Spinner /> : <Routes />}
        </QueryParamProvider>
      </DashboardContext.Provider>
    )
  }

  const renderAppKit = () => {
    return (
      <AppContext.Provider
        value={{ getAppThemes, setAppThemes, getStorageData, setStorageData }}
      >
        <StylesProvider jss={jss}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
              <ThemeProvider theme={createTheme(theme.currentTheme)}>
                <ToastContainer />
                <Router history={history}>
                  {renderCustomRedirect(history)}
                  {renderRouteItemForgotPwd()}
                  {renderGlobalRoutes()}
                </Router>
              </ThemeProvider>
            </MuiThemeProvider>
          </MuiPickersUtilsProvider>
        </StylesProvider>
      </AppContext.Provider>
    )
  }

  return (
    <PageMainWrapper>
      {renderDynamicStylesColor({ getAppThemes })}
      {renderHelmet()}
      {renderAppLogout()}
      {renderAppKit()}
    </PageMainWrapper>
  )
}
export default App
