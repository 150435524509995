import { makeStyles, useTheme } from "@material-ui/core/styles"
import { green, red } from "@material-ui/core/colors"
import { spacing } from "@material-ui/system"
import styled from "styled-components/macro"
import {
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Button,
  Grid,
} from "@material-ui/core"

export const Typography = styled(MuiTypography)(spacing)

export const ColoredLine = ({ color }) => (
  <hr
    style={{
      borderTop: `1px solid ${color}`,
      marginBottom: 10,
    }}
  />
)

export const useStyles = makeStyles((theme) => ({
  cardOuter: {
    width: "100%",
    // "@media (min-width: 350px) and (max-width: 851px)": {
    //   width: "40%",
    // },
  },
  cls_sub_container: {
    padding: "16px 16px 10px 16px",
    margin: "5px 5px",
    cursor: "pointer",
    boxShadow: "2px 0px 8px 4px #eeeeee",
  },
  inactive: {
    width: "15px",
    height: "15px",
    background: "#ff0000",
    borderRadius: "10px",
    margin: "2px 5px",
  },
  active: {
    width: "15px",
    height: "15px",
    background: "#32d342",
    borderRadius: "10px",
    margin: "2px 5px",
  },
}))

export const StyledMainWrapperCardView = styled(Grid)`
  transition: all 400ms ease;
  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(1.01);
  }
`
