import React, { useState } from "react";
import styled from "styled-components/macro";
import Helmet from "react-helmet";
import { Grid, Button, Typography, Card, Divider } from "@material-ui/core";
import { ImportButtonUI, ExportButtonUI } from "@features"
import { useTranslatiofExcelErrorTemplaten } from "react-i18next"
import { useHistory } from "react-router-dom";
import SharedTable from '../../sharedComponents/Table'
import * as Adm from "@adm"
import { productDataApiMethods } from '../../../../services/masterData/products/api'
import { Add, LineWeight, Sync } from '@material-ui/icons';
import { useListPageState } from "@state"
import { stubData } from "@fixture"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import { PRODUCT_DATA_URLS } from "../../../../utils/url"
import _ from "lodash";
import * as XLSX from "xlsx";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";

function ExcelErrorTemplate(props) {
    const {
        openPopupDialog = "",
        handleDialogClose = () => { },
        excelErrorCountDetails = {},
        displayExcelException = "",
        setOpenPopupDialog = () => { },
        setFileData = () => { },
        downloadExcelError = () => { },
        downloadTemplateClick = () => { },
        set_reRender = () => { }
    } = props || {};


    const useStyles = makeStyles(theme => ({
        dialogPaper: {
            minHeight: "20vh",
            minWidth: "60vw"
        },
        divider: {
            borderRight: "0.1em solid rgba(0, 0, 0, 0.1)",
            padding: "15px 15px",
            margin: '10px 0px'
        },
        textHeader: {
            marginBottom: "15px",
            fontSize: '16px',
            fontFamily: 'Roboto',
            //fontStyle: 'normal',
            fontWeight: 1000,
            LineWeight: '28px'
        },
        cardStyle: {
            marginTop: "10px",
            background: '#FFFFFF',
            boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.07)',
            borderRadius: '8px',
            height: "200px",
            overflowY: "scroll",
            // '&::-webkit-scrollbar,::-webkit-scrollbar-track': {
            //     width: '4px',
            //     height: '20px',
            //     boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            //     webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            //     '-webkit-appearance': 'none',
            //     backgroundColor: 'transparent',
            // },
            // '&::-webkit-scrollbar-thumb': {
            //     backgroundColor: theme.palette.almostBlack[400],
            //     height: '150px',
            //     width: '8px',
            //     borderRadius: '8px',

            // },
        },
        '& table': {
            border: '1px solid black',
            borderCollapse: 'collapse'
        },
        '& th': {
            border: '1px solid black',
            borderCollapse: 'collapse'
        },
        '& td': {
            border: '1px solid black',
            borderCollapse: 'collapse'
        }
    }));

    const classes = useStyles();

    const padTo2Digits = (num) => {
        return num.toString().padStart(2, '0');
    }

    const formatDate = (date) => {
        return [
            padTo2Digits(date.getDate()),
            padTo2Digits(date.getMonth() + 1),
            date.getFullYear(),
        ].join('-');
    }

    return (
        <>
            <Dialog
                open={openPopupDialog}
                onClose={handleDialogClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                classes={{ paper: classes.dialogPaper }}
            >
                <DialogTitle style={{ fontWeight: 1000 }} id="scroll-dialog-title">Log Details</DialogTitle>
                <DialogContent dividers={'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        //ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        <>
                            <Card style={{
                                boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.07)',
                                borderRadius: '4px'
                            }}>
                                <Grid container row="true" style={{ height: '100px' }}>
                                    <Grid item lg={2} md={2} sm={12} xs={12} className={classes.divider}>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            Added
                                        </Typography>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            {excelErrorCountDetails?.createdCount === "" ? "-" : excelErrorCountDetails?.createdCount}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={12} xs={12} className={classes.divider}>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            Changed
                                        </Typography>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            {excelErrorCountDetails?.updatedCount === "" ? "-" : excelErrorCountDetails?.updatedCount}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={12} xs={12} className={classes.divider}>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            Ignored
                                        </Typography>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            {excelErrorCountDetails?.failedCount === "" ? "-" : excelErrorCountDetails?.failedCount}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3} md={2} sm={12} xs={12} className={classes.divider}>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            Imported By
                                        </Typography>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            {localStorage.getItem("firstName") === null ? "" : _.upperCase(localStorage.getItem("firstName"))} {" "}
                                            {localStorage.getItem("lastName") === null ? "" : _.upperCase(localStorage.getItem("lastName"))}
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3} md={2} sm={12} xs={12} className={classes.divider}>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            Imported Date
                                        </Typography>
                                        <Typography variant="h5" className={classes.textHeader}>
                                            {formatDate(new Date())}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>

                            <Card className={classes.cardStyle}>
                                <div>
                                    <table style={{
                                        width: '100%', border: '1px solid black',
                                        borderCollapse: 'collapse',
                                    }}>
                                        {displayExcelException.map((details) => (
                                            <>
                                                <tr style={{
                                                    border: '1px solid rgba(0, 0, 0, 0.1)',
                                                }}>
                                                    <td style={{ width: '33%' }}>
                                                        <Typography style={{
                                                            fontFamily: 'Roboto',
                                                            //fontStyle: 'normal',
                                                            fontWeight: 600,
                                                            fontSize: '12px',
                                                            LineWeight: '20px',
                                                            color: '#000000DE',
                                                            padding: '7px',
                                                            paddingLeft: '10px'
                                                        }}>{details.split("-")[0]}</Typography>

                                                    </td>
                                                    <td style={{
                                                        border: '1px solid rgba(0, 0, 0, 0.1)'
                                                    }}>
                                                        <Typography style={{
                                                            fontFamily: 'Roboto',
                                                            //fontStyle: 'normal',
                                                            fontWeight: 600,
                                                            fontSize: '12px',
                                                            LineWeight: '20px',
                                                            color: '#000000DE',
                                                            paddingLeft: '10px'
                                                        }}>{details.split("-")[1]}{"-"}{details.split("-")[2]}{" "}{details.split("-")[3]}
                                                        </Typography>

                                                    </td>
                                                </tr>
                                            </>
                                        ))}
                                    </table>
                                </div>
                            </Card>

                        </>

                    </DialogContentText>
                </DialogContent>
                <DialogActions justify="space-between">
                    <Button variant="contained" onClick={() => {
                        setOpenPopupDialog(false);
                        setFileData({
                            files: [],
                        });
                        set_reRender(true);
                    }}>{_.toUpper('Cancel')} </Button>
                    <Button variant="contained" color="primary" onClick={downloadExcelError}>{_.toUpper('Download')} </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ExcelErrorTemplate;