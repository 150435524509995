/* eslint-disable import/no-anonymous-default-export */
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import axios from "axios"
import _ from "lodash"
import { JSUtils } from "@utils"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const { headerAxios, API_BASE_URL } = ServiceAPI
const authHeaderAxios = axios.create()

const headers = {
  "X-KC-Realm-ID": localStorage.getItem("X-KC-Realm-ID"),
  "X-KC-Host": localStorage.getItem("X-KC-Host"),
}

export default {
  createBusinessUsers: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.createBusinessUser,
      body
    )
  },

  updateBusinessUsers: (body, userId) => {
    return headerAxios.put(
      API_BASE_URL + API_END_POINTS.updateBusinessUser + "/" + userId,
      body
    )
  },
  updateAdminUser: (body, userId) => {
    return headerAxios.put(
      API_BASE_URL + API_END_POINTS.updateAdminUser + "/" + userId,
      body
    )
  },
  createAdminUser: (body, userId) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.createAdminUser, body)
  },
  getRoles: () => {
    return headerAxios.get(API_BASE_URL + API_END_POINTS.getRoles)
  },
  getTenantUserRoles: () => {
    return headerAxios.get(API_BASE_URL + API_END_POINTS.getTenantUserRoles)
  },
  getAdminUserRoles: () => {
    return headerAxios.get(API_BASE_URL + API_END_POINTS.getAdminUserRoles)
  },
  createTenantRole: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.createTenantRole,
      body
    )
  },
  updateTenantRole: (body, roleId) => {
    return headerAxios.put(
      API_BASE_URL + API_END_POINTS.updateTenantRole + "/" + roleId,
      body
    )
  },
  deleteTenantRole: (roleId) => {
    return headerAxios.delete(
      API_BASE_URL + API_END_POINTS.deleteTenantRole + "/" + roleId
    )
  },
  getTenantRoles: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getTenantRoles, body)
  },
  getAdminRole: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getAdminRoles, body)
  },
  createAdminRole: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.createAdminRole, body)
  },
  updateAdminRole: (body, roleId) => {
    return headerAxios.put(
      API_BASE_URL + API_END_POINTS.updateAdminRole + "/" + roleId,
      body
    )
  },
  deleteAdminRole: (roleId) => {
    return headerAxios.delete(
      API_BASE_URL + API_END_POINTS.deleteAdminRole + "/" + roleId
    )
  },
  getBusinessUsers: (body) => {
    return headerAxios.post(
      API_BASE_URL +
      API_END_POINTS.getBusinessUsers +
      "/" +
      localStorage.getItem("tenantId"),
      body
    )
  },
  getAdminUsers: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getAdminUsers, body)
  },
  getTaxProviders: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getTaxProviders, body)
  },
  createTaxProvider: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.saveTaxProvider, body)
  },
  getlogmodules: () => {
    let apiCall =
      localStorage.getItem("role") === "SaaS-Site-Admin" ||
        localStorage.getItem("role") === "Sub-SaaS-Admin"
        ? API_END_POINTS.getSassLogModules
        : API_END_POINTS.getTenantLogmodules
    return headerAxios.get(API_BASE_URL + apiCall)
  },
  getAuditLogs: (body) => {
    let apiCall =
      localStorage.getItem("role") === "SaaS-Site-Admin" ||
        localStorage.getItem("role") === "Sub-SaaS-Admin"
        ? API_END_POINTS.getSassAuditLogs
        : API_END_POINTS.getTenantAuditLogs
    return headerAxios.post(API_BASE_URL + apiCall, body)
  },
  getApplicationLogs: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getApplicationLogs,
      body
    )
  },
  getPopupAuditLogs: (recordId) => {
    let tenantID = localStorage.getItem("tenantId")
    let apiCall =
      _.includes(localStorage.getItem("sourceRole"), "SaaS-Site-Admin") ||
        _.includes(localStorage.getItem("sourceRole"), "Sub-SaaS-Admin")
        ? API_END_POINTS.getSassAuditLogById + "/" + recordId
        : API_END_POINTS.getTenantAuditLogById + "/" + tenantID + "/" + recordId
    return headerAxios.get(API_BASE_URL + apiCall)
  },
  searchEmployeeActivities: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.searchEmployeeActivities,
      body
    )
  },
  createTenantUsers: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.createTenantUser,
      body
    )
  },
  getTenantsUsers: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getTenantsUsers, body)
  },
  generateOtp: (body) => {
    return authHeaderAxios.post(
      API_BASE_URL + API_END_POINTS.generateOtp,
      body,
      {
        headers: headers,
      }
    )
  },

  createRealmWithAdmin: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.createRealmwithAdmin,
      body
    )
  },

  getTenantRealms: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getTenantRealms, body)
  },

  getRealmDetailWithAdmin: (RealmId) => {
    return headerAxios.get(
      API_BASE_URL + API_END_POINTS.getRealmDetailWithAdmin + "/" + RealmId
    )
  },

  updateRealmDetail: (body, tenantId) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.updateRealmDetail + "/" + tenantId,
      body
    )
  },

  verifyOtp: (body) => {
    return authHeaderAxios.post(API_BASE_URL + API_END_POINTS.verifyOtp, body, {
      headers: headers,
    })
  },
  updatePassword: (body) => {
    return authHeaderAxios.post(
      API_BASE_URL + API_END_POINTS.updatePassword,
      body,
      {
        headers: headers,
      }
    )
  },
  verifyPassword: (body) => {
    return authHeaderAxios.post(
      API_BASE_URL + API_END_POINTS.verifyPassword,
      body,
      {
        headers: headers,
      }
    )
  },
  generatePhoneNumberOTP: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.generatePhoneNumberOTP,
      body
    )
  },
  verifyPhoneNumberOTP: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.verifyPhoneNumberOTP,
      body
    )
  },
  updateTenantAddress: (body, tenantId) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.updateTenantAddress + "/" + tenantId,
      body
    )
  },
  getRetailersList: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getRetailersList,
      body
    )
  },
  getTenantDetailWithAdmin: (TenantID) => {
    return headerAxios.get(
      API_BASE_URL + API_END_POINTS.getTenantDetailWithAdmin + "/" + TenantID
    )
  },
  getUserDetail: (id) => {
    return headerAxios.get(
      API_BASE_URL +
      API_END_POINTS.getBusinessUserbyId +
      "/" +
      localStorage.getItem("tenantId") +
      "/" +
      id
    )
  },

  getSubSaaSUserDetail: (id) => {
    return headerAxios.get(
      API_BASE_URL + API_END_POINTS.getAdminUserbyId + "/" + id
    )
  },
  updateTenantDetail: (body, tenantId) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.updateTenantDetail + "/" + tenantId,
      body
    )
  },

  getThemes: () => {
    return headerAxios.get(API_BASE_URL + API_END_POINTS.getThemesList)
  },

  createTheme: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.createTheme, body)
  },

  createPaymentConfig: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.createPaymentConfig,
      body
    )
  },

  getPaymentConfig: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getPaymentConfig,
      body
    )
  },
  deletePaymentConfig: (body) => {
    return headerAxios.delete(
      API_BASE_URL + API_END_POINTS.deletePaymentConfig,
      body
    )
  },
  updatePaymentConfigStatus: (paymentId) => {
    return headerAxios.put(
      API_BASE_URL + API_END_POINTS.updatePaymentConfigStatus + "/" + paymentId
    )
  },
  stripeTestConnection: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.stripeTestConnection,
      body
    )
  },

  getProductAdjustmentHistories: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getProductAdjustmentHistories,
      body
    )
  },
  getStorageAdjustmentHistories: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getStorageAdjustmentHistories,
      body
    )
  },
  getProductMappingDetail: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getProductMappingDetail,
      body
    )
  },
  getStorageMappingDetail: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getStorageMappingDetail,
      body
    )
  },
  getProductAdjustments: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getProductAdjustments,
      body
    )
  },
  getStorageAdjustments: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getStorageAdjustments,
      body
    )
  },
  createProductAdjustment: (body, isStore = true) => {
    return headerAxios.post(
      API_BASE_URL +
      (isStore
        ? API_END_POINTS.createProductAdjustment
        : API_END_POINTS.createStorageAdjustment),
      body
    )
  },
  getPaymentTypes: () => {
    return headerAxios.get(API_BASE_URL + API_END_POINTS.getPaymentTypes)
  },
  getNewAlertList: (body) => {
    let customPayload = JSUtils?.customPayload(body)
    customPayload =
      _.size(customPayload.sort_by) === 0
        ? {
          ...customPayload,
          sort_by: [
            {
              key_name: "updated_at",
              sort_by_ascending: false,
            },
          ],
        }
        : customPayload
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getNewAlertList,
      customPayload
    )
  },
  getAlertSetupLevelAll: () => {
    return headerAxios.get(API_BASE_URL + API_END_POINTS.getAlertSetupLevelAll)
  },
  getAlertSetupEntityFilterByIds: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getAlertSetupEntityFilterByIds,
      body
    )
  },
  getAlertSetupSeverityAll: () => {
    return headerAxios.get(
      API_BASE_URL + API_END_POINTS.getAlertSetupSeverityAll
    )
  },
  createSetupAlert: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.createSetupAlert,
      body
    )
  },
  updateSetupAlert: (body, codeId) => {
    return headerAxios.put(
      API_BASE_URL + API_END_POINTS.updateSetupAlert + "/" + codeId,
      body
    )
  },
  unconfiguredTenantAlertList: (body, headers) => {
    console.log("unconfiguredTenantAlertList", body, headers)
    return authHeaderAxios.post(
      API_BASE_URL + API_END_POINTS.unconfiguredTenantAlertList,
      body,
      {
        headers: headers,
      }
    )
  },
  configurationSave: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.configurationSave,
      body
    )
  },
  getShelfTypes: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getShelfTypes, body)
  },
  getStorageUnits: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getStorageUnits, body)
  },
  getStorageUnitById: async (_id) => {
    return headerAxios.get(
      API_BASE_URL + API_END_POINTS.getStorageUnitById + "/" + _id
    )
  },
  updatestorageunit: (body, _id) => {
    return headerAxios.put(
      API_BASE_URL + API_END_POINTS.updateStorageUnit + "/" + _id,
      body
    )
  },
  getlocations: (body) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getAllStores, body)
  },

  // vendorCreate: (body) => {
  //   return headerAxios.post(API_BASE_URL + API_END_POINTS.vendorCreate, body)
  // }

  getVendorDetail: (id, headers) => {
    return headerAxios.get(
      API_BASE_URL + API_END_POINTS.getVendorDetail + "/" + id,
      {
        headers: headers,
      }
    )
  },
  getVendorList: (body = {}, headers = {}, needNoneVendor = true) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.getVendorList + "&need_none_vendor=" + needNoneVendor, body, {
      headers: headers,
    })
  },
  deleteVendor: (body = {}, headers = {}) => {
    return headerAxios.delete(API_BASE_URL + API_END_POINTS.deleteVendor, body, {
      headers: headers,
    })
  },
  vendorCreate: (body, headers) => {
    return headerAxios.post(API_BASE_URL + API_END_POINTS.vendorCreate, body, {
      headers: headers,
    })
  },
  vendorLogo: (body, headers, id = "") => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.vendorLogo + `?company_id=${id}`,
      body,
      {
        headers: headers,
      }
    )
  },
  vendorUpdate: (body, headers, id = "") => {
    return headerAxios.put(
      API_BASE_URL + API_END_POINTS.vendorUpdate + "/" + id,
      body,
      {
        headers: headers,
      }
    )
  },
  updateCompanyonStorageUnitMapping: (body, headers, storageUnitId = "") => {
    return headerAxios.put(
      API_BASE_URL + API_END_POINTS.updateCompanyonStorageUnitMapping + "/" + storageUnitId,
      body,
      {
        headers: headers,
      }
    )
  },
  getProductCycleCounts: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.getProductCycleCounts,
      body
    )
  },
  createProductCycleCount: (body) => {
    return headerAxios.post(
      API_BASE_URL + API_END_POINTS.createProductCycleCount,
      body
    )
  },
  cycleCountExcelTemplate: async () => {
    return headerAxios.get("https://dev8.digitmart.ai/import/api/export/sample/download"
      // API_BASE_URL + API_END_POINTS.createProductCycleCount
    )
  },
  excelTemplateDownload: async (url, headers) => {
    return headerAxios.get(
      //url,
      API_BASE_URL + url,
      {
        headers: headers,
      }
    )
  },
  InventoryExcelTemplate: async (url, body, headers) => {
    return headerAxios.post(url,
      body,
      {
        headers: headers,
      }
    )
  },
}
