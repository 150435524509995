import { getTreeFromFlatData, getFlatDataFromTree } from "react-sortable-tree";
import _ from 'lodash';

export const getProcessedFlatData = ({ data = [], checkedList = [], idPath = 'category_id' }) => {
    let temp = data.reduce((acc, o, i) => {
        let temp = Object.assign({}, _.get(o, 'node', o));
        temp.isChecked = _.indexOf(checkedList, _.get(temp, idPath)) !== -1;
        acc.push(temp);
        return acc;
    }, []);
    return temp
}

export const getNewFlatDataWithOldExpandedStatus = (newData, oldData, idPath = 'category_id', nodeToBeKeptInExpandedStatus, callback = () => { }) => {
    // if (_.isBoolean(_.get(oldData, '[0].node.expanded', _.get(oldData, '[0].expanded', undefined)))) {
    let temp = newData.map(o => {
        let tempNodeData = Object.assign({}, o);
        let tempOldNodeData = _.find(oldData, x => _.get(x, `node.${idPath}`, _.get(x, idPath)) === _.get(o, `node.${idPath}`, _.get(o, idPath)));
        tempNodeData.expanded = _.get(tempOldNodeData, 'node.expanded', _.get(tempOldNodeData, 'expanded', false));//_.find(oldData, x => _.get(x, 'node.category_id', _.get(x, 'category_id')))?.expanded || false
        if (nodeToBeKeptInExpandedStatus && _.get(o, `node.${idPath}`, _.get(o, idPath)) === nodeToBeKeptInExpandedStatus) {
            tempNodeData.expanded = true;
            if (_.isFunction(callback)) {
                callback()
            }
        }
        return tempNodeData;
    });
    return temp;
    // }
    // return newData;
}

//We are using this function only on Product screen Hierarchy for child node expand......
export const getNewFlatDataWithOldExpandedStatusArray = (newData, oldData, idPath = 'category_id', nodeToBeKeptInExpandedStatus, parentArrayId = [],
    callback = () => { }) => {
    let temp = newData.map(o => {
        let tempNodeData = Object.assign({}, o);
        let tempOldNodeData = _.find(oldData, x => _.get(x, `node.${idPath}`, _.get(x, idPath)) === _.get(o, `node.${idPath}`, _.get(o, idPath)));
        tempNodeData.expanded = _.get(tempOldNodeData, 'node.expanded', _.get(tempOldNodeData, 'expanded', false));//_.find(oldData, x => _.get(x, 'node.category_id', _.get(x, 'category_id')))?.expanded || false
        _.map(parentArrayId, (parentids, index) => {
            if (nodeToBeKeptInExpandedStatus && _.get(o, `node.${idPath}`, _.get(o, idPath)) === parentids) {
                tempNodeData.expanded = true;
                if (_.isFunction(callback)) {
                    callback()
                }
            }
        })

        return tempNodeData;
    })
    return temp
}

export const getNodeId = n => _.get(n, 'category_id', _.get(n, 'node.category_id', undefined));
export const getNodeKey = ({ treeIndex, node }) => treeIndex;
export const getParentNodeId = n => _.isString(_.get(n, 'mapped_parent_categories.[0]')) ? _.get(n, 'mapped_parent_categories.[0]', null) : null;
export const getTreeData = (data, getKeyFn = getNodeId, getParentKeyFn = getParentNodeId, rootKey = null) => {
    let temp = getTreeFromFlatData({
        flatData: data || [], //.map(node => ({ ...node, title1: node.name })),
        getKey: getKeyFn, // resolve a node's key
        getParentKey: getParentKeyFn, // resolve a node's parent's key
        rootKey: rootKey, // The value of the parent key when there is no parent (i.e., at root level)
    })
    return temp
}

export const getFlatData = (data, getKeyFn = getNodeId) => {
    let temp = getFlatDataFromTree({
        treeData: data, getNodeKey: getKeyFn, ignoreCollapsed: false
    })
    return temp
}

export const getAllChildrenIds = (data, idPath = 'category_id') => {
    let temp = data?.children?.reduce((acc, o, i) => {
        acc.push(_.get(o, idPath, ''))
        if (o?.children) {
            acc = _.concat(acc, getAllChildrenIds(o))
        }
        return acc
    }, []);
    return temp || [];
}