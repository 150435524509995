import _ from "lodash"

const { location = {} } = window || {}

const fetchAccessToken = () => {
  return window.localStorage.getItem('accessToken');
}
let apiEndpoint = process.env.REACT_APP_API_ENDPOINT;
apiEndpoint = location.origin.includes("localhost") ? apiEndpoint : "https://api" + location?.host + "/portal"

export async function fetchSignUpData(data) {

  const allProductsPromise = fetch(
    apiEndpoint + ":3012/api/auth/signup",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({data})
      body: JSON.stringify({
        permission_enabled: true,
        firstName: data.firstName,
        middleInitial: data.middleName,
        lastName: data.lastName,
        email: data.email,
        password: data.password,
        title: data.title,
        customer_acquisition_source: "loyalty app",
        terms_and_conditions_consent_date: ["03/18/2021"],
        terms_and_conditions_version_number: 1,
        privacy_policy_consent_date: "03/18/2021",
        privacy_policy_consent_version: 1,
        device_id: "F678-GH32",
        loyalty_tier: "Platinum Tier-1",
        associate_flag: true,
        marketing_communication: "yes",
        address: [
          {
            line1: data.line1,
            line2: data.line2,
            line3: data.line3,
            line4: data.line4,
            city: data.city,
            state: data.state,
            country: data.country,
            zip_code_5_plus_4: data.zipcodeFull,
            zip_code_5: data.zipcodeFive,
            zip_code_4: data.zipcodeFour,
          },
        ],
        phoneNumber: data.phoneNo,
      }),
    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        status: response.status,
        signup: data,
      }
    })
  )
  return allProductsPromise
}
export async function fetchSignIn(data) {

  const allProductsPromise = fetch(
    apiEndpoint + ":3012/api/auth/login",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({data})
      body: JSON.stringify({
        username: data.email,
        password: data.password,
      }),
    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "signin": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchProductListInitial(data) {
  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/paginate?",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchProductList(data, data2) {
  const itemLimit = data2 === undefined ? "" : "&limit=" + data2;
  const itemID = data === undefined ? "" : data.itemID !== "" ? "&item_id=" + data.itemID : "";
  const barcode = data === undefined ? "" : data.barCode !== "" ? "&item_barcode_type=" + data.barCode : "";
  const itemUPC = data === undefined ? "" : data.itemUPC !== "" ? "&itemUPC=" + data.itemUPC : "";
  const title = data === undefined ? "" : data.title !== "" ? "&title=" + data.title : "";
  const list = data === undefined ? "" : data.list !== "" ? "&msrp=" + data.list : "";
  const inventory = data === undefined ? "" : data.inventory !== "" ? "&inventory_on_hand=" + data.inventory : "";



  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/paginate?" + itemID + barcode + itemUPC + title + list + inventory + itemLimit,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchCreateProduct(data, data2) {
  const projectDescription = data2 === undefined ? "" : data2;
  const unit_of_measure = data.unit_of_measure;
  const itemUPC = data.itemUPC;
  const stockCode = data.stockCode;
  const title = data.title;
  const item_id = data.item_id;
  const item_barcode_type = data.item_barcode_type;
  const category = data.item_hierarchy;
  const location_id = data.location_id;
  const item_storage = data.item_storage;
  const inventory_on_hand = data.inventory_on_hand;
  const offer_price = data.offer_price;
  const customretailprice = data.customretailprice === undefined ? "" : data.customretailprice;
  const store_type = data.store_type;
  const categoryParent = data.categoryParent;
  const category2 = data.category;
  const sub_categories = data.sub_categories;
  // const hierar = {
  //   "category": data.categoryParent,
  //   "subcategory": data.category,
  //   "class": data.sub_categories,
  //   "subclass" : data.item_hierarchy

  // }
  const brand = data.brand === undefined ? "" : data.brand;
  const country_of_origin = data.country_of_origin === undefined ? "" : data.country_of_origin;
  const manufacturer_address = data.manufacturer_address === undefined ? "" : data.manufacturer_address;
  const item_dimensions = data.item_dimensions === undefined ? "" : data.item_dimensions;
  const item_weight = data.item_weight === undefined ? "" : data.item_weight;
  const item_package_dimensions = data.item_package_dimensions === undefined ? "" : data.item_package_dimensions;
  // const itemDimensions = data.itemDimensions;
  const color = data.color;

  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/create",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({data})
      body: JSON.stringify({
        store_id: store_type,
        description: projectDescription,
        itemUPC: itemUPC,
        stockCode: stockCode,
        offer_price: offer_price,
        msrp: customretailprice,
        brand: brand,
        country_of_origin: country_of_origin,
        manufacturer_address: manufacturer_address,
        item_dimensions: item_dimensions,
        item_weight: item_weight,
        item_package_dimensions: item_package_dimensions,
        //       categoryAncestors: categoryParent,
        //       categoryslug: category2,
        // sub_category:sub_categories,
        title: title,
        item_id: item_id,
        item_barcode_type: [
          item_barcode_type
        ],
        item_hierarchy: [
          {
            "category": data.categoryParent,
            "subcategory": data.category,
            "class": data.sub_categories,
            "subclass": data.item_hierarchy
          }
        ],
        location_id: location_id,
        unit_of_measure: [unit_of_measure
        ],
        item_storage: "ambient",
        inventory_on_hand: inventory_on_hand,
        active: true,
        color: color,
      }),
    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchAddImage(data, data2) {
  const formData = new FormData();

  const store_type = data.store_type;
  const itemUPC = data.itemUPC;
  const file = data2;
  formData.append('image', file[0]);


  const allProductsPromise = fetch(apiEndpoint + ":3005/api/products/upload/" + store_type + "/" + itemUPC, {
    // const allProductsPromise = fetch("http://d0ee5043e9dd.ngrok.io/api/products/upload"+"/"+store_type+"/"+itemUPC, {
    method: 'POST',
    headers: {
      //  'Content-Type': 'application/json',
      'Authorization': `Bearer \xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${fetchAccessToken()}`,
    },
    body: formData
  })
    .then(response => response.json()
      .then(data => {
        return {
          "status": response.status,
          "products": data
        }
      }))

  return allProductsPromise;

  // const allProductsPromise = fetch(
  //   apiEndpoint+":3005/api/products/upload"+"/"+store_type+"/"+itemUPC,

  //     {
  //     method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //         mode: 'no-cors',

  //    'Authorization': `Bearer \xa0\xa0\xa0\xa0\xa0\xa0\xa0 ${fetchAccessToken()}`, 
  //     // body: JSON.stringify({
  //     //   image: file[0],
  //     // }),
  //     body: formData,
  //   }
  // ).then((response) =>
  //   response.json().then((data) => {
  //     return {
  //       "status": response.status,
  //       "products": data,
  //     }
  //   })
  // )
  // return allProductsPromise
}

export async function fetchBarCodeInitial() {
  const allProductsPromise = fetch(
    apiEndpoint + ":3100/api/barcode?",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchBarCode(data) {
  const itemID = "&limit=" + data;
  const allProductsPromise = fetch(
    apiEndpoint + ":3100/api/barcode?" + itemID,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchStoreTypeInitial() {
  const allProductsPromise = fetch(
    apiEndpoint + ":3004/api/stores/stores?",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchStoreType(data) {
  const itemID = "&limit=" + data;
  const allProductsPromise = fetch(
    apiEndpoint + ":3004/api/stores/stores?" + itemID,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchUnitOfMeasureInitial() {
  const allProductsPromise = fetch(
    apiEndpoint + ":3100/api/measurement?",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchUnitOfMeasure(data) {
  const itemID = "&limit=" + data;
  const allProductsPromise = fetch(
    apiEndpoint + ":3100/api/measurement?" + itemID,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchProductDetails(data, data2) {
  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/store/" + data + "/" + data2,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}


export async function fetchEditProduct(data, data2, data3, data4, data5, data6) {
  const projectDescription = data2 === undefined ? "" : data2;
  const itemHandlingValue = data3 === undefined ? "" : data3;
  const equipmentValue = data4 === undefined ? "" : data4;

  const unit_of_measure = data.unit_of_measure === undefined ? "" : data.unit_of_measure;
  const itemUPC = data.itemUPC;
  const stockCode = data.stockCode;
  const title = data.title;
  const item_id = data.item_id;
  const item_barcode_type = data.item_barcode_type;
  const item_hierarchy = data.item_hierarchy;
  const location_id = data.location_id;
  const item_storage = data.item_storage;
  const inventory_on_hand = data.inventory_on_hand;
  const offer_price = data.offer_price === undefined ? "" : data.offer_price;
  const customretailprice = data.customretailprice === undefined ? "" : data.customretailprice;
  const store_type = data.store_type;
  const categoryParent = data.categoryParent === undefined ? "" : data.categoryParent;
  const category = data.category === undefined ? "" : data.category;
  const sub_categories = data.sub_categories === undefined ? "" : data.sub_categories;
  const color = data.color === undefined ? "" : data.color;
  const brand = data.brand === undefined ? "" : data.brand;
  const country_of_origin = data.country_of_origin === undefined ? "" : data.country_of_origin;
  const manufacturer_address = data.manufacturer_address === undefined ? "" : data.manufacturer_address;
  const item_dimensions = data.item_dimensions === undefined ? "" : data.item_dimensions;
  const item_weight = data.item_weight === undefined ? "" : data.item_weight;
  const item_package_dimensions = data.item_package_dimensions === undefined ? "" : data.item_package_dimensions;
  const kosher_1 = data.kosher_1 === undefined ? "" : data.kosher_1;
  const kosher_2 = data.kosher_2 === undefined ? "" : data.kosher_2;
  const kosher_3 = data.kosher_3 === undefined ? "" : data.kosher_3;
  const kosher_4 = data.kosher_4 === undefined ? "" : data.kosher_4;
  const pick_time = data.pick_time === undefined ? "" : data.pick_time;
  const prep_time = data.prep_time === undefined ? "" : data.prep_time;
  const hold_time = data.hold_time === undefined ? "" : data.hold_time;
  const pack_time = data.pack_time === undefined ? "" : data.prep_time;

  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/update/store/" + data5 + "/" + data6,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({data})

      body: JSON.stringify({
        store_id: store_type,
        description: projectDescription,
        itemUPC: itemUPC,
        stockCode: stockCode,
        offer_price: offer_price,
        msrp: customretailprice,
        //       categoryAncestors: categoryParent,
        //       categoryslug: category,
        // sub_category:sub_categories,
        title: title,
        item_id: item_id,
        item_barcode_type: [
          item_barcode_type
        ],
        item_hierarchy: [
          {
            "category": categoryParent,
            "subcategory": category,
            "class": sub_categories,
            "subclass": item_hierarchy
          }
        ],
        location_id: location_id,
        unit_of_measure: [unit_of_measure
        ],
        item_storage: "ambient",
        inventory_on_hand: inventory_on_hand,
        active: true,
        color: color,
        brand: brand,
        country_of_origin: country_of_origin,
        manufacturer_address: manufacturer_address,
        item_dimensions: item_dimensions,
        item_weight: item_weight,
        item_package_dimensions: item_package_dimensions,
        kosher_1: kosher_1,
        kosher_2: kosher_2,
        kosher_3: kosher_3,
        kosher_4: kosher_4,
        pick_time: pick_time,
        prep_time: prep_time,
        hold_time: hold_time,
        pack_time: pack_time,
        handling_instructions: itemHandlingValue,
        equipment_handling: equipmentValue
      }),
    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )

  return allProductsPromise
}


export async function fetchDeleteProduct(data) {
  const storeID = data.storeID;
  const upcID = data.upc
  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/delete/store/" + storeID + "/" + upcID,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchDeleteMultipleProduct(data) {

  const item_id = data.item_id;
  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/deletemany",
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        item_id,
      })
    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchProductListRedux(data) {
  const itemLimit = data === undefined ? "" : "&limit=" + data;




  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/paginate?" + itemLimit,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchCategoryList(data) {
  const allProductsPromise = fetch(
    apiEndpoint + ":3014/api/productcategory/getParentCategories",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchSubCategoryList(data) {
  const allProductsPromise = fetch(
    apiEndpoint + ":3014/api/productcategory/getSubcategories",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}


export async function fetchProductGroupList() {
  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/getAllProductGroups",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchProductGroupInitial() {
  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/productGroupPaginate?",
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchProductGroupListFinal(data, data2) {
  const itemLimit = data2 === undefined ? "" : "&limit=" + data2;
  const itemID = data === undefined ? "" : data.itemID !== "" ? "&group_name=" + data.itemID : "";
  const barcode = data === undefined ? "" : data.barCode !== "" ? "&description=" + data.barCode : "";
  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/productGroupPaginate?" + itemID + barcode + itemLimit,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchCreateProductGroup(data) {

  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/createproductgroup",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({data})
      body: JSON.stringify({
        group_name: data.project_name,
        description: data.project_desc,
        group_active_status: true
      }),
    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}


export async function fetchAssociateProductGroup(data) {

  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/editproductgroup/" + data.group_id,
    {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({data})
      body: JSON.stringify({
        group_name: data.group_name,
        item_ids: data.item_ids,
        description: data.description,
        group_active_status: data.group_active_status
      }),
    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}


export async function fetchDeleteProductGroup(data) {

  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/deleteproductgroup/" + data.groupID,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchProductGroupListRedux(data) {
  const itemLimit = data === undefined ? "" : "&limit=" + data;




  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/productGroupPaginate?" + itemLimit,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}


export async function fetchProductGroupDetails(data) {
  const allProductsPromise = fetch(
    apiEndpoint + ":3005/api/products/getProductGroupById/" + data,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchOrdersListInitial(data) {
  const allProductsPromise = fetch(
    apiEndpoint + ":3008/api/transaction/getTransactions",
    {
      method: "POST",
      body: JSON.stringify({ filter: {}, limit: 10, page: 1, sort: {} }),
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return data
    })
  )
  return allProductsPromise
}

export async function fetchOrdersListFinal(data) {
  const itemLimit = data === undefined ? "" : "&limit=" + data;

  const allProductsPromise = fetch(
    apiEndpoint + ":3008/api/transaction/getTransactions",
    {
      method: "POST",
      body: JSON.stringify({ filter: {}, limit: data || 10, page: 1, sort: {} }),
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return data
    })
  )
  return allProductsPromise
}

export async function fetchSearchResults(data) {
  const search = data === undefined ? "" : "&searchTerm=" + data;
  const size = "&size=5"
  const from = "&from=0"

  const allProductsPromise = fetch(
    apiEndpoint + ":3008/api/order/searchAnyOrder?" + size + from + search,
    {
      method: "GET",
      headers: { "Content-Type": "application/json" },

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchDataAndTimeResults(data) {

  const allProductsPromise = fetch(
    apiEndpoint + ":3008/api/order/getOrdersByDate",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        date: data.date,
        time: data.time,

      }),

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}

export async function fetchStateAndStatusResults(data) {

  const allProductsPromise = fetch(
    apiEndpoint + ":3008/api/order/getOrdersByStateOrStatus",
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        customer_state: data.customer_state,
        order_status: data.order_status,

      }),

    }
  ).then((response) =>
    response.json().then((data) => {
      return {
        "status": response.status,
        "products": data,
      }
    })
  )
  return allProductsPromise
}