import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex', flexDirection: 'column', height: '100%', //border: '1px solid blue',
        // padding: theme.spacing(2),
        // background: theme.palette.background.paper,
        '& .k-grid': {
            boxShadow: ['none', '!important']
        }
    },
    header: {
        display: 'flex', width: '100%', height: 48, //border: '1px solid red',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: "0px"
    },
    height60: {
        height: 60
    },
    noPadding: {
        padding: 0
    },
    headerActionsContainer: {
        display: 'flex', width: '100%', height: '100%', //border: '1px solid red',
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: theme.spacing(2.5)
    },
    content: {
        width: '100%', height: `calc(100% - 48px)`, //border: '1px solid red'
    },
    title: {
        fontSize: theme.typography.pxToRem(16),
        fontWeight: 600,
        marginRight: "10px"
    },
    headerTitle: {
        fontSize: theme.typography.pxToRem(20),
    }
}))

export default useStyles