import React, { useState, useEffect } from "react"
import { List, ListItem, ListItemText, IconButton, Select, MenuItem, FormControl, Grid, } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { CloseIcon } from "@iconsGallery"
import { addLocationSerivces } from "@reduxHelper"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, width: 250 },
  },
}
const useStyles = makeStyles(theme => ({
  basicIconProperty: { color: "rgba(158, 158, 158, 1)", fontSize: "30px", },
  iconUpDown: { fontSize: "30px", color: "rgba(0, 0, 0, 1)", },
  dividerBottom: { borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)", },
  listStyle: {
    width: "100%",
    padding: "0px",
    display: "flex",
    flexDirection: "column-reverse",
  },
  listItem: { borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)", height: "50px", alignItems: "center", },
  selectField: {
    // width: "350px",
    width: "100%",
    // marginRight: "10px",
    '& .MuiOutlinedInput-input': {
      padding: '12px 14px',
      color: "#9E9E9E",
    },
    '& .MuiSelect-iconOutlined': {
      right: '0px'
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
  },
  cardSadhow: { boxShadow: "0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset" },
  scrollWrap: {
    overflowY: "auto",
    overflowX: "hidden",
    maxHeight: "300px",
    minHeight: "auto",
    '&::-webkit-scrollbar,::-webkit-scrollbar-track': {
      width: '3px',
      height: '8px',
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      '-webkit-appearance': 'none',
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.almostBlack[500],
      height: '5px',
      width: '3px',
      borderRadius: '8px',
    },
  },
}))
function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

function LocationServiceContent(props) {
  const { isDetailedPage = false, setAllowPrompt = () => { }, setClearVal = () => { }, clearVal = false, serviceOldValue = [], isCreatePage = false } = props
  const theme = useTheme()
  const classes = useStyles()
  const dispatch = useDispatch()
  let names = props.names || []
  const reduxData = useSelector((state) => state.addLocationReducer)
  const [editMode, setEditMode] = React.useState(true)
  const [personName, setPersonName] = useState(_.get(reduxData, "locationServices") !== null ? reduxData.locationServices : _.get(props, "data", []))



  useEffect(() => {
    if (_.get(reduxData, "locationServices") !== null) {
      setPersonName(personName)
    }
  }, [personName])

  // useEffect(() => {
  //   if (clearVal === true) {
  //      setPersonName(serviceOldValue)
  //   }
  //       setClearVal(false)

  // },[])

  useEffect(() => {
    dispatch(addLocationSerivces(personName));
  }, [personName])

  useEffect(() => {
    if (!isCreatePage) {
      setPersonName(_.get(props, "data", []))
    }
  }, [props.data])

  const handleChange = (event) => {
    setPersonName(event.target.value);
    setAllowPrompt(true)
    if (isDetailedPage) {
      props.setAnyChanges(true)
    } else {
      props.seterrormsg(false);
    }
  }
  const deleteItem = (name) => {
    let newItem = personName.filter((_item) => {
      return _item !== name
    })
    setAllowPrompt(true)
    setPersonName(newItem)
    if (isDetailedPage) {
      personName.length !== 1 ? props.setAnyChanges(true) : props.setAnyChanges(false)
    } else {
      personName.length === 0 ? props.seterrormsg(true) : props.seterrormsg(false);
    }
  }



  return (
    <>
      <div >
        {props.edit ? (
          <div className={classes.scrollWrap}>
            <List className={classes.listStyle}>
              {personName.map((name, index) => (
                <div key={`${name}${index}`} className={classes.dividerBottom}>
                  <ListItem alignItems="flex-start" style={{ padding: '10px 16px' }}>
                    <ListItemText primary={name} />
                  </ListItem>
                </div>
              ))}
            </List>
          </div>
        ) : (
          <>
            <div style={{ padding: isDetailedPage ? "15px 0px 15px 15px" : "20px 0px", borderBottom: isDetailedPage ? '1px solid #EEEEEE' : "" }}>
              <Grid container item xs={12} sm={8} md={6} lg={5} xl={4}> 
                <FormControl variant="outlined" className={classes.selectField}>
                  <Select
                  className={classes.SelectServiceField}
                    labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      placeholder="Select"
                    multiple={true}
                    value={personName}
                    onChange={handleChange}
                    displayEmpty
                    MenuProps={MenuProps}
                    inputProps={{ "aria-label": "Without label" }}
                    size="small"
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <div>{props.label}</div>
                      }
                      return selected.join(", ")
                    }}

                  >
                    <MenuItem disabled value="">
                      <div>{props.label}</div>
                    </MenuItem>
                    {names.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, personName, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>

                  {(isDetailedPage && personName.length > 0) || (!isDetailedPage && !props.errormsg) ? null :
                    <p style={{ color: "#f44336", margin: '5px 0px' }}>&ensp; Select atleast a Location Service</p>
                  }
                  <div>
                    {" "}
                    {editMode === false ? (
                      personName.length >= 0 ? (
                        <p></p>
                      ) : null
                    ) : (
                      ""
                    )}
                  </div>
                </FormControl>
              </Grid>
            </div>
            <div style={{ padding: isDetailedPage ? "10px" : "" }} className={classes.scrollWrap}>
              <List className={classes.listStyle} style={{ border: personName.length > 0 ? '1px solid #EEEEEE' : "" }}>
                {personName.map((name, index) => (
                  <ListItem
                    alignItems="flex-start"
                    key={`${index}${name}`}
                    className={classes.listItem}
                  >
                    <ListItemText primary={name} />
                    <IconButton
                      onClick={() => {
                        deleteItem(name)
                      }} style={{ left: "20px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export { LocationServiceContent }
export default LocationServiceContent
