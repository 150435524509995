import React from "react";
import {
  Checkbox,
  IconButton,
  Link,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";


const headCells = [
  {
    id: "groupName",
    numeric: false,
    disablePadding: true,
    label: "Group Name",
  },
  { id: "groupDesc", numeric: false, disablePadding: false, label: "Group Description" },
  // { id: "Barcode Type", numeric: true, disablePadding: false, label: "Barcode Type" },
  // { id: "Brand", numeric: true, disablePadding: false, label: "Brand" },
  // { id: "Manfacturer", numeric: true, disablePadding: false, label: "Manfacturer" },

];
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const SortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead style={{ backgroundColor: "#F4F7FA" }}>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              style={{ padding: "10px" }}
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={SortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    </>
  );

}
export default EnhancedTableHead