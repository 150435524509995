import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import OtpInput from "react-otp-input"
import { Link, useLocation } from "react-router-dom"
import { Formik } from "formik"
import * as Yup from "yup"
import _ from "lodash"
import { InfoOutlined, Visibility, VisibilityOff } from "@material-ui/icons"
import userApi from "../../services/user/api"
import * as Adm from "@adm"
import { CheckCircleIcon } from "../../IconsGallery/IconsGallery"
// import UserService from "../../services/UserService"
import * as ServiceAPI from "@services"
import { useTheme } from "styled-components"
const { headerAxios, API_BASE_URL } = ServiceAPI

const useStyles = makeStyles({
  dgrabImage: {
    maxWidth: "500px",
    padding: "22px",
    // borderRadius: "20px",
    height: "fit-content !important",
    position: "fixed !important",
    right: "24px !important",
    top: "0 !important",
    bottom: "0 !important",
    margin: "auto !important",
    minWidth: "400px !important",
    backgroundColor: "#fff",
    width: "386px !important",
  },
  forgetNames: {
    fontWeight: "bold",
    marginTop: "20px",
  },
  emailName: {
    fontWeight: "200",
    color: "#D3D3D3",
  },
  textInput: {
    // width: "372px",
    // height: "56px",
    height: "50px",
    "&.MuiOutlinedInput": {
      height: "100px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },

  textEmailInput: {
    height: "50px",
    "&.MuiOutlinedInput": {
      height: "100px",
    },
    "& .MuiInputBase-input": {
      textTransform: "lowercase !important",
    },
  },
  textInput1: {
    // width: "372px",
    // height: "56px",
    // height: '60px',
    "&.MuiInputBase-input": {
      // height: "100px"
    },
  },
  ButtonStyle: {
    // backgroundColor: "rgb(63 194 212)",
    color: "#FFFFFF",
    backgroundColor: "var(--primaryColor)",
    borderRadius: "8px",
    padding: "12px",
    fontWeight: "600",
    fontSize: "15px",
  },
  cancelButton: {
    backgroundColor: "var(--gray)",
    border: "1px solid #3399FF",
    color: "#3399FF",
    borderRadius: "8px",
    padding: "12px",
    fontWeight: "600",
    fontSize: "15px",
  },
  LinkStyle: {
    color: "rgb(63 194 212)",
    textDecoration: "none",
  },
  paraStyle: {
    color: "#ADADAD",
    lineHeight: "25px",
    fontWeight: "400",
    fontStyle: "normal",
    fontFamily: "Open Sans",
    fontSize: "16px",
  },
  paragraphVefication: {
    color: "rgb(114 118 123)",
  },
  verifiyEmail: {
    color: "#3399FF",
    fontSize: "20px",
  },
  imgStyle: {
    cursor: "pointer",
  },
})


const { location = {} } = window || {};
let dmEnvType = location?.host?.split(".")[1];
let colorCode = "#df86bb"; 
switch (dmEnvType) {
  case "digitmart":
    colorCode = "#df86bb";
    break;
  case "digitsquare":
    colorCode = "#10565c";
    break;
  case "digitkart":
    colorCode = "#10565c";
    break;
  default:
    colorCode = "#df86bb";
    break;
}

const SendOtp = (props) => {
  const {
    setEmailName,
    emailName,
    otpValue,
    setOtpValue = {},
    alertMessage,
    alertType,
    setLoading,
    setAlertMessage,
    loading,
    setAlertType,
    setAlert,
    handleSignIn = () => { },
    setTenantId,
  } = props || {}
  const {
    THEME_ASSET = "dev-theme-assets",
    LOGO_FOLDER = "digitmart",
    DOMAIN_ENV = "dev",
    location = {} } = window || {}
  const classes = useStyles()
  const history = useHistory()
  const [error, setError] = useState(false)
  let regexScript = new RegExp(
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/
  )
  const validationSchema = {
    email: Yup.string()
      .email("Please enter valid email Id")
      .required("Please enter your email id"),
    // .matches(
    //     /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/,
    //     'Please enter valid email Id',
    // ),
  }
  const sendOptApi = (email) => {
    setAlert(false)
    let body = {
      email,
      operationType: "forgot",
    }
    setEmailName(email)
    setLoading(true)
    userApi
      .generateOtp(body)
      .then(async (res) => {
        setOtpValue(res?.data?.data)
        await setAlertType(_.get(res, "data.status", "error"))
        await setAlertMessage(_.get(res, "data.data.message"))
        await setAlert(true)
        await setLoading(false)
        setTenantId(_.get(res, "data.data.tenantId"))
        props.setIsOtp("true")
      })
      .catch((err) => {
        setAlertType(_.get(err, "data.status", "error"))
        setAlertMessage(
          _.get(
            err,
            "data.data.message",
            _.get(err, "response.data.message", "Try again later!")
          )
        )
        setAlert(true)
        setLoading(false)
      })
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={async (values, { resetForm }) => {
          sendOptApi(values?.email, setEmailName)
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          resetForm,
          handleReset,
        }) => {
          return (
            <form
              noValidate
              onSubmit={handleSubmit}
              className={classes.cardContent}
            >
              <Grid className={classes.dgrabImage}>
                <img
                  style={{
                    display: "flex",
                    height: "fit-content",
                    width: "200px",
                    marginBottom: "20px",
                  }}
                  className="cls-dmlogoKit"
                  src={DOMAIN_ENV === "portal" ? `https://stdigitmartproddata.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/large_dark_logo.png` :
                    `https://stdgrabdatanonprod001.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/large_dark_logo.png`}
                  alt=""
                />
                <Typography
                  className={classes.forgetNames}
                  variant="h1"
                  gutterBottom
                >
                  Send OTP to
                </Typography>
                <div className={classes.paraStyle}>
                  <p>
                    Enter the email that you used when register to recover your
                    password. You will receive a one time password
                  </p>
                </div>

                <div style={{ position: "relative", paddingBottom: "22px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      value={values?.email}
                      className={classes.textEmailInput}
                      placeholder="Enter your email address"
                      variant="outlined"
                      name="email"
                      autoComplete="off"
                      error={Boolean(touched?.email && errors?.email)}
                      helperText={touched?.email && errors?.email}
                      onBlur={handleBlur}
                      onChange={(props) => {
                        handleChange(props)
                        // setEmailName(values?.email)
                      }}
                    // onKeyUp={(props) => {
                    // handleChange(props)
                    // }}
                    ></TextField>

                    {Boolean(touched?.email) && (
                      <div
                        style={{
                          position: "absolute",
                          top: "15px",
                          right: "-30px",
                        }}
                      >
                        {errors?.email === undefined ||
                          _.isEmpty(errors?.email) ? (
                          <CheckCircleIcon
                            style={{
                              color: "#4CAF50",
                              fontSize: "15px",
                            }}
                          />
                        ) : (
                          <InfoOutlined style={{ color: "#f44336" }} />
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div style={{ padding: "50px" }}></div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  <Button
                    type="submit"
                    //color="primary"
                    disabled={!_.isEmpty(errors)}
                    className={classes.ButtonStyle}
                    style={{ backgroundColor: colorCode }}
                    variant="contained"
                  >
                    Send OTP
                  </Button>
                  <Button
                    type="button"
                    onClick={() => {
                      handleSignIn()
                    }}
                    className={classes.cancelButton}
                    variant="contained"
                  >
                    CANCEL
                  </Button>
                </div>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
const Verification = (props) => {
  const {
    verifyOTP,
    emailName,
    handleSignIn = () => { },
    setLoading = () => { },
    tenantId,
  } = props || {}
  const [code, setCode] = useState("")
  const [verifyData, setVerifyData] = useState({})
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  // let emailName = localStorage.getItem("userEmail")
  const classes = useStyles()
  const handleChange = (code) => {
    setCode(code)
  }
  const verifyOTPApi = () => {
    setAlert(false)
    let body = {
      email: emailName,
      otp: code,
      tenantId: (_.includes(localStorage.getItem("sourceRole"), "SaaS-Site-Admin")) ||
        (_.includes(localStorage.getItem("sourceRole"), "Sub-SaaS-Admin")) ? "" : tenantId,
    }
    setLoading(true)
    userApi
      .verifyOtp(body)
      .then(async (res) => {
        await setAlert(true)
        await setAlertType(_.get(res, "data.status", "success"))
        // await setAlertMessage(_.get(res, "data.data.message"))
        await setAlertMessage(
          _.get(res, "data.data.message", "Try Again Later")
        )
        await setLoading(false)
        setVerifyData(res?.data?.data)
        setTimeout(() => {
          props.setIsOtp("true1")
        }, 2000)
      })
      .catch((err) => {
        setAlertType("error")
        setAlertMessage(_.get(err, "response.data.message"))
        setAlert(true)
        setLoading(false)
      })
  }

  // const handleSignIn = () => {
  //     setTimeout(() => {
  //         // UserService.doLogout()
  //         localStorage.clear()
  //     }, 100)
  // }

  return (
    <>
      <Grid className={classes.dgrabImage}>
        <img
          src="/static/img/backArrow.svg"
          alt="back Arrow"
          onClick={() => props.setIsOtp("")}
          className={classes.imgStyle}
        />
        <Typography className={classes.forgetNames} variant="h1" gutterBottom>
          Verification
        </Typography>
        <div className={classes.paraStyle}>
          <p>An OTP has been sent to your email:</p>
        </div>
        <Typography className={classes.verifiyEmail} variant="h1" gutterBottom>
          {emailName}
        </Typography>
        <div className={classes.paraStyle}>{/* <p>Resend Code 0.52</p> */}</div>
        <div style={{ position: "relative" }}>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={6}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              inputStyle={{
                border: "1px solid #eee",
                borderRadius: "8px",
                width: "54px",
                height: "54px",
                fontSize: "12px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
            />
            {/* <div style={{ position: 'absolute', top: "13px", right: '-30px' }}>
                            {<>  {code ? <CheckCircleIcon style={{
                                color: "#4CAF50", fontSize: '15px'
                            }} /> : null}
                            </>}
                        </div> */}
          </div>
        </div>
        <div style={{ padding: "50px" }}></div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={() => {
              verifyOTPApi()
            }}
            disabled={code.length < 6 ? true : false}
            color="primary"
            className={classes.ButtonStyle}
            variant="contained"
          >
            Verify OTP
          </Button>
          <Button
            // color="primary"
            onClick={() => {
              handleSignIn()
            }}
            className={classes.cancelButton}
            variant="contained"
          >
            CANCEL
          </Button>
        </div>
      </Grid>
      {alert ? (
        <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
      ) : null}
    </>
  )
}
const ResetComponents = (props) => {
  const {
    verifyOTP,
    setEmailName,
    alertMessage,
    alertType,
    setLoading,
    setAlertMessage,
    loading,
    setAlertType,
    setAlert,
    alert,
    emailName,
    handleSignIn = () => { },
    tenantId,
  } = props || {}
  const classes = useStyles()
  const history = useHistory()
  const [update, setUpdate] = useState({})
  const [isVerified, setIsVerified] = useState(false)
  // let emailName = emailName
  let regexPassword = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])/
  )
  const validationSchema = {
    newPassword: Yup.string()
      .required("Password is required")
      .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$%^&+=])/, {
        message:
          "Password must be at least one uppercase,and one special character",
      })
      .min(8, "Password must be at least 8 charaters."),

    confirmPswd: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Password must match")
      .required("Password confirm is required"),
  }
  // const handleSignIn = () => {
  //     setTimeout(() => {
  //         // UserService.doLogout()
  //         localStorage.clear()
  //     }, 1000)
  // }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          newPassword: "",
          confirmPswd: "",
          showNewPswdIcon: false,
          showConfirmPswdIcon: false,
        }}
        validationSchema={Yup.object().shape(validationSchema)}
        onSubmit={(values, { resetForm }) => {
          setAlert(false)
          let body = {
            email: emailName,
            newPassword: values.newPassword,
            tenantId: (_.includes(localStorage.getItem("sourceRole"), "SaaS-Site-Admin")) ||
              (_.includes(localStorage.getItem("sourceRole"), "Sub-SaaS-Admin")) ? "" : tenantId,
          }
          // setAlert(false)
          setLoading(true)
          userApi
            .updatePassword(body)
            .then(async (res) => {
              await setAlertType(_.get(res, "data.status", "error"))
              await setAlertMessage(
                _.get(res, "data.data.message", "Try Again Later")
              )
              await setAlert(true)
              await handleSignIn()
              await setLoading(false)
              setUpdate(res?.data?.data)
            })
            .catch((err) => {
              setAlertType("error")
              setAlertMessage(
                _.get(err, "response.data.message", "Try Again Later")
              )
              setAlert(true)
              setLoading(false)
            })
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
          resetForm,
          handleReset,
        }) => {
          const handleClickShowPassword = (type, value) => {
            // setshowIconType(type)
            setFieldValue([type], value)
          }
          const handleMouseDownPassword = (event) => {
            event.preventDefault()
          }

          return (
            <form
              noValidate
              onSubmit={handleSubmit}
              className={classes.cardContent}
            >
              <Grid className={classes.dgrabImage}>
                <img
                  src="/static/img/backArrow.svg"
                  alt="back Arrow"
                  onClick={() => props.setIsOtp("true")}
                  className={classes.imgStyle}
                />
                <Typography
                  className={classes.forgetNames}
                  variant="h1"
                  gutterBottom
                >
                  Reset your password
                </Typography>
                <div className={classes.paraStyle}>
                  <p>
                    Password must be alphanumeric+spl characters inputs and at
                    least 8 to 15 characters
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    marginTop: "20px",
                  }}
                >
                  <TextField
                    value={values?.newPassword}
                    variant="outlined"
                    // size="small"
                    className={classes.textInput1}
                    type={values.showNewPswdIcon ? "text" : "password"}
                    // onKeyPress={(e) => handleKeyPress(e, 'text')}
                    placeholder="Enter new password"
                    name="newPassword"
                    error={Boolean(touched?.newPassword && errors?.newPassword)}
                    helperText={touched?.newPassword && errors?.newPassword}
                    onBlur={handleBlur}
                    onChange={(props) => {
                      // setAllowPromptCheck(values?.address1)
                      handleChange(props)
                      // onGlobalUpdate()
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() =>
                              handleClickShowPassword(
                                "showNewPswdIcon",
                                !values.showNewPswdIcon
                              )
                            }
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {values.showNewPswdIcon ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  ></TextField>
                  <div style={{ position: "relative", paddingBottom: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <TextField
                        value={values?.confirmPswd}
                        variant="outlined"
                        // size="small"
                        className={classes.textInput1}
                        type={values.showConfirmPswdIcon ? "text" : "password"}
                        // onKeyPress={(e) => handleKeyPress(e, 'text')}
                        placeholder="Enter confirm password"
                        name="confirmPswd"
                        error={Boolean(
                          touched?.confirmPswd && errors?.confirmPswd
                        )}
                        helperText={touched?.confirmPswd && errors?.confirmPswd}
                        onBlur={handleBlur}
                        onChange={(props) => {
                          // setAllowPromptCheck(values?.address1)
                          handleChange(props)
                          // onGlobalUpdate()
                        }}
                        onKeyUp={(e) => {
                          if (values?.newPassword === e?.target?.value) {
                            setIsVerified(true)
                          } else {
                            setIsVerified(false)
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  handleClickShowPassword(
                                    "showConfirmPswdIcon",
                                    !values.showConfirmPswdIcon
                                  )
                                }
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showConfirmPswdIcon ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      ></TextField>
                    </div>
                    <div
                      style={{
                        position: "absolute",
                        top: "11px",
                        right: "-30px",
                      }}
                    >
                      {isVerified ? (
                        <CheckCircleIcon
                          style={{
                            color: "#4CAF50",
                            fontSize: "15px",
                          }}
                        />
                      ) : null}
                    </div>
                  </div>

                  {
                    <>
                      {" "}
                      {isVerified && (
                        <Typography style={{ color: "#4CAF50" }}>
                          Password Matched
                        </Typography>
                      )}
                    </>
                  }
                  {/* {<>  {!isVerified && <Typography style={{ color: "#f44336" }}>
                                        New Password and confirm password doesn't match
                                    </Typography>}
                                    </>} */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Button
                      type="submit"
                      color="primary"
                      disabled={!_.isEmpty(errors)}
                      className={classes.ButtonStyle}
                      variant="contained"
                    >
                      Reset Password
                    </Button>
                    <Button
                      // color="primary"
                      onClick={() => {
                        handleSignIn()
                      }}
                      className={classes.cancelButton}
                      variant="contained"
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </Grid>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
const ForgetPassword = (props) => {
  const { history } = props
  const classes = useStyles()
  const [isOtp, setIsOtp] = useState("")
  const [verifyOTP, setVerifyOTP] = useState("")
  const [emailName, setEmailName] = useState("")
  const [otpValue, setOtpValue] = useState({})
  const [loading, setLoading] = useState(false)
  const [alertMessage, setAlertMessage] = React.useState("")
  const [alertType, setAlertType] = React.useState("")
  const [alert, setAlert] = React.useState(false)
  const [tenantId, setTenantId] = useState()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const isDesktop = useMediaQuery(theme.breakpoints.between("lg", "xl"))
  const handleSignIn = () => {
    setTimeout(() => {
      if (window.location.origin.includes("localhost")) {
        window.location.replace(`${process.env.REACT_APP_SIGNIN_URL}`)
      }
      else {
        window.location.replace(`${window.location.origin}`)
      }
      // history.push("http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com:4003/")
      // UserService.doLogout()
      // localStorage.clear()
    }, 100)
  }

  useEffect(() => {
    let isBdyDome = document.querySelector("body")
    let isLogoDome = document.querySelector(".cls-dmlogoKit")
    let domainNameKit = "digitmart"
    let companyName = "digitmart"
    const {
      LOGO_FOLDER = "digitmart",
      THEME_ASSET = "dev-theme-assets",
      DOMAIN_ENV = "dev",
      location = {} } = window || {};

    if (location.origin.includes("localhost")) {
    } else {
      let isDomainDM = location.origin.includes(companyName)
      let domainOrginal = companyName
      const hostName = location?.host?.split(".")

      if (hostName.length === 2) {
        const [lcdomainOrginal = companyName] = location?.host?.split(".")
        domainOrginal = lcdomainOrginal
      } else if (hostName.length === 3) {
        const [_x, lcdomainOrginal = companyName] = location?.host?.split(".")
        domainOrginal = lcdomainOrginal
      } else {
      }

      if (location?.host) {
        domainNameKit = domainOrginal
      }
    }
    if (isLogoDome) {
      isLogoDome.setAttribute(
        "src",
        DOMAIN_ENV === "portal" ?
          `https://stdigitmartproddata.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/large_dark_logo.png` :
          `https://stdgrabdatanonprod001.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/large_dark_logo.png`
      )
    }
    if (isBdyDome) {
      isBdyDome.setAttribute(
        "style",
        `background:url(${DOMAIN_ENV === "portal" ? `https://stdigitmartproddata.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/bg.jpg` :
          `https://stdgrabdatanonprod001.blob.core.windows.net/${THEME_ASSET}/${LOGO_FOLDER}/bg.jpg`});
         background-repeat: no-repeat;
         background-size: cover;
         height: 100%;
         width: 100%;
         display: flex;
         align-items: stretch;
         position: relative;`
      )
    }

    return () => {
      if (isBdyDome) {
        isBdyDome.setAttribute("style", `background:#f9f9fc !important;`)
      }
    }
  }, [])

  return (
    <>
      <Adm.BackdropOverlay open={loading} />
      <Grid
        container
        direction="row"
        item
        lg={11}
        md={12}
        sm={12}
        xs={12}
        xl={12}
      >
        <Grid
          container
          direction="row"
          item
          lg={12}
          md={12}
          sm={12}
          xs={12}
          xl={12}
        >
          {/* <Grid
            container
            direction="row"
            item
            lg={7}
            md={7}
            sm={12}
            xs={12}
            xl={6}
            style={{ margin: "-8px", padding: "0px" }}
          >
            {isTablet ? (
              <img
                alt=""
                style={{ opacity: "0" }}
                src="/static/img/avatars/login.jpg"
                width="600"
                height="648"
              />
            ) : (
              <img
                style={{ opacity: "0" }}
                alt=""
                src="/static/img/avatars/login.jpg"
                width="800"
                height="648"
              />
            )}
          </Grid> */}
          <Grid
            container
            direction="row"
            item
            lg={5}
            md={5}
            sm={12}
            xs={12}
            xl={6}
          >
            {/* <img src="/static/img/avatars/DgrabIcon.jpg" height="90" /> */}
            {isOtp === "" && (
              <SendOtp
                isOtp={isOtp}
                setIsOtp={setIsOtp}
                emailName={emailName}
                setEmailName={setEmailName}
                setOtpValue={setOtpValue}
                otpValue={otpValue}
                loading={loading}
                setLoading={setLoading}
                alert={alert}
                setAlert={setAlert}
                alertMessage={alertMessage}
                setAlertMessage={setAlertMessage}
                alertType={alertType}
                setAlertType={setAlertType}
                setTenantId={setTenantId}
                handleSignIn={handleSignIn}
              />
            )}
            <ShowComponent
              isOtp={isOtp}
              setIsOtp={setIsOtp}
              verifyOTP={verifyOTP}
              alertType={alertType}
              setAlertType={setAlertType}
              setVerifyOTP={setVerifyOTP}
              setAlert={setAlert}
              alertMessage={alertMessage}
              setAlertMessage={setAlertMessage}
              emailName={emailName}
              setEmailName={setEmailName}
              otpValue={otpValue}
              setOtpValue={setOtpValue}
              setLoading={setLoading}
              loading={loading}
              alert={alert}
              handleSignIn={handleSignIn}
              tenantId={tenantId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        {alert ? (
          <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
        ) : null}
      </Grid>
    </>
  )
}

export default ForgetPassword

const ShowComponent = ({
  isOtp,
  setIsOtp,
  setVerifyOTP,
  alert,
  setAlert,
  alertMessage,
  setAlertMessage,
  alertType,
  setAlertType,
  verifyOTP,
  emailName,
  setEmailName,
  otpValue,
  setOtpValue = {},
  setLoading,
  loading,
  handleSignIn = () => { },
  tenantId,
}) => {
  if (isOtp === "true") {
    return (
      <Verification
        setIsOtp={setIsOtp}
        verifyOTP={verifyOTP}
        setVerifyOTP={setVerifyOTP}
        emailName={emailName}
        setLoading={setLoading}
        loading={loading}
        alert={alert}
        setAlert={setAlert}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        alertType={alertType}
        setAlertType={setAlertType}
        handleSignIn={handleSignIn}
        tenantId={tenantId}
      />
    )
  } else if (isOtp === "true1") {
    return (
      <ResetComponents
        setIsOtp={setIsOtp}
        emailName={emailName}
        setEmailName={setEmailName}
        setLoading={setLoading}
        loading={loading}
        alert={alert}
        verifyOTP={verifyOTP}
        setAlert={setAlert}
        alertMessage={alertMessage}
        setAlertMessage={setAlertMessage}
        alertType={alertType}
        setAlertType={setAlertType}
        handleSignIn={handleSignIn}
        tenantId={tenantId}
      />
    )
  } else {
    return null
  }
}
