import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({

    root: {
        height: "100%",
        overflowY: "scroll",
        width: "100%",

    },
    //table header styling
    locationheader: {
        paddingTop: "10px !important",
        paddingBottom: "0px !important",
        // borderBottom: "0.1px solid rgba(0, 0, 0, 0.1)",
        borderLeft: "0",
        // marginTop:"10px",
        // paddingLeft: "10",
        paddingRight: "0",
        background: "#e0e0e0",
        padding: '15px',
        display: "flex",
        width: "100%",
        height: "46px",
        fontWeight: "500",
        flexDirection: "row",
        justifyContent: "space-between",
        border: "0.5px solid rgba(0, 0, 0, 0.1)",
        position: "sticky",
        top: "0",
        borderRight: "1px solid rgba(0, 0, 0, 0.1)",
    },

    tableheader: {
        fontSize: "14px",
        fontWeight: "500",
        borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        padding: "15px 15px",
        margin: '10px 0px'


    },
    tableHeaderGrid: {
        // borderRight: "1px solid rgba(0, 0, 0, 0.1)",
    },
    tableheaderlocationAddress: {
        fontSize: "14px",
        fontWeight: "500",
        minwidth: "30%",
        maxWidth: "30%",
        width: "30%"
    },
    tableheaderInventory: {
        fontSize: "14px",
        minwidth: "50%", maxWidth: "50",
        fontWeight: "500", width: "35%"

    },
    tableheaderList: {
        fontSize: "14px",
        fontWeight: "500",
        minwidth: "50%",
        maxWidth: "50",
        width: "35%"

    },
    tableheaderOffer: {
        fontSize: "14px",
        fontWeight: "500",
        minwidth: "50%",
        maxWidth: "50",
        width: "35%"

    },
    // table content Styling
    contenttablegrid: {
        paddingTop: "0px !important",
        paddingBottom: "0px !important",
        // borderBottom: "0.5px solid rgba(0, 0, 0, 0.1)",
        borderLeft: "0",
        borderRight: "0",
        paddingLeft: "10px",
        // padding: '10px',
        paddingRight: "0",
        width: "100%",
        display: "flex",
        // height:"70px",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        border: '0.5px solid #EEEEEE',
        minHeight: "50px",
        color: "#000000",

    },
    contentlocationId: {
        paddingLeft: "10px",
        // padding: '10px',
        paddingRight: "0",
        width: "100%",
        // display: "flex",
        // flexDirection: "row",
        justifyContent: "space-between",
        // alignItems: "center",
        // border: "0.5px solid rgba(0, 0, 0, 0.1)",
        minHeight: "40px",
        maxHeight: "50px",
        // minwidth: "50%",
        // maxWidth: "50%",
        borderRight: "1px solid rgba(0, 0, 0, 0.1)",

    },
    contentlocationAddress: {
        fontSize: "14px",
        color: "#000000",
        maxHeight: "50px",
        minHeight: "40px",
        paddingBottom: "15px",
        textOverflow: "ellipsis"

    },
    bodyGrid: {
        // borderRight: '1px solid #EEEEEE',
        // padding: "10px",
        // margin: '10px 0px',
    },
    chip: {
        padding: theme.spacing(0.5),
        borderRadius: '50px',
    },
    divider: {
        // borderRight: "1px solid rgba(0, 0, 0, 0.1)",
        padding: "0.5em",
        height: "30px",
    },
    textInput: {
        position: "sticky",
        top: 0,
        margin: "10px ,30px,10px, 20px",
        // width: "200px",

        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: "var(--primaryColor)",
          },

        '& .MuiFormHelperText-contained': {
            color: '#f44336',
            marginTop: "0px",
            fontSize: "10px",
            marginLeft: "8px"
        },
    },

    box: {
        display: 'flex',
        // justifyContent: 'space-between',
        width: '220px',
        border: '1px solid #d8d8de',
        borderRadius: '50px',
    },
    filterIconCss: {
        margin: "5px",
    },
    tableCellFilter: {
        padding: "5px 16px",
        '& .MuiTableCell-root': {
            padding: "5px 16px"
        }
    }

}))
export default useStyles

