import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Grid, Divider, Button, Card, Typography } from "@material-ui/core"
import MaterialDataGrid from "../../components/MaterialDataGrid"
import {
  renderOnSave,
  renderOnDelete,
  renderOnEdit,
  renderOnDiscard,
  renderSwitchHandler,
  renderItemChange,
  renderSelectionChange,
  renderSetDefaultValue,
  renderHeaderSelectionChange,
  renderUpdateTableFilterData,
  renderSingleSelectionChange,
} from "./renderProps"
import * as Adm from "@adm"
import { useHistory } from "react-router-dom"

function TableLocation(props) {
  const { t } = useTranslation()
  const {
    tableGlobalState,
    pageData,
    detailPageRoute,
    reDirectPath,
    queryParams = "",
    onRowClick,
    DontGoToUrl,
    deleteAnId,
    editVariable,
    isType = "singel",
    InTableRowEdit,
    onSaveHandler,
    additionalActions = () => {},
    ShowCheckBox = true,
    isOnRowClick = true,
    loading,
    hideCheckboxAll = false,
    isLookup = false,
    pageable = true,
  } = props || {}

  const { isWriteAllowed } = props

  const {
    setGridState,
    gridState,
    items,
    setRowData,
    rowData,
  } = tableGlobalState
  const { columnData } = tableGlobalState

  let tttt = true
  let history = useHistory()
  return (
    <Grid item lg={12} md={12} sm={12} xs={12}>
      <Grid item>
        {/* {loading ? <Adm.SkeletonTab width={100} height={100} loading={loading} column={5} row={5} /> : */}
        <MaterialDataGrid
          isGrid={false}
          isInlineEditAllowed={isWriteAllowed}
          additionalActions={additionalActions}
          itemChange={(event) => renderItemChange(event, tableGlobalState)}
          isLookup={isLookup}
          setDefaultValue={(event, props) =>
            renderSetDefaultValue(event, props, tableGlobalState)
          }
          headerSelectionChange={(event) =>
            renderHeaderSelectionChange(event, tableGlobalState)
          }
          selectionChange={(event) =>
            hideCheckboxAll
              ? renderSingleSelectionChange(event, tableGlobalState)
              : renderSelectionChange(event, tableGlobalState)
          }
          onDiscard={(props) =>
            renderOnDiscard(props, tableGlobalState, InTableRowEdit)
          }
          onEdit={(props) =>
            DontGoToUrl
              ? onRowClick()
              : renderOnEdit(
                  props,
                  history,
                  tableGlobalState,
                  reDirectPath,
                  editVariable,
                  isType,
                  InTableRowEdit,
                  queryParams
                )
          }
          onDelete={(props) => {
            deleteAnId(props)
          }}
          // onDelete={(props) => renderOnDelete(props, tableGlobalState)}
          onSave={(props) =>
            InTableRowEdit
              ? onSaveHandler(props, tableGlobalState)
              : renderOnSave(props, tableGlobalState)
          }
          switchHandler={(event, props) =>
            renderSwitchHandler(event, props, tableGlobalState)
          }
          setGridState={setGridState}
          gridState={gridState}
          setRowData={setRowData}
          rowData={rowData}
          columnData={columnData}
          pathProps={detailPageRoute}
          pageData={pageData}
          actionMenu={true}
          number_of_items={items}
          onRowClick={props?.onRowClick}
          menutype={props.menutype}
          historyPopup={props?.historyPopup}
          ShowCheckBox={ShowCheckBox}
          isOnRowClick={isOnRowClick}
          hideCheckboxAll={hideCheckboxAll}
          pageable={pageable}
        />
        {/* } */}
      </Grid>
    </Grid>
  )
}
export { TableLocation }
