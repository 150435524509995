import React from "react"
import { Grid, Box, Tabs, Tab as TabUI, Typography } from "@material-ui/core"
import Styled from "styled-components"

const StyledTabUI = Styled(TabUI)`
  position: relative;
  .cls-tab-ui, .cls-tab-subui {
    display: flex;
    align-items: center;
  }

  .MuiTab-wrapper {
    flex-direction: row;
  }
//   .MuiTabs-fixed > .MuiTabs-indicator {
//     background-color: #f00 !important;
// }
`

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ marginTop: 15 }}>{children}</Box>}
    </div>
  )
}

function Tab(props) {
  const {
    className = "",
    list = [],
    defaultIndex = 0,
    onUpdate = () => "",
    isIconEnable = false,
    renderChildrenTabHeader = () => "",
  } = props || {}

  const [tabList, setTabList] = React.useState(list)
  const [value, setValue] = React.useState(defaultIndex)

  React.useEffect(() => {
    setTabList(list)
  }, [list])

  React.useEffect(() => {
    setValue(defaultIndex)
  }, [defaultIndex])

  const handleChange = (event, newValue) => {
    // setValue(newValue)
    onUpdate({ index: newValue })
  }

  return (
    <Box sx={{ width: "100%" }} className={className}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          className="cls-ui-tab-control"
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "var(--primaryColor)"
            }
          }}
        >
          {tabList.length > 0 &&
            tabList.map((itm, i) => {
              const {
                name = "",
                icon: DynamicIcon = () => "",
                renderTabAddtionalChild = () => "",
                isHide = false,
              } = itm

              return (
                <StyledTabUI key={i}
                  style={{ display: isHide ? "none" : "flex" }}
                  className={`cls-styled-tab-ui-button ${value === i ? "active" : "inactive"
                    }`}
                  label={
                    <div className="cls-tab-ui">
                      <div className="cls-tab-subui">
                        {isIconEnable && <DynamicIcon />}
                        <div className="cls-tab-ui-label">{name}</div>
                      </div>
                      {renderTabAddtionalChild()}
                    </div>
                  }
                />
              )
            })}
          {renderChildrenTabHeader()}
        </Tabs>
      </Box>
      {tabList.length > 0 &&
        tabList.map((itm, index) => {
          const {
            name = "",
            component: DynmicComponent = () => "",
            isHide = false,
          } = itm
          if (isHide) {
            return <></>
          }
          return (
            <TabPanel value={value} index={index} key={index}>
              <DynmicComponent />
            </TabPanel>
          )
        })}
    </Box>
  )
}

export { Tab }
export default Tab
