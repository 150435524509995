import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

// const types = REDUX_CONSTANTS

export const updateCountry = (country) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.UPDATE_COUNTRY,
    country: country,
  })
}
