import React from "react"
import { Grid, useMediaQuery } from "@material-ui/core"
import * as Deps from "./deps"
import { defaultList } from "./fixture"
import { FacetCell } from "./FacetCell"
import { CreateAttribute } from "./CreateAttribute"
import * as Utils from "@utils"
import { makeStyles, useTheme } from "@material-ui/core/styles"


function FacetAttribute(props) {
  const {
    list = defaultList,
    onAdd = () => Deps.onAdd(),
    updateValue = () => Deps.updateValue(),
    onDelete = () => Deps.onDelete(),
    updateKey = () => Deps.updateKey(),
    placeholder = { key: "Enter Key", Value: "Enter Value" },
    isDetailView = false,
    onBlur = () => { },
    style = { width: '50%', padding: '5px 0px' }

  } = props || {}
  const [attributeList, setAttributeList] = React.useState({ data: list })
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.between('lg', 'xl'))

  React.useEffect(() => {
    setAttributeList({ data: list })
  }, [list])

  const stateProps = { attributeList, setAttributeList }
  const isEmptyfilterset = attributeList.data.filter(
    (item) => {
      return !Utils.JSUtils.isEmpty(item.error) || Utils.JSUtils.isEmpty(item.key) || Utils.JSUtils.isEmpty(item.value)
    }

  )
  const renderAdd = () => (
    !isDetailView &&
    <div style={{ padding: '20px', width: '50%' }}>
      <CreateAttribute
        disabled={!Utils.JSUtils.isEmpty(isEmptyfilterset)}
        onClick={() => onAdd(stateProps)}
      />
    </div>
  )
  return (
    <>
      {Utils.JSUtils.isEmpty(attributeList.data) === false &&
        attributeList.data.map((item, idx) => {
          const { key, value, isDisableClose, error } = item || {}
          return idx % 2 === 0 ? (
            <>
              <Grid
                key={idx}
                container
                direction="row"
                data-index={idx}
                // justify="space-between"
                style={{ padding: "20px", justifyContent: "space-between", borderBottom: "1px solid #EEEE", width: isDesktop ? "50%" : "50%", display: "flex", flexDirection: "column" }}
              >
                <FacetCell
                  key={idx}
                  idx={idx}
                  keyText={key}
                  errorText={error}
                  keyPlaceholder={placeholder.key}
                  valuePlaceholder={placeholder.value}
                  valueText={value === "" ? isDetailView ? "-" : "" : value}
                  removeRecord={(idx) => onDelete(idx, stateProps)}
                  keyUpdate={(value, idx) =>
                    updateKey(value, idx, stateProps)
                  }
                  valueUpdate={(value, idx) =>
                    updateValue(value, idx, stateProps)
                  }
                  isDetailView={isDetailView}
                  onBlur={onBlur}
                />
              </Grid>
              {idx + 1 === attributeList.data.length ? (
                renderAdd()
              ) : (
                <Grid key={idx}
                  direction="row"
                  data-index={idx}
                  // justify="space-between"
                  style={{ padding: "20px", justifyContent: "space-between", borderBottom: "1px solid #EEEE", width: isDesktop ? "50%" : "50%", borderRight: "1px solid #EEEE", }}
                // style={{ width: '50%', padding: '5px 0px' }}
                >
                  <FacetCell
                    key={idx}
                    idx={idx + 1}
                    keyPlaceholder={placeholder.key}
                    valuePlaceholder={placeholder.value}
                    keyText={attributeList.data[idx + 1].key}
                    valueText={attributeList.data[idx + 1].value === "" ? isDetailView ? "-" : "" : value}
                    errorText={attributeList.data[idx + 1].error}
                    removeRecord={(idx) => onDelete(idx, stateProps)}
                    keyUpdate={(value, idx) =>
                      updateKey(value, idx, stateProps)
                    }
                    valueUpdate={(value, idx) =>
                      updateValue(value, idx, stateProps)
                    }
                    isDetailView={isDetailView}
                    onBlur={onBlur}
                  />
                </Grid>
              )}
            </>

          ) : (
            <React.Fragment />
          )
        })}
      {attributeList.data.length % 2 === 0 && isDetailView === false ? renderAdd() : <React.Fragment />}
    </>
  )
}

export { FacetAttribute }
export default FacetAttribute
