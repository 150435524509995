export const API_END_POINTS = {
  /**
   * @ Auth Service
   */
  signIn: "/api/auth/sign-in",
  signUp: "/api/auth/sign-up",
  reset: "/api/auth/reset-password",
  addCustomer: "/api/auth/signup",

  /**
   * @ Tenant Auth service
   */
  generateOtp: "/tenantauth/api/tenantauth/generateOTP",
  verifyOtp: "/tenantauth/api/tenantauth/verifyOTP",
  updatePassword: "/tenantauth/api/tenantauth/updatePassword",
  verifyPassword: "/tenantauth/api/tenantauth/verifyPassword",

  /**
   * @ User Profile
   */
  getAllCustomers: "/api/userprofile/users/allCustomers",
  getCustomersPaginated: "/api/userprofile/users/userpaginate",
  addNewAddress: "/api/userprofile/users/newaddress",
  editAddress: "/api/userprofile/users/editaddress",

  /**
   * @ DigitShelf
   */
  getAllDigitshelf: "/master/api/shelf/getShelfTypes",
  addDigitshelf: "/master/api/shelf/create",
  updateDigitshelf: "/master/api/shelf/update",
  deleteDigitshelf: "/master/api/shelf/delete",
  deleteManyDigitshelf: "/master/api/shelf/deletemany",
  getdigitShelfDetails: '/smart/api/smartshelf/getdigitshelfdetails',

  /**
   * @ Gender
   */
  getAllGender: "/master/api/gender/getGender",
  getGender: "/master/api/gender",
  addGender: "/master/api/gender/create",
  updateGender: "/master/api/gender/update",
  deleteGender: "/master/api/gender/delete",
  deleteManyGender: "/master/api/gender/deletemany",

  /**
   * @ UOM
   */
  getAllMeasure: "/master/api/measurement/getuom",
  addMeasure: "/master/api/measurement/create",
  updateMeasure: "/master/api/measurement/update",
  deleteMeasure: "/api/measurement/delete",
  deleteManyMeasure: "/master/api/measurement/deletemany",

  /**
   * @ Barocde
   */
  getAllBarcodeTypes: "/master/api/barcode/getbarcodes",
  addBarcodeTypes: "/master/api/barcode/create",
  updateBarcodeTypes: "/master/api/barcode/update",
  deleteBarcodeTypes: "/api/barcode/delete",
  deleteManyBarcodeTypes: "/master/api/barcode/deletemany",

  /**
   * @ Tax code
   */
  getTaxCodes: "/master/api/taxs/getTaxCodes",
  getEntityUseCodes: "/master/api/taxs/getEntityUseCodes",
  getTaxTypes: "/master/api/taxs/getTaxTypes",
  getTaxForTaxProvider: "/master/api/taxs/getTaxForTaxProvider",

  /**
   * @ Location service
   */
  getlocationtypes: "/master/api/location/getlocationtypes",
  getlocationservices: "/master/api/location/getlocationservices",
  getTimezoneByCountry: "/master/api/timezone/getTimezoneByCountry",

  /**
   * @ Country
   */
  getAllCountries: "/master/api/countries/getCountries",
  addCountry: "/api/countries/create",
  updateCountry: "/api/countries/update",
  deleteCountry: "/api/countries/delete",
  deleteManyCountry: "/api/countries/deletemany",

  /**
   * @ Currency
   */
  getAllCurrencies: "/api/currency/getcurrency",
  addCurrency: "/api/currency/create",
  updateCurrency: "/api/currency/update",
  deleteCurrency: "/api/currency/delete",
  deleteManyCurrency: "/api/currency/deletemany",

  /**
   * @ Province
   */
  getAllProvinces: "/api/province",
  addProvinces: "/api/province/create",
  updateProvinces: "/api/province/update",
  deleteProvinces: "/api/province/delete",
  deleteManyProvinces: "/api/province/deletemany",

  /**
   * @ State
   */
  getAllState: "/master/api/state/states",
  addState: "/api/state/create",
  updateState: "/api/state/update",
  deleteState: "/api/state/delete",
  deleteManyState: "/api/state/deletemany",

  /**
   * @ Language
   */
  getAllLanguage: "/api/language/fetchLanguages",
  addLanguage: "/api/language/create",
  updateLanguage: "/api/language/update",
  deleteLanguage: "/api/language/delete",
  deleteManyLanguage: "/api/language/deletemany",

  /**
   * @ Package Types
   */
  getpackagingtypes: "/master/api/packaging/getpackagingtypes",

  /**
   * @ Princes
   */
  getAllPrices: "/api/prices",
  getAnPrice: "/api/prices/price",
  updateAnPrice: "/api/prices/update",
  createPrice: "/api/prices/create",
  deletePrice: "/api/prices/delete",
  priceLocationElasticSearch: "/api/searchprice/locations?searchTerm=",

  /**
   * @ Stores service
   */
  getAllCities: "/store/api/stores/getAllCities",
  getAllStates: "/store/api/stores/getAllRegions",
  addLocation: "/store/api/stores/create",
  getAllStores: "/store/api/stores/getlocations",
  getLocation: "/store/api/stores/location",
  getAnStore: "/store/api/stores/store",
  getLocationGroup: "/store/api/stores/locationgroup",
  deleteAnStore: "/store/api/stores/delete",
  deleteManyStore: "/store/api/stores/deletemany",
  getGroupById: "/store/api/stores/getGroupById",
  updateGroup: "/store/api/stores/updateGroup",
  createLocationGroup: "/store/api/stores/createlocationgroup",
  deleteStoreGroup: "/store/api/stores/deleteGroupStore",
  checkLocationExist: "/store/api/stores/checkLocationExist",
  updateLocation: "/store/api/stores/updateLocation",
  updateLocationGroup: "/store/api/stores/updatelocationgroup",
  getlocationgroups: "/store/store/api/stores/getlocationgroups",
  getLocationByGroupId: "/store/api/stores/getLocationByGroupId",
  getDashBoardStore: "/store/api/stores/getStoresforDashboard",
  getLocationSecretFromKV: "/store/api/stores/getLocationSecretFromKV",
  updateLocationSecretInKV: "/store/api/stores/updateLocationSecretInKV",
  validateAddress: "/address/api/address/validateAddress",

  /**
   * @ Product service
   */
  getAllBrands: "/product/api/products/getAllBrands",
  getAllManufacturers: "/product/api/products/getAllManufacturers",
  createProduct: "/product/api/products/create",
  UploadProductImage: "/product/api/products/upload",
  getAllProducts: "/product/api/products/geteveryproducts",
  getProductPaginated: "/product/api/products/productpaginate",
  getproductbygroupId: "/product/api/products/getproductbygroupId",
  getProductGroup: "/product/api/products/productgroup",
  updateproductgroup: "/product/api/products/updateproductgroup",
  createproductgroup: "/product/api/products/createproductgroup",
  deleteManyAttributes: "/product/api/products/deleteProductAttributes",
  bulkUploadProducts: "/product/api/products/bulkUploadProducts",
  getProductMappingDetails: "/product/api/products/getProductMapping",

  exportAllProductMappings: "/product/api/products/exportAllProductMappings",
  bulkUploadLocationProductMappings:
    "/product/api/products/bulkUploadLocationProductMappings",
  updateProductMappingDetails: "/product/api/products/updateProductMapping",
  createProductMappingDetails: "/product/api/products/createProductMapping",
  deleteProductMappings: "/product/api/products/deleteProductMappings",
  getAllProductItemIdList: "/product/api/products/getAllProductItemIdList",
  getunmappedProductsOnLocation:
    "/product/api/products/getunmappedProductsOnLocation",
  getUnMappedProductsByStorageUnitId:
    "/product/api/products/getUnMappedProductsByStorageUnitId",
  getProductMappingCount: "/product/api/products/getProductMappingCount",
  getProductMappingSnapShot: "/product/api/products/getProductMappingSnapShot",

  /**
   * @ Location storage
   */
  createStorageUnit: "/product/api/storages/createstorageunit",
  getStorageUnits: "/product/api/storages/getStorageUnits",
  getUnMappedStorageUnits: "/product/api/storages/getUnMappedStorageUnits",
  getStorageUnitById: "/product/api/storages/getStorageUnitById",
  updateStorageUnit: "/product/api/storages/updatestorageunit",
  createStorageUnitMapping: "/product/api/storages/createStorageUnitMapping",
  getStorageUnitMappings: "/product/api/storages/getStorageUnitMappings",
  updateStorageUnitMapping: "/product/api/storages/updateStorageUnitMapping",
  createStorageUnitMappingWithAdjustment:
    "/product/api/storages/createStorageUnitMappingWithAdjustment",
  deleteStorageUnitMappings: "/product/api/storages/deleteStorageUnitMappings",
  deleteStorageUnits: "/product/api/storages/deleteStorageUnits",
  bulkUploadStorageProductMappings:
    "/product/api/storages/bulkUploadStorageProductMappings",
  updateCompanyonStorageUnitMapping: "/product/api/storages/updateCompanyonStorageUnitMapping",
  getStorageUnitProductMappingCount: "/product/api/storages/getStorageUnitProductMappingCount",

  /**
   * @ Customer
   */
  getAllCustomerList: "/api/customer/getcustomers",
  customerInfoValidationCheck: "/api/customer/customerInfoValidation",
  getSocialProfile: "/api/customer/getsocialprofiles",
  getEthnicity: "/api/customer/getethnicity",
  getcustomeracquisitionsource: "/api/customer/getcustomeracquisitionsource",
  deleteCustomers: "/api/customer/deletecustomers",
  createCustomer: "/api/customer/createcustomer",
  getAnCustomer: "/api/customer/getcustomerbyid",
  updateAnCustomer: "/api/customer/updatecustomer",

  /**
   * @ Tansaction
   */
  getTransaction: "/transaction/api/transaction/searchTransactions",
  getInvoiceTransactions: "/transaction/api/transaction/invoiceTransactions",
  getSummaryTransactions: "/transaction/api/transaction/storeInvoiceSummary",

  /**
   * @ Inventory
   */
  getAllInventory: "/api/inventory/getinventories",
  deleteInventories: "/api/inventory/deletemany",
  getInventoryPaginated: "/api/inventory/inventorypaginate",
  createInventory: "/api/inventory/create",
  updateInventory: "/api/inventory/update",
  getParticularInventory: "/api/inventory/getInventoryById",

  getProductAdjustmentHistories:
    "/product/api/products/getProductAdjustmentHistories",
  getStorageAdjustmentHistories:
    "/product/api/storages/getStorageAdjustmentHistories",
  getProductMappingDetail: "/product/api/products/getProductMappingDetail",
  getStorageMappingDetail: "/product/api/storages/getStorageMappingDetail",
  getProductAdjustments: "/product/api/products/getProductAdjustments",
  getStorageAdjustments: "/product/api/storages/getStorageAdjustments",
  createProductAdjustment: "/product/api/products/createProductAdjustment",
  createStorageAdjustment: "/product/api/storages/createStorageAdjustment",
  storageAdjustmentReason: "/master/api/adjustment/getAdjustmentTypes/storage",
  storeAdjustmentReason: "/master/api/adjustment/getAdjustmentTypes/store",

  /**
   * @ Tenant
   */
  getTenantIdByName: "/tenant/api/tenant/getTenantIdByName",
  createBusinessUser: "/tenant/api/tenant/createBusinessUser",
  updateBusinessUser: "/tenant/api/tenant/updateBusinessUser",
  getBusinessUsers: "/tenant/api/tenant/getBusinessUsers",
  getAdminUsers: "/tenant/api/tenant/getAdminUsers",
  createTenantUser: "/tenant/api/tenant/createTenantwithAdmin",
  getTenantDetailWithAdmin: "/tenant/api/tenant/getTenantDetailWithAdmin",
  getBusinessUserbyId: "/tenant/api/tenant/getBusinessUserbyId",
  updateTenantDetail: "/tenant/api/tenant/updateTenantDetail",
  getTenantsUsers: "/tenant/api/tenant/getTenants",
  updateTenantAddress: "/tenant/api/tenant/updateTenantAddress",
  getRolePermissionDetail: "/tenant/api/roles/getRolePermissionDetail",
  generatePhoneNumberOTP: "/tenant/api/tenant/generatePhoneNumberOTP",
  verifyPhoneNumberOTP: "/tenant/api/tenant/verifyPhoneNumberOTP",
  getTenantDashBoard: "/tenant/api/tenant/getTenantforDashboard",
  getThemesList: "/tenant/api/tenant/getThemes",
  createTheme: "/tenant/api/tenant/createTheme",
  getLogoDetail: "/tenant/api/tenant/getLogoDetail",
  getUserDetail: "/tenant/api/tenant/getUserDetail",

  /**
   * @ Sun SAAS
   */
  getAdminUserbyId: "/tenant/api/tenant/getAdminUserbyId",
  updateAdminUser: "/tenant/api/tenant/updateAdminUser",
  createAdminUser: "/tenant/api/tenant/createAdminUser",

  /**
   * @ RealM
   */
  createRealmwithAdmin: "/tenant/api/tenant/createRealmwithAdmin",
  updateRealmDetail: "/tenant/api/tenant/updateRealmDetail",
  getTenantRealms: "/tenant/api/tenant/getTenantRealms",
  getRealmDetailWithAdmin: "/tenant/api/tenant/getRealmDetailWithAdmin",

  /**
   * @ Tax Serivice
   */
  getTaxProviders: "/tax/api/tax/provider/getTaxProviders",
  saveTaxProvider: "/tax/api/tax/provider/saveTaxProvider",
  getTaxProviderById: "/tax/api/tax/provider/getTaxProviderById",
  testConnection: "/tax/api/tax/ping",

  /**
   * @ Roles Serice
   */
  updateRolePermissionList: "/tenant/api/roles/updateRolePermission",
  updateRoleAttributesByTenantId:
    "/tenant/api/roles/updateRoleAttributesByTenantId",
  getRoles: "/tenant/api/roles/getRoles",
  getRoleAttributesByTenantId: "/tenant/api/roles/getRoleAttributesByTenantId",
  getSassAdminRoles: "/tenant/api/roles/getRolesDetailByRolename",
  getTenantRoles: "/tenant/api/roles/getTenantRoles",
  createTenantRole: "/tenant/api/roles/createTenantRole",
  updateTenantRole: "/tenant/api/roles/updateTenantRole",
  deleteTenantRole: "/tenant/api/roles/deleteTenantRole",
  getTenantRoleDetailByRoleId: "/tenant/api/roles/getTenantRoleDetailByRoleId",
  getDefaultRoleDetail: "/tenant/api/roles/getDefaultRoleDetail",
  updateTenantRoleAttributes: "/tenant/api/roles/updateTenantRoleAttributes",
  updateDefaultRoleAttributes: "/tenant/api/roles/updateDefaultRoleAttributes",
  getTenantUserRoles: "/tenant/api/roles/getTenantUserRoles",
  getAdminUserRoles: "/tenant/api/roles/getAdminUserRoles",

  getAdminRoles: "/tenant/api/roles/getAdminRoles",
  createAdminRole: "/tenant/api/roles/createAdminRole",
  updateAdminRole: "/tenant/api/roles/updateAdminRole",
  deleteAdminRole: "/tenant/api/roles/deleteAdminRole",
  getAdminRoleDetailByRoleId: "/tenant/api/roles/getAdminRoleDetailByRoleId",
  updateAdminRoleAttributes: "/tenant/api/roles/updateAdminRoleAttributes",

  /**
   * @ SDC Module
   */
  getTurnstiles: "/sdc/api/sdc/getturnstiledetails",
  getCameras: "/sdc/api/sdc/getcameradetails",
  getEdgeDeviceHealth: "/sdc/api/sdc/getedgehealth",
  listplanogram: "/sdc/api/sdc/listplanogram",
  // listplanogramtest: "/sdc/api/sdc/listplanogram",
  getProductCamera: "/sdc/api/sdc/getproductcamera",
  getGondolas: "/gondola/api/gondola/getgondoladetails",
  getGondolaById: "/gondola/api/gondola/getgondola?",
  getFSCamera: "/api/kc/getposdetails",
  getFSEdgeDevice: "/api/kc/getedgedevicedetails",
  listPlanogramFS: "/api/kc/listplanogram",
  getProductMap: "/smart/api/smartshelf/getproduct",
  getRetailersList: "",
  searchEmployeeActivities:
    "/transaction/api/employee/searchEmployeeActivities",
  getTenantLogmodules: "/auditlog/api/auditlog/getTenantLogmodules",
  getSassLogModules: "/auditlog/api/auditlog/getSassLogModules",
  getTenantAuditLogs: "/auditlog/api/auditlog/getTenantAuditLogs",
  getSassAuditLogs: "/auditlog/api/auditlog/getSassAuditLogs",
  getTenantAuditLogById: "/auditlog/api/auditlog/getTenantAuditLogById",
  getSassAuditLogById: "/auditlog/api/auditlog/getSassAuditLogById",
  getApplicationLogs: "/logquery/api/logquerier/getLogData",

  /**
   * @ Alerts
   */
  createAlertConfig: "/alert/api/alerts/createAlertConfig",
  getalertconfigurationlist: "/alert/api/alerts/getAlertConfigurationList",
  getTenantAlertConfiglist: "/alert/api/alerts/getTenantAlertConfigList",
  updateAlertConfig: "/alert/api/alerts/updateAlertConfig",
  deleteAlertConfig: "/alert/api/alerts/deleteAlertConfig",
  updateAlertMonitor: "/alert/api/alerts/updateAlertMonitor",

  /**
   * @ Payment Configuration
   */
  createPaymentConfig: "/payment/api/paymentConfig/createPaymentConfig",
  updatePaymentConfigStatus:
    "/payment/api/paymentConfig/updatePaymentConfigStatus",
  getPaymentConfig: "/payment/api/paymentConfig/getPaymentConfig",
  deletePaymentConfig: "/payment/api/paymentConfig/deletePaymentConfig",
  stripeTestConnection: "/payment/api/paymentConfig/stripeTestConnection",
  getPaymentConfigByPaymentType:
    "/payment/api/paymentConfig/getPaymentConfigByPaymentType",

  /**
   * @ Alert setup
   */
  getNewAlertList: "/alert/setup/alert/all",
  getAlertSetupLevelAll: "/alert/setup/level/all",
  getAlertSetupEntityFilterByIds: "/alert/setup/entity/filter/by/ids",
  getAlertSetupSeverityAll: "/alert/setup/severity/all",
  createSetupAlert: "/alert/setup/alert/create",
  updateSetupAlert: "/alert/setup/alert/update",
  unconfiguredTenantAlertList: "/alert/setup/alert/unconfigured/by/tenant",

  /**
   * @ Configuration setup
   */
  configurationSave: "/alert/configuration/save/multiple",
  configurationTenantList: "/alert/configuration/all",
  configurationRoleSubscription: "/alert/configuration/role/by/subscription",
  configurationSubscriptionUpdate: "/alert/configuration/update",

  /**
   * @ Payment Type Service
   */
  getPaymentTypes: "/payment/api/paymentConfig/getPaymentTypes",


  /**
   * @ Shelf Type Services
   */
  getShelfTypes: "/master/api/shelf/getShelfTypes",

  /**
   * @ monitoring Services
   */
  getAlertMonitorlist: "/alert/monitoring/all",
  monitoringUpdate: "/alert/monitoring/update",
  dashboardAlertRealm: "/alert/monitoring/count/by/realm",
  dashboardAlertTenant: "/alert/monitoring/count/by/tenant",
  dashboardAlertStore: "/alert/monitoring/count/by/location",
  dashboardAlertAll: "/alert/monitoring/count",

  /**
   * @ Vendor
   */
  vendorCreate: "/vendor/api/vendor/create",
  vendorLogo: "/vendor/api/vendor/logo/upload",
  vendorUpdate: "/vendor/api/vendor/update",
  getVendorDetail: "/vendor/api/vendor/by/id",
  vendorList: "/vendor/api/vendor/all",
  getVendorList: "/vendor/api/vendor/all/names/by/ids?is_active=true",
  deleteVendor: "/vendor/api/vendor/delete/many",

  /**
     * @ cycle Count
  */
  getProductCycleCounts: "/product/api/cyclecount/getProductCycleCounts",
  createProductCycleCount: "/product/api/cyclecount/createProductCycleCount",
  cycleCountExcelTemplate: "/import/api/export/sample/download",

  /**
       * @ Iventory Report
  */
  generateInventoryReport: "/report/api/report/inventory/file",
  generateInventoryLogReport: "/report/api/report/inventory/log/file",
  generateInventoryStoreListGrid: "/report/api/report/inventory/store/all",
  generateInventoryStorageListGrid: "/report/api/report/inventory/storage/all"

}
