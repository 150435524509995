import * as React from "react"
import { Editor, EditorTools } from "@progress/kendo-react-editor"
// import content from "./content";
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  // Undo,
  // Redo,
  FontSize,
  FontName,
  // FormatBlock,
  Link,
  Unlink,
  // InsertImage,
  ViewHtml,
  // InsertTable,
  // AddRowBefore,
  // AddRowAfter,
  // AddColumnBefore,
  // AddColumnAfter,
  // DeleteRow,
  // DeleteColumn,
  // DeleteTable,
  // MergeCells,
  // SplitCell,
} = EditorTools

function RichTextWYSIWYG(props) {
  const {
    content = "",
    onUpdate = () => { },
    style={},
  } = props || {}
  
  return (
    <Editor
      tools={[
        // [Undo, Redo],
        [Bold, Italic, Underline, Strikethrough],
        // [Subscript, Superscript],
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        // FontSize,
        // FontName,
        // FormatBlock,
        // [Link, Unlink,]
          [ ViewHtml],
        // [InsertTable],
        // [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
        // [DeleteRow, DeleteColumn, DeleteTable],
        // [MergeCells, SplitCell],
      ]}
      defaultContent={content}
      onChange={onUpdate}
      style={style}
    />
  )
}

export { RichTextWYSIWYG }
export default RichTextWYSIWYG
