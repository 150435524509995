import React from "react"
import styled from "styled-components/macro"
import { Link, useHistory } from "react-router-dom"

import Helmet from "react-helmet"

import { Button as MuiButton, Typography } from "@material-ui/core"
import { spacing } from "@material-ui/system"
import UserService from "../../../src/services/UserService"

const Button = styled(MuiButton)(spacing)

// const Wrapper = styled.div`
//   padding: ${(props) => props.theme.spacing(6)}px;
//   text-align: center;
//   background: transparent;

//   ${(props) => props.theme.breakpoints.up("md")} {
//     padding: ${(props) => props.theme.spacing(10)}px;
//   }
// `

const Wrapper = styled.div`
  padding: 6px;
  text-align: center;
  background: transparent;  
`

function UnAuthorizedUser() {
    const history = useHistory()
    const returnWeb = () => {
        UserService.doLogout()
        // let getRoleName = localStorage.getItem("role")
        // let getTenantId = localStorage.getItem("tenantId")
        // getRoleName = getRoleName.toLocaleLowerCase()
        // const isSassRole = getRoleName.includes("saas")

        // let dynamicRoutes = isSassRole
        //     ? "/dashboard?page=home"
        //     : `/dashboard?page=autonomous&retailer=${getTenantId}&subpage=0`
        // window?.location?.replace("/dashboard?page=home")
    }

    return (
        <>
            <Wrapper>
                <div style={{ position: "relative" }}>
                    <img src="/static/img/pageNotFound1.svg" alt="pageNotFound Page" />
                    <div style={{ position: "absolute", top: "88%", left: "52%" }}>
                        <Button
                            //component={Link}
                            to="/"
                            variant="contained"
                            style={{
                                background: "#8080D2",
                                width: "150px",
                                borderRadius: "20px",
                                color: "#FFF",
                            }}
                            onClick={() => returnWeb()}
                        >
                            Log out
                        </Button>
                    </div>
                </div>
            </Wrapper>

        </>
        // <Wrapper>
        //     <Helmet title="403 Forbidden" />
        //     <Typography component="h1" variant="h1" align="center" gutterBottom>
        //         403
        //     </Typography>
        //     <Typography component="h2" variant="h5" align="center" gutterBottom>
        //         Access Denied / Forbidden
        //     </Typography>
        //     <Typography component="h2" variant="body1" align="center" gutterBottom>
        //         You don't have permission to access this page
        //         {/* Contact an administrator to get permissions or go to the home page  */}
        //     </Typography>

        //     <Button
        //         component={Link}
        //         to="/"
        //         variant="contained"
        //         color="secondary"
        //         mt={2}
        //         onClick={() => returnWeb()}
        //     >
        //         Return to website
        //     </Button>
        // </Wrapper>
    )
}

export default UnAuthorizedUser
