import { ReduxConstants } from '@constants';
const { LOC_ACTION_TYPES, LOC_SAGA_ACTIONS: SAGAS } = ReduxConstants.REDUX_CONSTANTS;

export const fetchAllHierarchy = () => ({
    type: SAGAS.FETCH_LOC_HIERARCHY
})

// export const deleteHierarchy = (categoryID) => ({
//     type: SAGAS.DELETE_HIERARCHY,
//     categoryID
// })

// export const addHierarchy = (formData) => ({
//     type: SAGAS.ADD_HIERARCHY,
//     formData
// })