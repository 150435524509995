import React, { useState } from "react"
import {
  List,
  ListItem,
  IconButton,
  Typography,
  Grid,
} from "@material-ui/core"
import { CloseIcon, Edit, Slash } from "@iconsGallery"
import _ from "lodash"
import { FormfacetUI } from "./form"
import { formInput } from "./fixture"
import { useFacetUIContentStyles } from "./styles"
import { useFacetUIViewState } from "./state"
import * as Utils from "@utils"
import * as Features from "@features"
import styled from "styled-components"
import * as Adm from "@adm"

const ListWrapper = styled(List)`
  &.list-split {
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
  }
`
const ListItemWrapper = styled(ListItem)`
  &.list-split-sub {
    width: 50%;
  }
  &.makeStyles-listItem-34 {
    height: 50px;
    padding: 20px;
    align-items: center;
    border-bottom: 1px solid #e5e5e5 !important;
    padding-left: 0px;
  }
`
const ListItemHeaderWrapper = styled(ListItem)`
  &.list-split-sub {
    width: 50%;
  }
  &.makeStyles-listItem-34 {
    height: 50px;
    padding: 20px;
    align-items: center;
    border-bottom: 0em solid rgba(0, 0, 0, 0.1);
    padding-left: 0px;
  }
`

function View(props) {
  const {
    listColumnSplit = false,
    edit: facetEdit = false,
    facetData = [],
    facetSetData = () => "",
    keyPath = 'id',
    valuePath = "value",
    isEditMode = true,
    setAnyChange = () => { },
  } = props || {}
  const classes = useFacetUIContentStyles()
  const {
    edit,
    setEdit,
    collapseOpen,
    setCollapseOpen,
    data,
    setData,
    expanded,
    setExpanded,
  } = useFacetUIViewState({ defaultData: facetData })

  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")

  React.useEffect(() => {
    setData(facetData)
  }, [facetData])
  React.useEffect(() => { }, [data])

  const doDataUpdate = (item) => {
    setData([...item])
    facetSetData([...item])

  }

  const deleteData = (id, keypath) => {
    setAlert(false)
    let newItem = data.filter((_item) => {
      return _item[keypath] !== id
    })
    setData(newItem)
    facetSetData(newItem)
    doDataUpdate(newItem)
    setAnyChange(true)
  }

  const formSubmit = (values, { resetForm }) => {
    setAlert(false)
    const adapter = {
      [keyPath]: values.id,
      [valuePath]: values.value,
    }
    const DataKit = [adapter, ...data]
    const isDuplicate = Utils.JSUtils.isDuplicateObject(DataKit, keyPath)
    if (isDuplicate) {
      setAlertType("error")
      setAlertMessage("Duplicate Attribute-Value Pairs")
      setAlert(true)
      // setOpenConfirmationDialog(true)
    } else {
      doDataUpdate(DataKit)
      resetForm()
      setAnyChange(true)
    }
  }

  const formStateProps = {
    data,
    keyPath: "id",
    valuePath: 'value',
    setData,
    onSubmit: formSubmit,
    ...formInput,
  }

  const ListItemKit = ({ list, valuePath = 'value', keyPath = 'id' }) => {
    const classes = useFacetUIContentStyles()
    function isOdd(num) {
      return num % 2
    }

    function isEven(num) {
      if (num % 2 === 0) {
        return 1
      } else {
        return 0
      }
    }
    return (
      <Grid container item xs={12} sm={12} md={12} lg={12} direction="row">
        <div style={{ flex: 1, padding: "0px", paddingTop: "0px", width: "100%" }}>
          <ListWrapper className={`cls-listwrapper-kit ${listColumnSplit === true ? "list-split" : ""} ${classes.listWrapper}`}
          // style={{
          //   paddingTop: "0px",
          //   paddingBottom: "0px",
          //   paddingLeft: "0px",
          //   width: "100%",
          //   margin: "auto",
          //   // border: "1px solid #e5e5e5",
          //   height: "auto",
          //   borderTop: "0",
          //   minHeight: "auto",
          //   maxHeight: "400px",
          //   overflowY: "scroll",
          // }}
          >
            {listColumnSplit === false && !Utils.JSUtils.isEmpty(list) && (
              <Grid container item xs={12} sm={12} md={12} lg={12} direction="row" style={{ padding: "15px 0px", width: "100%", background: "var(--gray)" }}>
                <Grid item sm={6} xs={6} md={6} lg={6}>
                  <Typography
                    style={{
                      paddingLeft: "16px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {"Name"}
                  </Typography>
                </Grid>
                <Grid item sm={6} xs={6} md={6} lg={6}>
                  <Typography
                    style={{
                      paddingLeft: "16px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {"Value"}
                  </Typography>
                </Grid>
              </Grid>
              // <ListItemHeaderWrapper
              //   style={{
              //     paddingTop: "0px !important",
              //     paddingBottom: "0px !important",
              //     border: "1px solid rgba(0, 0, 0, 0.1)",
              //     borderBottom: "0",
              //     borderLeft: "0",
              //     borderRight: "0",
              //     paddingLeft: "0",
              //     paddingRight: "0",
              //   }}
              //   className={`${classes.listItem} ${listColumnSplit === true ? "list-split-sub" : ""
              //     } cls-listitemwrapper`}
              // >
              //   <ListItem
              //     style={{
              //       padding: "0",
              //       width: "100%",
              //       background: "#f1f1f1",
              //     }}
              //   >
              //     <Grid item lg={5}
              //       style={{
              //         // borderRight: "1px solid rgba(0, 0, 0, 0.1)",
              //         height: "50px",
              //         minWidth: "100%",
              //         alignItems: "center",
              //         display: "flex",
              //       }}
              //     >
              //       <Typography
              //         style={{
              //           paddingLeft: "16px",
              //           fontSize: "14px",
              //           fontWeight: "bold",
              //         }}
              //       >
              //         {"Name"}
              //       </Typography>
              //     </Grid>
              //   </ListItem>
              //   <ListItem
              //     style={{
              //       padding: "0",
              //       background: "#f1f1f1",
              //     }}
              //   >
              //     <Grid
              //       item
              //       lg={2}
              //       style={{
              //         height: "50px",
              //         minWidth: "330px",
              //         alignItems: "center",
              //         display: "flex",
              //       }}
              //     >
              //       <Typography
              //         style={{
              //           paddingLeft: "16px",
              //           fontSize: "14px",
              //           fontWeight: "bold",
              //         }}
              //       >
              //         {"Value"}
              //       </Typography>
              //     </Grid>
              //   </ListItem>
              // </ListItemHeaderWrapper>
            )}

            {!Utils.JSUtils.isEmpty(list) ? (
              list.map((header, index) => (
                <ListItemWrapper
                  key={`ListKeyKit-${index}`}
                  className={`${classes.listItem} ${listColumnSplit === true ? "list-split-sub" : ""
                    } cls-listitemwrapper`}
                  listColumnSplit={listColumnSplit}
                  style={{
                    paddingTop: "0px !important",
                    paddingBottom: "0px !important",
                    paddingRight: '0px',
                    border: "1px solid rgba(0, 0, 0, 0.1)",
                    borderBottom:
                      listColumnSplit && index === list.length - 2
                        ? isOdd(index) + " solid rgba(0, 0, 0, 0.1)"
                        : "0px",
                    borderTop: "1px solid rgba(0, 0, 0, 0.1)",
                    borderLeft: "0px",
                    borderRight: listColumnSplit
                      ? isEven(index) + " solid rgba(0, 0, 0, 0.1)"
                      : "0px",
                    paddingLeft: "0px",

                  }}
                >
                  {!isEditMode ? (
                    <>
                      <Grid container item xs={12} sm={12} md={12} lg={12} direction="row" style={{ padding: "15px 0px", width: "100%" }}>
                        <Grid item sm={6} xs={6} md={6} lg={6}>
                          <ListItem>
                            <Typography
                              style={{ fontSize: "14px", fontWeight: "500", width: '260px' }}
                            >
                              {_.get(header, keyPath, "")}
                            </Typography>
                          </ListItem>
                        </Grid>
                        <Grid item sm={6} xs={6} md={6} lg={6}>
                          <ListItem>
                            <Typography
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              {_.get(header, valuePath, "")}
                            </Typography>
                          </ListItem>
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    // <ListItem style={{ height: "50px", paddingLeft: "0px" }}>
                    <Grid container item xs={12} sm={12} md={12} lg={12} direction="row" style={{ padding: "15px 0px", width: "100%" }}>
                      <Grid item lg={6} md={6}
                        style={{
                          width: "50%",
                          borderRight: "1px solid rgba(0, 0, 0, 0.1)",
                          // height: "50px",
                          // minWidth: "330px",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            marginTop: "14px",
                            paddingLeft: "16px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {_.get(header, keyPath, "")}
                        </Typography>
                      </Grid>
                      <Grid item lg={6} md={6} style={{ height: "50px", width: "50%", display: "flex", justifyContent: "space-between" }}>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            marginTop: "14px",
                            paddingLeft: "16px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap"
                          }}
                        >
                          {_.get(header, valuePath, "")}
                        </Typography>
                        {!facetEdit && (
                          <IconButton
                            onClick={() => {
                              deleteData(_.get(header, keyPath, ""), keyPath)
                              // setAnyChange(true)
                            }}
                          >
                            <CloseIcon />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                    // </ListItem>
                  )}
                  {/* {!facetEdit && (
                    <IconButton
                      onClick={() => {
                        deleteData(_.get(header, keyPath, ""), keyPath)
                        // setAnyChange(true)
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )} */}
                </ListItemWrapper>
              ))
            ) : (
              <Features.NoRecordFound />
            )}
          </ListWrapper>
        </div>
      </Grid >
    )
  }

  const renderList = ({ facetEdit }) => {
    const renderViewlist = () => (
      <div className={classes.listProperty} style={{ width: "100%" }}>
        <ListItemKit list={data} keyPath={keyPath} valuePath={valuePath} />
      </div>
    )
    return facetEdit === false ? (
      <div>
        <FormfacetUI {...formStateProps} />
        {renderViewlist()}
      </div>
    ) : (
      renderViewlist()
    )
  }

  return <div>
    {alert ? (
      <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
    ) : null}
    {renderList({ facetEdit })}
  </div>
}
export { View }
export default View
