/* eslint-disable no-sequences */
import axios from 'axios';
import { PRODUCT_CATEGORY_URLS as URLS, PRODUCT_DATA_URLS } from '../../../utils/url';
import UserService from '../../UserService';

//PATH VARIABLE REPLACER
function bindPath(url, pathVal) {
    var newUrl = url;
    var pathExpression = /:[a-z0-9]+/gi;
    var pathVar;
    while (pathVar = pathExpression.exec(url), pathVar != null) {
        let pathVarName = pathVar[0];
        newUrl = newUrl.replace(pathVarName, pathVal[pathVarName.substring(1, pathVarName.length)]);
    }
    return newUrl;
}



const productAxios = axios.create();

productAxios.interceptors.request.use(
    async function (config) {
        config.baseURL = config?.baseURL || URLS.baseURL
        // //CHECK REQUEST NEED TO ADD AUTH TOKEN IN THE HEADER
        // if (config.headers.isAuthRequired) {
        //     const token = store.getState().authentication.authToken || config.headers.authKey; //GET TOKEN FROM REDUX STATE
        //     if (token) config.headers.Authorization = `Bearer ${token}`; //ADD AUTHORIZATION HEADER
        // }
        // //DELETE CUSTOM PROPERTY IN THE REQUEST HEADERS
        // delete config.headers.isAuthRequired;
        // delete config.headers.authKey;

        if (UserService.isLoggedIn()) {
            if (UserService.isAuthExpired() === true) {
                const myData = await UserService.updateRefreshToken();
                if (myData === true) {
                    let mydataToken = UserService.getToken((res) => {
                        return res
                    })
                    localStorage.setItem('token', mydataToken);
                    config.headers['Authorization'] = "Bearer " + mydataToken
                    let tenantId = localStorage.getItem("tenantId") === null ? "" : localStorage.getItem("tenantId")
                    config.headers['X-Tenant-ID'] = tenantId
                    config.headers['X-KC-Realm-ID'] = localStorage.getItem('X-KC-Realm-ID')
                    config.headers['X-KC-Host'] = localStorage.getItem('X-KC-Host')
                    // return Promise.resolve(config);
                } else {
                    localStorage.clear();
                    UserService.doLogout();
                }
            } else {
                let tenantId = localStorage.getItem("tenantId") === null ? "" : localStorage.getItem("tenantId")
                config.headers['X-Tenant-ID'] = tenantId
                config.headers['X-KC-Realm-ID'] = localStorage.getItem('X-KC-Realm-ID')
                config.headers['X-KC-Host'] = localStorage.getItem('X-KC-Host')
                config.headers['Authorization'] = localStorage.getItem('token') === null ? "" : "Bearer " + localStorage.getItem('token')
                // return Promise.resolve(config);
            }
        } else {
            localStorage.clear();
            UserService.doLogout();
        }

        //PATH VARIABLES IS AVAILABLE
        // config.headers['Authorization'] = localStorage.getItem('token') === null ? "" : "Bearer " + localStorage.getItem('token')
        if (config.headers.path) {
            try {
                config.url = bindPath(config.url, config.headers.path);
            }
            catch (e) {
            }
        }
        delete config.headers.path;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

const productCategoryApiMethods = {
    getAllProductCategories: () => {
        return productAxios.get(URLS.endpoints.getAll)
    },
    createCategory: (bodyData) => {
        return productAxios.post(URLS.endpoints.create, bodyData)
    },
    fetchCategory: (categoryID) => {
        return productAxios.get(URLS.endpoints.fetchCategory)
    },
    updateCategory: (categoryID, bodyData) => {
        return productAxios.patch(URLS.endpoints.updateCategory, bodyData, { headers: { path: { categoryID } } })
    },
    uploadImage: () => {
        return productAxios.post(URLS.endpoints.uploadImage)
    },
    deleteCategory: (categoryID) => {
        return productAxios.delete(URLS.endpoints.deleteCategory, { headers: { path: { categoryID } } })
    },
};

export const productDataApiMethods = {
    getAllProductsWithPagination: (page = 1, limit = 10) => {
        return productAxios.get(PRODUCT_DATA_URLS.endpoints.getAllWithPagination, { baseURL: PRODUCT_DATA_URLS.baseURL, params: { page, limit } })
    },
    getAllProductGroupsWithPagination: (page = 1, limit = 10) => {
        return productAxios.get(PRODUCT_DATA_URLS.endpoints.getAllProductGroupsWithPagination, { baseURL: PRODUCT_DATA_URLS.baseURL, params: { page, limit } })
    },
    createCategory: (bodyData) => {
        return productAxios.post(PRODUCT_DATA_URLS.endpoints.createCategory, bodyData, { baseURL: PRODUCT_DATA_URLS.baseURL })
    },
    updateCategory: (id, bodyData) => {
        return productAxios.put(PRODUCT_DATA_URLS.endpoints.updateCategory + '/' + id, bodyData, { baseURL: PRODUCT_DATA_URLS.baseURL })
    },
    deleteProductCategory: (categoryId) => {
        return productAxios.delete(PRODUCT_DATA_URLS.endpoints.deleteCategory + '/' + categoryId, { baseURL: PRODUCT_DATA_URLS.baseURL })
    },
}

export default productCategoryApiMethods;