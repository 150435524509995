import React, { useState, createRef } from "react"
import CropperKit from "react-cropper"
import "cropperjs/dist/cropper.css"
import { defaultSrc, defaultSrcs, defaultList } from "./fixture"
import useCropper from "./useCropper"
import {
  Grid,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel,
  TextareaAutosize,
} from "@material-ui/core"
import { CloseRounded } from "@material-ui/icons"
import { StyledCropperMainWrapper } from "./styles"
import * as Adm from "@adm"
import * as IconsGallery from "@iconsGallery"
import { IconGallery } from "@storybook/addon-docs"
import { makeStyles } from "@material-ui/core"
export const useStyles = makeStyles({
  autoCompleteHeight: {
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },
})
function Cropper(props) {
  const {
    defaultProductLoader = false,
    defaultSaveLoader = false,
    defaultProductList = [],
    onClose = () => "",
    currentItem = {},
    onSave = () => "",
    defaultMCKitList = [],
  } = props || {}
  const {
    getPRDOpen,
    setPRDOpen,
    getYourComments,
    setYourComments,
    getImage,
    setImage,
    getCropData,
    setCropData,
    cropperRef,
    onTriggerImageUrl,
    onHandleCropData,
    onHandleResetCropData,
    getImageList,
    setImageList,
    getProductList,
    getProductItem,
    setProductItem,
    getError,
    setError,
    getMisclassified,
    setMisclassified,
    getMCList,
    setMCList,
    getMCItem,
    setMCItem,
    getScale,
    setScale,
  } = useCropper({ currentItem, defaultProductList, defaultMCKitList })

  const classes = useStyles()

  let mcObjKit = getMisclassified
    ? {
      defaultValue: getMCItem,
      placeholder: "Select",
      list: getMCList,
      onUpdate: (selectedItem) => {
        setMCItem(selectedItem)
      },
    }
    : {
      defaultValue: "",
      placeholder: "No selected",
      list: [],
      onUpdate: () => "",
      style: { opacity: "0.5" },
    }

  const renderTextArea = () => {
    return (
      <Grid
        style={{
          marginTop: 10,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid className="cls-al-titl-head">
          {`Comments:`}
          <span
            style={{ marginLeft: 3, fontSize: 12, color: "gray" }}
          >{`(optional)`}</span>
        </Grid>
        <TextareaAutosize
          type="text"
          name="description"
          autoComplete="off"
          style={{ width: "465px", height: "100px", fontFamily: `unset` }}
          placeholder="Add your comments here"
          value={getYourComments}
          onChange={(e) => {
            setYourComments(e.target.value)
          }}
          variant="outlined"
          size="small"
          fullWidth
          maxLength={250}
          rowsMin={3}
        />
      </Grid>
    )
  }
  const renderCheckBox = () => {
    return (
      <Grid
        style={{
          marginTop: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* <Grid className="cls-al-titl-head">{`Check Misclassification Occurred:`}</Grid> */}
        <FormControlLabel
          value="default"
          control={
            <Checkbox
              style={{ color: "var(--primaryColor)" }}
              checked={getMisclassified}
              onChange={() => {
                setMisclassified(!getMisclassified)
              }}
            />
          }
          label="Deny Adaptive Learning"
        />
        <Adm.DropdownKit {...mcObjKit} />
      </Grid>
    )
  }
  const renderDropdown = () => {
    return (
      <Grid
        style={{
          marginTop: 10,
          pointerEvents: getMisclassified ? "none" : "unset",
          opacity: getMisclassified ? "0.5" : "1",
        }}
      >
        <Grid className="cls-al-titl-head">{`Select Product Name:`}</Grid>
        <Adm.DropdownFilter
          styleDropdownProps={{
            style: {
              maxHeight: 260,
            },
          }}
          classNameTextField={classes.autoCompleteHeight}
          onChangeEvent={(e, val) => {
            setError({ ...getError, nameFlag: false })
            setProductItem(val?.name)
          }}
          popupOpen={getPRDOpen}
          setPopupOpen={setPRDOpen}
          defaultValue={getProductItem}
          placeholder={
            defaultProductLoader ? "Loading..." : "Search Product Name"
          }
          list={getProductList.map((itm, index) => {
            return { id: index, name: itm }
          })}
        />
      </Grid>
    )
  }
  const renderImageList = () => {
    return (
      <Grid
        style={{
          marginTop: 10,
          pointerEvents: getMisclassified ? "none" : "unset",
          opacity: getMisclassified ? "0.5" : "1",
        }}
      >
        <Grid className="cls-al-titl-head">{`Select a Image to Crop:`}</Grid>
        <Grid className="cls-al-image-main-wrapper">
          <Grid className="cls-al-image-main-kit">
            {getImageList.length > 0 ? (
              [...getImageList].map((itm, index) => {
                const { selected = false } = itm
                return (
                  <Grid
                    className={`cls-al-image-kit ${itm.url === undefined ? "disabled-image" : ""
                      } ${selected ? `active` : ``}`}
                    onClick={() => {
                      setCropData("#")
                      if (itm.url !== undefined) {
                        let selectedImageUrl = ""
                        let lcGetImageList = getImageList.map(
                          (innItm, innIndex) => {
                            if (innIndex === index) {
                              selectedImageUrl = innItm.url
                            }
                            return { ...innItm, selected: innIndex === index }
                          }
                        )
                        setImageList(lcGetImageList)
                        setImage(selectedImageUrl)
                        setTimeout(() => {
                          if (cropperRef) {
                            setScale(1)
                            cropperRef.current.cropper.scale(1)
                          }
                        }, 200)
                      }
                    }}
                  >
                    <img
                      alt={index}
                      src={
                        itm.url ||
                        "https://place-hold.it/300?text=NoImage&italic=true"
                      }
                    />
                  </Grid>
                )
              })
            ) : (
              <h3>No Image Found</h3>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
  const renderCrop = () => {
    let stylesCropWrapper = { height: "400px", width: "100%" }
    return (
      <Grid
        style={{
          pointerEvents: getMisclassified ? "none" : "unset",
          opacity: getMisclassified ? "0.5" : "1",
        }}
      >
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid>
            {getCropData === "#" && getImage.length > 0 && (
              <Grid style={{ display: "flex", flexDirection: "column" }}>
                <Grid style={{ display: "flex", flexDirection: "row" }}>
                  <label
                    htmlFor="scale"
                    style={{ marginRight: 3 }}
                  >{`Zoom`}</label>
                  <input
                    className="cls-input-range-kit"
                    type="range"
                    min="0.2"
                    max="2"
                    step="0.2"
                    value={getScale}
                    aria-label="scale"
                    id="scale"
                    onChange={(e) => {
                      const scaleValue = parseFloat(e.target.value)
                      setScale(scaleValue)
                      cropperRef.current.cropper.scale(scaleValue)
                    }}
                  />
                </Grid>
                <Grid
                  style={{ fontSize: 10, fontStyle: "italic" }}
                >{`Note: Pinch or Zoom Image Stage`}</Grid>
              </Grid>
            )}
          </Grid>
          <Button
            style={{
              background: getImage.length > 0 ? "var(--primaryColor)" : "gray",
              color: "var(--primaryTextColor)",
              width: 150,
              height: 30,
              fontWeight: "500",
              marginBottom: 6,
              display: "flex",
              marginLeft: "auto",
              opacity: getImage.length > 0 ? 1 : 0.5,
            }}
            onClick={
              getCropData === "#" ? onHandleCropData : onHandleResetCropData
            }
          >{`${getCropData === "#" ? `Crop and Preview` : `Reset Crop`
            }`}</Button>
        </Grid>
        <Grid style={{ width: "100%" }}>
          {getCropData === "#" && getImage.length > 0 ? (
            <Grid
              style={{
                ...stylesCropWrapper,
                background: "#000000bd",
                overflow: "auto",
              }}
            >
              <CropperKit
                dragMode="move"
                ref={cropperRef}
                style={stylesCropWrapper}
                zoomTo={1}
                initialAspectRatio={1}
                preview=".img-preview"
                src={getImage}
                viewMode={1}
                minCropBoxHeight={5}
                minCropBoxWidth={5}
                background={false}
                responsive={true}
                autoCropArea={1}
                checkOrientation={false}
                guides={true}
                onZoomChange={(propsIn) => {
                }}
              />
            </Grid>
          ) : (
            <>
              {getImage.length > 0 ? (
                getCropData !== "#" && (
                  <Grid
                    style={{
                      ...stylesCropWrapper,
                      background: "#000000bd",
                      overflow: "auto",
                    }}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={getCropData}
                      alt="cropped"
                    />
                  </Grid>
                )
              ) : (
                <Grid
                  style={{
                    ...stylesCropWrapper,
                    background: "#B4B4B4",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 16,
                    fontWeight: "bold",
                  }}
                >
                  <Grid>
                    <IconsGallery.CropIcon
                      style={{
                        margin: "21px auto",
                        display: "flex",
                        width: 65,
                        height: "auto",
                      }}
                    />
                    <div>{`Please Select a Image to Crop`}</div>
                  </Grid>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </Grid>
    )
  }

  let isSaveButtonDisable = true

  if (
    getMisclassified === false &&
    getCropData !== "#" &&
    getProductItem.length > 0
  ) {
    isSaveButtonDisable = false
  }

  if (getMisclassified && getMCItem.length > 0) {
    isSaveButtonDisable = false
  }

  return (
    <>
      <StyledCropperMainWrapper>
        <Grid className="cls-al-header">
          <h2>{`Adaptive  Learning`}</h2>
          <IconButton
            onClick={() => {
              onClose()
            }}
          >
            <CloseRounded />
          </IconButton>
        </Grid>
        <Grid className="cls-al-container">
          <Grid className="cls-al-innr-container-l">
            {renderCheckBox()}
            {renderDropdown()}
            {renderImageList()}
            {renderTextArea()}
          </Grid>
          <Grid
            className="cls-al-innr-container-r"
            style={{ padding: 15, paddingRight: 0, paddingTop: 0 }}
          >
            {renderCrop()}
          </Grid>
        </Grid>
      </StyledCropperMainWrapper>
      <Grid
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "20px 10px",
          minHeight: 95,
        }}
      >
        {getMisclassified === false && (
          <Grid>
            {getError?.imageFlag && (
              <p style={{ fontSize: 13, color: "red", margin: 5 }}>
                <IconsGallery.AlertIconKit />
                <span style={{ margin: 5 }}> {getError?.imageError}</span>
              </p>
            )}
            {getError?.nameFlag && (
              <p style={{ fontSize: 13, color: "red", margin: 5 }}>
                <IconsGallery.AlertIconKit />
                <span style={{ margin: 5 }}> {getError?.nameError}</span>
              </p>
            )}
          </Grid>
        )}
        <Button
          disabled={isSaveButtonDisable}
          style={{
            background: isSaveButtonDisable ? "gray" : "var(--primaryColor)",
            color: "var(--primaryTextColor)",
            width: 120,
            height: 30,
            fontWeight: "500",
            marginBottom: 6,
            display: "flex",
            marginLeft: "auto",
            marginRight: 10,
            marginTop: 10,
            opacity: isSaveButtonDisable ? 0.5 : 1,
          }}
          onClick={() => {
            let learnFlg = ""

            if (getMCItem.length > 0) {
              const [_x] = defaultMCKitList.filter(
                (itm) => itm.al_drop_down === getMCItem
              )
              const { al_flag = "" } = _x
              learnFlg = al_flag
            }
            onSave({
              croppedImage: getCropData,
              selectedProduct: getProductItem,
              isDoMisclassified: getMisclassified,
              yourComments: getYourComments,
              learning_review: getMCItem,
              learning_flag: learnFlg,
            })
          }}
        >
          {defaultSaveLoader ? `Saving...` : `Save`}
        </Button>
      </Grid>
    </>
  )
}
export default Cropper
export { Cropper }
