import * as React from "react"

function Image(props) {
  const {
    src = "",
    label,
    altText = label,
    className,
    alt,
    style = {},
    ...rest
  } = props || {}
  const imageRef = React.createRef()
  const [imageLoadingStatus, setImageLoadingStatus] = React.useState("loading")

  const updateProtocolSrc = src

  const onImageLoad = () => {
    setImageLoadingStatus("done")
  }

  const onError = () => {
    setImageLoadingStatus("error")
  }

  React.useEffect(() => {
    if (imageRef.current.complete === true) {
      setImageLoadingStatus("done")
    }
  }, [])

  const isImageLoading = imageLoadingStatus === "loading"
  return (
    <div
      {...rest}
      style={{
        minWidth: 300,
        minHeight: 200,
        width: "fit-content",
        height: "fit-content",
        position: "relative",
      }}
    >
      {imageLoadingStatus === "error" ? (
        <div
          style={{
            position: "absolute",
            width: "fit-content",
            height: "fit-content",
            margin: "auto",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          }}
        >{`ERROR`}</div>
      ) : (
        <>
          <img
            style={style}
            ref={imageRef}
            src={updateProtocolSrc}
            alt={alt}
            onError={onError}
            onLoad={onImageLoad}
          />
          {isImageLoading && (
            <div
              style={{
                position: "absolute",
                width: "fit-content",
                height: "fit-content",
                margin: "auto",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}
            >{`LOADING...`}</div>
          )}
        </>
      )}
    </div>
  )
}

export { Image }
export default Image
