import {
  Switch,
  Box,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControl,
} from "@material-ui/core"
import React from "react"

import customTableStyles from "../../styles/components/customTable/styles"
import _ from "lodash"
import { useState } from "react"
import { useEffect } from "react"
//For status
function AdjustmentReason(props) {
  const data = props?.dataItem
  //let dropdownList = props?.list || []

  const classes = customTableStyles()
  const { value = [] } = props

  const handleChange = (e) => {
    props?.handleChange(e)
  }

  let incrementProps = ["Restock"]
  let descrementProps = ["Damaged (WRITE OFF)", "Expired (WRITE OFF)", "Return to Vendor"]

  const dropdownList =
    props?.dataItem?.adjustment_type === "Increment"
      ? incrementProps
      : descrementProps || []

  // console.log("AdjustmentReason", props?.dataItem, props?.value)

  return (
    <td>
      {data?.inEdit ? (
        <FormControl style={{ width: "150px" }}>
          <Select
            name={props?.field}
            value={props?.value || dropdownList[0]}
            onChange={(e) => handleChange(e)}
            input={<OutlinedInput />}
            variant="outlined"
            style={{
              height: "30px",
              backgroundColor: dropdownList?.length <= 1 ? "#e0e0e5" : "",
            }}
            className={classes.actionDropdown}
            disabled={dropdownList?.length <= 1}
          >
            {dropdownList?.length > 0 &&
              dropdownList?.map((name) => (
                <MenuItem key={name} value={name}>
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      ) : (
        <Typography>{props?.value || "-"}</Typography>
      )}
    </td>
  )
}

export default AdjustmentReason
