import React from "react"
import { Backdrop, CircularProgress } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import * as Adm from "@adm"

const BackDropOverLayStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.modal + 1,
    color: "white",
  },
}))

function BackdropOverlay({ open }) {
  const classes = BackDropOverLayStyles()
  return (
    <Backdrop open={open} className={classes.backdrop}>
      <Adm.BrandLoadingBar />
      {/* <CircularProgress m={2} style={{ color: "var(--primaryColor)" }} /> */}
    </Backdrop>
  )
}
export { BackdropOverlay }
export default BackdropOverlay
