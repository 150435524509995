import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}
// const types = REDUX_CONSTANTS

export const addCustomerInfo = (data) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_CUSTOMER_INFO,
    customerInfo: data,
  })
}

export const addCustomerCommunication = (data) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_CUSTOMER_COMMUNICATION,
    customerCommunication: data,
  })
}

export const addCustomerAttributes = (data) => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_CUSTOMER_ATTRIBUTES,
    customerAttributes: data,
  })
}

export const addCustomerReset = () => (dispatch) => {
  dispatch({
    type: REDUX_CONSTANTS.ADD_CUSTOMER_RESET,
  })
}
