import React from "react"
import { Grid, TextField, IconButton, Tooltip, Drawer } from "@material-ui/core"
import styled from "styled-components"

export const StyledMainWrapper = styled(Grid)`
  width: 100%;
  height: 30px;
  min-height: 30px;
  max-height: 30px;
  background: transparent;
  display: flex;
  flex-direction: row;

  .cls-pos-parent {
    display: flex;
    flex-direction: row;
    border: 1px solid transparent;
    overflow: hidden;
    border-radius: 5px;
    margin-right: 5px;
  }
  .cls-box-kit {
    width: 30px;
    height: 30px;
    overflow: hidden;
    &.key {
      width: 20px;
      padding: 4px;
      text-transform: uppercase;
      color: white;
      font-weight: bold;
    }
    &.value {
      width: 40px;
      background: #fff;
      input {
        padding-left: 3px;
      }

      .Mui-disabled {
        background: #dbe1e8 !important;
      }
    }
  }
`
function Position(props) {
  const {
    defaultPosition = { x: 0, y: 0, z: 0 },
    disabled = false,
    onUpdate = () => "",
  } = props
  const list = [
    { name: "x", value: defaultPosition?.x, color: "#f66d6d" },
    { name: "y", value: defaultPosition?.y, color: "#75bb77" },
    { name: "z", value: defaultPosition?.z, color: "#297ad8" },
  ]
  const [getDefaultList, setDefaultList] = React.useState([])

  React.useEffect(() => {
    setDefaultList(list)
  }, [defaultPosition])

  return (
    <StyledMainWrapper>
      {getDefaultList?.map((itm, index) => {
        return (
          <Grid
            key={`pos_${index}`}
            className="cls-pos-parent"
            style={{ borderColor: itm?.color || "gray" }}
          >
            <Grid
              className="cls-box-kit key"
              style={{ background: itm?.color || "gray" }}
            >
              {itm?.name}
            </Grid>
            <Grid className="cls-box-kit value">
              <TextField
                className="cls-input-count"
                disabled={disabled}
                defaultValue={itm?.value}
                onChange={(event) => {
                  let inputValue = event.target.value
                  inputValue = inputValue.replace(/[^0-9.-]/g, "")
                  // inputValue = inputValue.slice(0, 5)
                  event.target.value = inputValue
                  let lcGetDefaultList = getDefaultList
                  lcGetDefaultList[index]["value"] = isNaN(inputValue)
                    ? inputValue
                    : Number(inputValue)
                  setDefaultList(lcGetDefaultList)
                  onUpdate(lcGetDefaultList)
                }}
              />
            </Grid>
          </Grid>
        )
      })}
    </StyledMainWrapper>
  )
}

export { Position }

export default Position
