import React, { useState, createRef } from "react"
import { defaultSrc, defaultSrcs, defaultList } from "./fixture"
const onTriggerImageUrl = ({ imageUrl, setImage }) => {
  fetch(imageUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const url = URL.createObjectURL(blob)
      setImage(url)
    })
}

function useCropper(props) {
  const {
    currentItem = {},
    defaultProductList = [],
    defaultMCKitList = [],
  } = props
  const { cvData = [] } = currentItem
  const [getYourComments, setYourComments] = useState("")
  const [getPRDOpen, setPRDOpen] = useState(false)
  const [getMisclassified, setMisclassified] = useState(false)
  const [getImageLoader, setImageLoader] = useState(false)
  const [getProductItem, setProductItem] = useState("")
  const [getProductList, setProductList] = useState([])
  const [getMCItem, setMCItem] = useState("")
  const [getMCList, setMCList] = useState([])
  const [getScale, setScale] = useState(1)
  const [getError, setError] = useState({
    imageFlag: true,
    nameFlag: true,
    imageError: "Please select the image and crop and save it",
    nameError: "Please select the Product Name and save it",
  })

  let lcDefaultImageList = cvData.map((itm) => {
    return { ...itm, url: itm?.combined_image || itm?.beforeImage }
  })
  const [getImageList, setImageList] = useState([...lcDefaultImageList])
  const [getImage, setImage] = useState("")
  const [getCropData, setCropData] = useState("#")
  const cropperRef = createRef()

  React.useEffect(() => {
    setMCList(defaultMCKitList.map((itm) => itm?.al_drop_down))
    // setMCItem(defaultMCKitList[0])
  }, [defaultMCKitList])
  React.useEffect(() => {
    setProductList(
      defaultProductList.map((itm) => {
        return itm?.title || ""
      })
    )
  }, [defaultProductList])
  const onHandleCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
    } else {
      setCropData("#")
    }
    setError({ ...getError, imageFlag: false })
  }
  const onHandleResetCropData = () => {
    setCropData("#")
    setError({ ...getError, imageFlag: true })
    setScale(1)
    cropperRef.current.cropper.scale(1)
  }

  React.useEffect(() => {
    // onTriggerImageUrl({ imageUrl: defaultSrcs, setImage })
  }, [])

  return {
    getImage,
    setImage,
    getImageList,
    setImageList,
    getCropData,
    setCropData,
    cropperRef,
    onTriggerImageUrl,
    onHandleCropData,
    getProductList,
    setProductList,
    getProductItem,
    setProductItem,
    onHandleResetCropData,
    getImageLoader,
    setImageLoader,
    getError,
    setError,
    getMisclassified,
    setMisclassified,
    getMCList,
    setMCList,
    getMCItem,
    setMCItem,
    getYourComments,
    setYourComments,
    defaultMCKitList,
    getPRDOpen,
    setPRDOpen,
    getScale,
    setScale,
  }
}

export { useCropper }
export default useCropper
