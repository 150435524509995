import {
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { withStyles } from "@material-ui/styles"
import { Autocomplete } from "@material-ui/lab"
import styled from "styled-components"

export const useStyles = makeStyles((theme) => ({
  option: {
    borderBottom: `1px dashed #ccc`,
    paddingBottom: "0px !important",
    height: 65,
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: "#dfdfdf",
      borderColor: "transparent",
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: "#dfdfdf",
      borderColor: "transparent",
    },
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  productBtn: {
    margin: "0px",
    "@media(min-width:1200px)": {
      "& .MuiGrid-grid-lg-1": {
        maxWidth: "14%",
        flexBasis: "14%",
      },
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  textInput: {
    // marginBottom: '8px',
    fontWeight: 600,
    fontSize: "14px",
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
  disabledTextInput: {
    width: "100%",
    "& input": {
      backgroundColor: "#f6f6f6",
      color: "gray",
      width: "100%",
    },
  },
  text: {
    marginBottom: "45px",
    paddingLeft: "20px",
    marginLeft: "0px",
  },
  text1: {
    marginBottom: "45px",
    paddingLeft: "0px",
    marginLeft: "0px",
  },
  textemail: {
    marginBottom: "45px",
    paddingLeft: "20px",
    marginLeft: "0px",
  },
  textHeader: {
    marginBottom: "15px",
    // fontSize: '16px'
  },
  icon: {
    marginRight: "5px",
    color: "rgba(21, 101, 192, 1)",
    marginBottom: "-5px",
    fontSize: "30px",
  },
  actionIcons: {
    color: "rgba(158, 158, 158, 1)",
    fontSize: "22px",
  },
  textHeader1: {
    marginBottom: "15px",
    marginTop: "10px",
  },
  displayText: {
    fontSize: "18px",
    fontWeight: 500,
  },
  header2: {
    marginBottom: "15px",
    paddingLeft: "0px",
    marginLeft: "0px",
    marginTop: "10px",
  },
  divider: {
    borderRight: "0.1em solid rgba(0, 0, 0, 0.1)",
    padding: "15px 15px",
    margin: "10px 0px",
  },
  noDivider: {
    padding: "15px 15px",
    margin: "10px 0px",
  },
  bottomDivider: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
  },
  divPadding: {
    padding: "2px",
  },
  autoCompleteHeight: {
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },
  valuesStyle: {
    alignSelf: "center",
  },
  keyboardDatePicker: {
    marginTop: "0px",
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: "0px",
      height: "36px"
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
  },
}))

export const StyledAutocomplete = styled(Autocomplete)`
  li {
    padding-bottom: 0px !important;
  }
  .Mui-disabled {
    background: lightgrey;
  }
  p.Mui-disabled {
    background: transparent;
  }
`
export const StyledForm = styled("form")`
  .Mui-disabled {
    background: lightgrey;
  }
  p.Mui-disabled {
    background: transparent;
  }
`
export const StyledOverAllMainWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 405px;
  padding: 10px;
  border: 1px solid #c6c6c6;
  border-top: 0;
  border-radius: 0px 0px 10px 10px;
  &.cls-store {
    min-height: 300px;
  }
  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
export const StyledSideWrapper = styled(Grid)`
  width: 20%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #ccc;
  padding: 20px;

  @media (max-width: 1200px) {
    border-right: 0px solid #ccc;
    width: 100%;
  }
`
export const StyledSideRightWrapper = styled(Grid)`
  width: 80%;
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 1200px) {
    width: 100%;
  }
`
export const StyledMainWrapper = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 8px;
  grid-column-gap: 15px;
  row-gap: 8px;
  column-gap: 15px;
  width: 100%;
  padding: 20px;

  .cls-field-form-kit {
    min-height: 75px;
    width: 100%;
  }
`
export const ProDialog = styled(Grid)`
  background: white;
  box-shadow: rgba(0, 0, 0, 0.17) 1px 2px 3px;
  margin-bottom: 12px;
  overflow: hidden;
  border-radius: 8px;
`
